import brandActionTypes from "../actions/brand/brandActionTypes";

const initialState = {
    data: {},
    loading: false,
    isErrorBranding: false
};

// eslint-disable-next-line default-param-last
const brandReducer = (state = initialState, action) => {
    switch (action.type) {
        case brandActionTypes.GET_BRAND_DATA:
            return {
                ...state,
                loading: true,
            };
        case brandActionTypes.SAVE_BRAND_DATA:
            return {
                ...state,
                loading: false,
                data: action.payload,
                isErrorBranding: false
            };
        case brandActionTypes.SET_ERROR_DATA:
            return {
                data: {},
                loading: false,
                isErrorBranding: true
            };
        default:
            return state;
    }
};

export default brandReducer;
