import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectBrand, selectIsErrorBrand } from "../../store/selectors/brandSelector";
import { getBrandAction } from "../../store/actions/brand/brandActions";
import MaintenanceView from "./MaintenanceView";
import { hasObjectProps } from "../../helpers/validations";

const Maintenance = () => {
    const brand = useSelector(selectBrand);
    const isErrorBrand = useSelector(selectIsErrorBrand);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getBrandAction());
    }, []);

    useEffect(() => {
        if (hasObjectProps(brand) && !isErrorBrand) {
            history.push(`/${brand?.name?.replaceAll(" ", "")}`);
        }
    }, [brand]);

    return <MaintenanceView />;
};

export default Maintenance;
