import paymentActionTypes from "../actions/payment/paymentActionTypes";
import { paymentTypesEnum } from "../../constants/enums";

const initialState = {
  marketingBudgets: [],
  ampBalance: null,
  isAmpAvailable: false,
  isCreditCardAvailable: false,
  isEnoughAmpBalance: false,
  paymentLoading: false,
  paymentType: paymentTypesEnum.CreditCard,
  payWithAmpBalance: {},
  payWithCreditCard: {},
  paymentError: null,
};

// eslint-disable-next-line default-param-last
const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case paymentActionTypes.SAVE_MARKETING_BUDGETS:
      return {
        ...state,
        ...action.payload,
      };
    case paymentActionTypes.SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload,
      };
    case paymentActionTypes.SET_PAYMENT_LOADING:
      return {
        ...state,
        paymentLoading: action.payload,
      };
    case paymentActionTypes.SAVE_AMP_BALANCE:
      return {
        ...state,
        ampBalance: action.payload.ampBalance,
        isEnoughAmpBalance: action.payload.isEnoughAmpBalance,
        paymentLoading: false,
      };
    case paymentActionTypes.SET_CREDIT_CARD_INFO:
      return {
        ...state,
        payWithCreditCard: action.payload,
      };
    case paymentActionTypes.EMPTY_CREDIT_CARD_INFO:
      return {
        ...state,
        payWithCreditCard: {},
      };
    case paymentActionTypes.SET_PAYMENT_ERROR:
      return {
        ...state,
        paymentError: action.payload,
      };
    case paymentActionTypes.EMPTY_PAYMENT_DATA:
      return initialState;
    default:
      return state;
  }
};

export default paymentReducer;
