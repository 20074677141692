import React from "react";
import PropTypes from "prop-types";
import { getFaqContent } from "../../../helpers/formatters";

const QuestionAnswerRow = ({ faqItem, handleFAQListToggle, activeId }) => {
    const { question, answer } = getFaqContent(faqItem.content);
    const isActive = faqItem.id === activeId;

    return (
        <li
            onClick={() => handleFAQListToggle(faqItem.id)}
            className={isActive ? "active" : ""}
        >
            <span className="target-fix"></span>
            <h3 className="accordion accordion-title active">
                <span className={`cf-skeleton-span-${faqItem.w || "5"}`}>{question}</span>
                <i className="icon-down cf-skeleton-dot-sm"></i>
            </h3>
            <p className="accordion-text">
                {answer}
            </p>
        </li>
    );
};

QuestionAnswerRow.propTypes = {
    faqItem: PropTypes.object.isRequired,
    handleFAQListToggle: PropTypes.func.isRequired,
    activeId: PropTypes.number,
};

export default QuestionAnswerRow;
