import React, { useEffect, useRef } from "react";
import resizeFontSize from "./helper";

const textGap = 20;
const defaultMinFontSize = 2;
const defaultMaxFontSize = 60;
const defaultStyles = {
  whiteSpace: "nowrap",
  width: "100%",
  overflow: "hidden",
};

export default function AutoSizeText({
  text,
  step = 1,
  minFontSize = defaultMinFontSize,
  maxFontSize = defaultMaxFontSize,
  gap = textGap,
}) {
  const containerRef = useRef(null);
  const textRef = useRef(null);

  const resize = () => {
    resizeFontSize({
      textRef,
      containerRef,
      step,
      minFontSize,
      maxFontSize,
      gap,
    });
  };

  useEffect(() => {
    resize();
  }, [text]);

  return (
    <div ref={containerRef} style={defaultStyles}>
      <span ref={textRef}>{text}</span>
    </div>
  );
}
