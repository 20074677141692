import React, { useState } from "react";

const Avatar = ({
  imageUrl,
  name,
  className,
  loading,
  placeholderName,
}) => {
  const [isImageAvailable, setIsImageAvailable] = useState(Boolean(imageUrl));
  const nameFirstLetter = name?.[0];

  if (isImageAvailable) {
    return (
      <img
        alt="avatar"
        loading={loading}
        src={imageUrl}
        onError={() => setIsImageAvailable(false)}
        className={className}
      />
    );
  }

  return (
    <span className={` ${className} placeholder `}>
      {placeholderName ?? nameFirstLetter}
    </span>
  );
};

export default Avatar;
