import first from "lodash/first";
import get from "lodash/get";
import memoizeOne from "memoize-one";
import { mediaTypesMapper, notApplicable } from "../constants/constants";
import { goalsEnum, paymentTypesEnum } from "../constants/enums";
import { isJson } from "./validations";

export const capitalizeFirstLetter = (string) =>
  string.toLocaleLowerCase().charAt(0).toUpperCase() +
  string.toLocaleLowerCase().slice(1);

export const formatAddressString = memoizeOne((address) => {
  if (!address) {
    return "";
  }

  const regexPattern =
    /\sA[lkszraep]\W|\sC[aot]\W|\sD[ec]\W|\sF[lm]\W|\sG[au]\W|\sHi\W|\sI[adln]\W|\sK[sy]\W|\sLa\W|\sM[adehinopst]\W|\sN[cdehjmvy]\W|\sO[hkr]\W|\sP[arw]\W|\sRi\W|\sS[cd]\W|\sT[nx]\W|\sUt\W|\sV[ait]\W|\sW[aivy]\W/;

  const titleCaseStr = address
    .replace(/  +/g, " ")
    .replace(" ,", ",")
    .split(" ")
    .reduce(
      (acc, s, i) => `${acc}${i > 0 ? " " : ""}${capitalizeFirstLetter(s)}`,
      ""
    );

  return titleCaseStr.replace(
    regexPattern,
    (first(titleCaseStr.match(regexPattern)) || "").toUpperCase()
  );
});

export const numberWithCommas = (number) => {
  if (typeof number === "number") {
    return parseFloat(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "";
};

export const valueOrNaNConverter = (value) => {
  if (typeof value === "undefined" || Number.isNaN(+value)) {
    return notApplicable;
  }
  return numberWithCommas(+value);
};

/* eslint-disable-next-line */
export const kFormatter = (num) =>
  // eslint-disable-next-line no-nested-ternary
  num
    ? Math.abs(num) > 999
      ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
      : Math.sign(num) * Math.abs(num)
    : 0;

export const getPlatformsTitle = (platforms) =>
  (Array.isArray(platforms)
    ? platforms.map((item) => mediaTypesMapper[item]?.title || "").join(", ")
    : "");

export const getLeadsValue = memoizeOne((leads, goal) =>
  (!goal || goalsEnum[goal] === goalsEnum.LEAD_GENERATION
    ? `${numberWithCommas(leads) ?? ""}`
    : notApplicable));

export const getAdConfigByGoal = (adConfig) => {
  let paymentType = "";

  if (adConfig?.isCreditCardAvailable) {
    paymentType = paymentTypesEnum.CreditCard;
  } else if (adConfig?.isAmpAvailable) {
    paymentType = paymentTypesEnum.AMP;
  }

  return {
    radius: get(adConfig, "radius", ""),
    marketingBudgets: get(adConfig, "budgets", []),
    isAmpAvailable: get(adConfig, "isAmpAvailable", false),
    isCreditCardAvailable: get(adConfig, "isCreditCardAvailable", false),
    paymentType,
  };
};

export const generateListingUniqueKey = ({
  listingId,
  affiliateId,
  subAffiliateId,
}) => `${listingId}_${affiliateId}_${subAffiliateId}`;

export const getUrlParamsAsObject = () => {
  const search = window.location.search.substring(1);
  if (!search) return {};

  return JSON.parse(
    `{"${decodeURI(search)
      .replace(/"/g, "\\\"")
      .replace(/&/g, "\",\"")
      .replace(/=/g, "\":\"")}"}`
  );
};

export const getFaqContent = (content = "") => {
  if (isJson(content)) {
    return JSON.parse(content);
  }
  return { question: "", answer: "" };
};

export const getFormattedAgentName = (agencyName = "") => {
  if (agencyName && agencyName.length < 29) {
    return agencyName;
  } else {
    const position = agencyName.lastIndexOf(" ");
    return `${agencyName.slice(0, position)}\n${agencyName.slice(
      position + 1
    )}`;
  }
};

export function transformTargetingRegionData(managerTargetingRegion) {
  const transformedData = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < managerTargetingRegion.length; i++) {
    const region = managerTargetingRegion[i];
    const state = region.region;
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < region.subLocations.length; j++) {
      const subLocation = region.subLocations[j];
      transformedData.push({
        name: subLocation.subLocation,
        value: subLocation.keys.map((key) => key.key).join(", "),
        id: `${i + 100}-${j + 300}`,
        state,
        facebookPageName: region.facebookPageName,
        instagramPageName: region.instagramPageName
      });
    }
  }

  return transformedData;
}

export const getTargetingRegionsKeys = (regions) => {
  const result = [];
  regions.forEach((region) => {
    const keys = region.value.split(", ");
    result.push(...keys);
  });
  return result;
};
