import React from "react";
import MaintenanceImg from "../../assets/images/maintenance.png";

const MaintenanceView = () => (
    <div className="maintenance-wrapper">
        <div className="maintenance-content">
            <div className="maintenance-img-holder">
                <img src={MaintenanceImg} alt="" className="maintenance-img"/>
            </div>
            <p className="maintenance-title">Ad<span>Pro</span> is under maintenance</p>
            <p className="maintenance-text">Our website is currently undergoing scheduled maintenance. We should be
                back
                shortly. Thank you for your patience.</p>
        </div>
    </div>
    );

export default MaintenanceView;
