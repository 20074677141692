export const promoteStepsEnum = {
  SelectYourGoal: 1,
  ChooseYourMedia: 2,
  ReviewCopy: 3,
  ChooseListingImages: 4,
  ReviewYourAd: 5,
};

export const paymentStepsEnum = {
  SelectPaidMediaPackage: 1,
  Pay: 2,
  Confirmation: 3,
};

export const goalsEnum = {
  LEAD_GENERATION: 1,
  LINK_CLICKS: 2,
};

export const callToActionEnum = {
  ShopNow: 1,
  LearnMore: 2,
  RequestTime: 3,
  ContactUs: 6,
};

export const paymentTypesEnum = {
  AMP: 0,
  CreditCard: 1,
};

export const creativeLogoTypesEnum = {
  AgentLogo: 1,
  CompanyLogo: 2,
};

export const SocialEnum = {
  FACEBOOK: 1,
  INSTAGRAM: 2,
  STORIES: 3,
};

export const popupStatusesEnum = {
  NONE: 0,
  FAQ: 1,
  SUPPORT: 2,
};

export const googleAdPreviewTypeEnum = {
  GMAIL: 1,
  DISPLAY: 2,
  DISCOVER: 3,
};

export const userLayoutScreenEnum = {
  FirstScreen: "FirstScreen",
  SecondScreen: "SecondScreen",
  ThirdScreen: "ThirdScreen",
};

export const managerAdSteps = {
  CHOOSE_MEDIA: "CHOOSE_MEDIA",
  UPLOAD: "UPLOAD",
  WRITE_COPY: "WRITE_COPY",
  PREVIEW: "PREVIEW",
  BUDGETS: "BUDGETS",
  PAY: "PAY",
  CONFIRM: "CONFIRM",
};

export const managerAdTabs = {
  ALL_ADS: "ALL_ADS",
  IN_PROMOTION: "IN_PROMOTION",
  PROMOTION_ENDED: "PROMOTION_ENDED",
  PROMOTION_NOT_STARTED: "PROMOTION_NOT_STARTED",
  VIDEOPROCESSING: "PROMOTION_NOT_STARTED",
  PUBLISHED: "PROMOTION_NOT_STARTED",
};
