import React, { useRef } from "react";
import AutoSizeText from "src/UI/AutoSizeText/AutoSizeText";
import { getFormattedAgentName } from "src/helpers/formatters";
import useSwipeWithScrollTo from "src/hooks/useSwipeWithScrollTo";
import LogoMaker from "./LogoMaker";

const IMAGES_PADDING_CORRECTOR = 20;

const FacebookPreview = ({
  agencyLogo,
  agencyName,
  adTitle,
  isAgentViewMode,
  adId,
  adDesignation,
  isTeamViewMode,
  adLogo,
  photos,
  openPopup,
  callToAction,
  headline,
  caption,
  primaryText,
  isActive,
  disableCarouselSwipe,
  isSingleImage,
}) => {
  const isClickable = typeof openPopup === "function";
  const isMobile = window.innerWidth < 738;
  const popupCondition = isClickable && !isMobile;
  const isHiddenSliderArrows = isMobile || isClickable || isSingleImage;

  const formattedAgentName = getFormattedAgentName(agencyName);

  const swipeableRef = useRef();

  const [swipeIndex, setSwipeIndex] = useSwipeWithScrollTo({
    swipeableRef,
    disabled: disableCarouselSwipe,
    stepsCount: photos.length,
    corrector: IMAGES_PADDING_CORRECTOR,
  });

  return (
    <div className="preview-your-ad-holder">
      <div
        onClick={() => (popupCondition ? openPopup() : null)}
        className={`preview-your-ad-block your-ad-block ${
          isClickable ? "" : "not-clickable"
        } ${isActive ? "show" : "hidden"} ${
          isSingleImage ? "single-image" : ""
        }`}>
        <div className="preview-your-ad-block-head">
          <div className="preview-your-ad-block-head-column">
            <div className="preview-your-ad-logo-block">
              <img
                src={agencyLogo}
                alt="Logo"
                className="preview-your-ad-logo"
              />
            </div>
            <div className="preview-your-ad-user-info">
              <p className="preview-your-ad-user-name">{formattedAgentName}</p>
              <p className="preview-your-ad-sponsored-text">
                Sponsored
                <i className="icon-earth" />
              </p>
            </div>
          </div>
          <i className="icon-menu preview-your-ad-menu" />
        </div>
        <p className="preview-your-ad-block-info">{primaryText}</p>
        <div className="preview-your-ad-slider-container">
          <div ref={swipeableRef} className="preview-your-ad-slider-row">
            <div className="preview-your-ad-slider-row-inner">
              {Array.isArray(photos)
                ? photos.map(({ data }, index) => (
                    <div
                      key={index}
                      className={`preview-your-ad-card ${
                        index === 0 && !adId ? "has-header" : "full"
                      }`}>
                      <div
                        // id={index === 0 ? "mainImage" : ""}
                        className="preview-your-ad-card-inner">
                        {!adId ? (
                          <div className="preview-your-ad-card-head">
                            {index === 0 && isAgentViewMode ? (
                              <div className="preview-your-ad-card-head-column">
                                <h3 className="preview-your-ad-card-user">
                                  {isActive && (
                                    <AutoSizeText
                                      text={`${adTitle}`}
                                      maxFontSize={15}
                                      minFontSize={6}
                                      gap={0}
                                    />
                                  )}
                                </h3>
                                <p className="preview-your-ad-card-user-info">
                                  {adDesignation}
                                </p>
                              </div>
                            ) : null}
                            <LogoMaker
                              src={adLogo}
                              isTeamViewMode={isTeamViewMode}
                            />
                          </div>
                        ) : null}
                        <div className="preview-your-ad-card-content">
                          <img
                            src={data}
                            alt="House Photo"
                            className="preview-your-ad-card-img"
                          />
                        </div>
                      </div>
                      <div className="preview-your-ad-card-bottom">
                        <div className="preview-your-ad-card-bottom-column">
                          <p title={headline}>{headline}</p>
                          <span>{caption}</span>
                        </div>
                        <button className="preview-your-ad-card-button">
                          {callToAction}
                        </button>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
          {isHiddenSliderArrows ? null : (
            <>
              <button
                onClick={() => setSwipeIndex(swipeIndex - 1)}
                className="slider-arrow left"
                style={{ display: swipeIndex ? "flex" : "none" }}>
                <i className="icon-arrow-left" />
              </button>
              <button
                onClick={() => setSwipeIndex(swipeIndex + 1)}
                className="slider-arrow right"
                style={{
                  display: swipeIndex !== photos.length - 1 ? "flex" : "none",
                }}>
                <i className="icon-arrow-right" />
              </button>
            </>
          )}
        </div>
        <div className="preview-your-ad-block-bottom">
          <div className="preview-your-ad-block-bottom-inner">
            <div className="preview-your-ad-block-bottom-column">
              <i className="icon-like" />
              <span>like</span>
            </div>
            <div className="preview-your-ad-block-bottom-column">
              <i className="icon-comment" />
              <span>comment</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookPreview;
