import axios from "../../../api";
import { networkActions } from "../../../constants/constants";
import { generateUUID } from "../../../services/auth";
import { getURLSearchParams } from "./helper";
import managerActionsTypes from "./managerActionsTypes";

export const setManagerGroupData = (groupData) => ({
  type: managerActionsTypes.GET_MANAGER_INFO,
  payload: groupData,
});

export const setManagerReset = (isReset) => ({
  type: managerActionsTypes.SET_MANAGER_RESET,
  payload: isReset,
});

export const setManagerAgentData = (agentData) => ({
  type: managerActionsTypes.GET_MANAGER_AGENT_INFO,
  payload: agentData,
});

export const setManagerGroupLoading = (loading) => ({
  type: managerActionsTypes.SET_MANAGER_INFO_LOADING,
  payload: loading,
});

export const setManagerAgentLoading = (loading) => ({
  type: managerActionsTypes.SET_MANAGER_AGENT_LOADING,
  payload: loading,
});

export const setManagerRegions = (regions) => ({
  type: managerActionsTypes.SET_MANAGER_INFO_REGIONS,
  payload: regions,
});

export const getManagerData =
  (filter, searchValue, regionFilter) => async (dispatch) => {
    try {
      const params = getURLSearchParams(filter, searchValue, regionFilter);
      dispatch(setManagerGroupLoading(true));
      const response = await axios.get(networkActions.GET_MANAGER_INFO, {
        params,
      });

      if (!searchValue && !regionFilter?.length) {
        const regions =
          response?.data?.data?.map((item) => item.majorRegion) || [];
        dispatch(setManagerRegions(regions));
      }

      dispatch(setManagerGroupData(response?.data));
    } catch (e) {
      dispatch(setManagerGroupLoading(false));
    }
  };

export const getManagerAgentData = (userId) => async (dispatch) => {
  try {
    dispatch(setManagerAgentLoading(true));
    const uniqueIdentifier = generateUUID();
    const response = await axios.post(
      networkActions.GET_PERSONAL_DATA,
      { uniqueIdentifier },
      { params: { userId } }
    );
    dispatch(setManagerAgentData(response.data));
    dispatch(setManagerAgentLoading(false));
  } catch (e) {
    dispatch(setManagerAgentLoading(false));
  }
};
