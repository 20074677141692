import React, { useMemo } from "react";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import {
  goalsEnum,
  adPhotosMinCount,
  adPhotosMaxCount,
  adLeadPhotoMaxCount,
  adTypes,
  adGoogleMaxCount,
  adGoogleMinCount,
} from "src/constants";
import { getSelectMediaType, getMaxPhotoCount } from "src/helpers";

import {
  applyChooseImagesAction,
  setHousePhotoAction,
} from "../../../store/actions/ad/adActions";
import { setPromoteListingStepAction } from "../../../store/actions/listing/listingActions";
import {
  selectAdMediaType,
  selectAdGoal,
  selectAdFbHousePhotosSingle,
  selectAdGoogleHousePhotos,
  selectAdHousePhotosCarousel,
  selectAdType,
} from "../../../store/selectors/adSelector";
import { selectPromoteListingStep } from "../../../store/selectors/listingSelector";
import StepsCountColumn from "../promoteLayout/StepsCountColumn";
import Tooltip from "../../shared/Tooltip";

const getHelperText = (goal, isGoogle) => {
  if (isGoogle) {
    return (
      <>
        You can select maximum
        <span>{adGoogleMaxCount}</span>
      </>
    );
  }
  if (goal === goalsEnum.LEAD_GENERATION) {
    return (
      <>
        {" "}
        You can select
        <span>
          {adPhotosMinCount} - {adLeadPhotoMaxCount}
        </span>
      </>
    );
  }
  return (
    <>
      You can select maximum
      <span>{adPhotosMaxCount}</span>
    </>
  );
};

const ImagesBottomList = () => {
  const dispatch = useDispatch();

  const adMediaType = useSelector(selectAdMediaType);
  const adHouseFbPhotosSingle = useSelector(selectAdFbHousePhotosSingle);
  const adHouseGooglePhotos = useSelector(selectAdGoogleHousePhotos);
  const adHousePhotosCarousel = useSelector(selectAdHousePhotosCarousel);
  const adType = useSelector(selectAdType);
  const promoteListingStep = useSelector(selectPromoteListingStep);
  const adGoal = useSelector(selectAdGoal);
  const { isSelectedFacebook, isSelectedGoogle } =
    getSelectMediaType(adMediaType);
  const photosMaxCount = getMaxPhotoCount(adGoal, isSelectedFacebook);
  const isCarouselTabActive = adType === adTypes.CAROUSEL;
  const facebookHousesPhotos = isCarouselTabActive
    ? adHousePhotosCarousel
    : adHouseFbPhotosSingle;
  const adHousePhotos = isSelectedFacebook
    ? facebookHousesPhotos
    : adHouseGooglePhotos;
  const adHousePhotosCount = get(adHousePhotos, "length", 0);
  const enabledChooseButton = useMemo(() => {
    if (isSelectedGoogle) {
      return adHousePhotosCount >= adGoogleMinCount;
    }
    if (adType === adTypes.SINGLE) {
      return adHousePhotosCount === 1;
    }
    return adHousePhotosCount >= adPhotosMinCount;
  }, [adHousePhotosCount]);

  const goBack = () =>
    dispatch(setPromoteListingStepAction(promoteListingStep - 1));

  const isShowHelperText = isCarouselTabActive || isSelectedGoogle;

  const imageListRenderer = () => {
    const isShowOnlyOneImage = !isCarouselTabActive && isSelectedFacebook;
    const imagesList = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < photosMaxCount; i++) {
      imagesList.push(
        <div
          key={`bottom_${i}`}
          className={`choose-listing-head-images-column ${
            isShowOnlyOneImage && i >= 1 ? "empty" : ""
          }`}>
          {adHousePhotos[i] ? (
            <img
              src={adHousePhotos[i]}
              alt="House Image"
              onClick={() => dispatch(setHousePhotoAction(adHousePhotos[i]))}
              className="choose-listing-head-img"
            />
          ) : (
            <div className="choose-listing-head-no-images">
              <span>No image</span>
            </div>
          )}
        </div>
      );
    }

    return imagesList;
  };
  return (
    <div className={`steps-bottom ${isShowHelperText ? "" : "active"}`}>
      <div className="next-step-row">
        <div className="container">
          <div className="next-step-row-inner">
            <div className="next-step-left-column">
              <div className="choose-listing-selected-images">
                <div className="choose-listing-selected-images-row">
                  <div className="choose-listing-s-i-column-left">
                    <div className="choose-listing-head-column-left-content">
                      <div className="choose-listing-head-choose-column">
                        <button
                          onClick={goBack}
                          className={"button back-button"}>
                          Go Back
                        </button>
                        <button
                          className={`button ${
                            enabledChooseButton ? "" : "disabled"
                          }`}
                          onClick={() => dispatch(applyChooseImagesAction())}>
                          choose
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="choose-listing-s-i-column-right">
                    <div className="choose-listing-head-images">
                      {imageListRenderer()}
                    </div>
                  </div>
                </div>
                <div className="choose-listing-head-texts">
                  <span className="choose-listing-head-info">
                    {getHelperText(adGoal, isSelectedGoogle)}
                    images; deselect an image to remove it.
                  </span>
                  <Tooltip className="choose-step-tooltip">
                    <p>
                      Industry recommends no fewer than 3 <span className="break-line">images.</span>
                    </p>
                  </Tooltip>
                </div>
              </div>
            </div>
            <StepsCountColumn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesBottomList;
