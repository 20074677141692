export const selectPromotedAds = state => state?.insight?.promotedAds || [];
export const selectPromotedAdsLoading = state => state?.insight?.promoteAdsLoading ?? false;
export const selectFilteredPromoteAds = state => state?.insight?.filteredPromoteAds || [];
export const selectPromoteAdsFilterObject = state => state?.insight?.promoteAdsFilterObject || {};
export const selectPromoteAdsTotalResults = state => state?.insight?.total || {};
export const selectPromoteAdsFilterRange = state =>
    state?.insight?.promoteAdsFilterObject?.dateRange || [null, null];
export const selectInsightError = state => state?.insight?.insightError || null;
export const selectExposure = state => state?.insight?.exposure || [];
export const selectDevices = state => state?.insight?.devicesReport || [];
export const selectCities = state => state?.insight?.citiesReport || [];
export const selectConversion = state => state?.insight?.conversionReport || [];
export const selectConversionReportTotal = state => state?.insight?.conversionReportTotal || 0;
export const selectEffectiveness = state => state?.insight?.effectiveness || [];
export const selectHasAd = state => state?.insight?.hasAd ?? false;
export const selectCitiesChartData = state => state?.insight?.citiesChartData || [];
export const selectDeviceChartData = state => state?.insight?.deviceChartData || [];
export const selectCitiesChartDataMax = state => state?.insight?.citiesChartDataMax || 0;
export const selectInsight = state => state?.insight;
export const selectExposureTotal = state => state?.insight?.exposureTotal || 0;
export const selectShareMetricLoading = state => state?.insight?.shareMetricLoading ?? false;
