import delegationActionTypes from "../actions/delegation/delegationActionTypes";

const initialState = {
  assistants: [],
  assistedFor: [],
  assistantsLoading: false,
  assistedForLoading: false,
};

// eslint-disable-next-line default-param-last
const delegationReducer = (state = initialState, action) => {
  switch (action.type) {
    case delegationActionTypes.GET_ASSISTANTS:
      return {
        ...state,
        assistantsLoading: false,
        assistants: action.payload,
      };
    case delegationActionTypes.GET_ASSISTANTS_FOR:
      return {
        ...state,
        assistedForLoading: false,
        assistedFor: action.payload,
      };

    case delegationActionTypes.SET_ASSISTANTS_LOADING:
      return {
        ...state,
        assistantsLoading: action.payload,
      };
    case delegationActionTypes.SET_ASSISTANTS_FOR_LOADING:
      return {
        ...state,
        assistedForLoading: action.payload,
      };

    case delegationActionTypes.SET_EMPTY_ASSISTANTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default delegationReducer;
