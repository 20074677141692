/* eslint-disable no-nested-ternary */
import jwtDecode from "jwt-decode";
import { CLIENT_FINDER_API_KEY, ROLES } from "../../constants/constants";

// this variable need  since we can not release manager flow/
const isShowManagerFlow = true;

const getRootUser = () => {
  const data = localStorage.getItem(CLIENT_FINDER_API_KEY);
  const rootUser = data ? JSON.parse(data || {}) : {};
  return rootUser;
};

const getRoles = (authData) => {
  if (!isShowManagerFlow) {
    return {
      currentRole: ROLES.AGENT,
      allRoles: [ROLES.AGENT],
    };
  }
  const decoded = jwtDecode(authData.token);
  const roles = JSON.parse(decoded.role);

  const isAgent = roles.includes(ROLES.AGENT);
  const isRegionalExecutive = roles.includes(ROLES.REGIONALEXECUTIVE);
  const isRegionalManager = roles.includes(ROLES.REGIONALMANAGER);
  const isSalesManager = roles.includes(ROLES.SALESMANAGER);
  const isMarketingManager = roles.includes(ROLES.MARKETINGMANAGER);
  let role = ROLES.AGENT;

  if (
    isRegionalExecutive ||
    isRegionalManager ||
    isSalesManager ||
    isMarketingManager
  ) {
    role = ROLES.MANAGER;
  } else if (isAgent) {
    role = ROLES.AGENT;
  }

  return {
    currentRole: role,
    allRoles: roles,
  };
};

const getRootUserCompany = () => {
  const rootUserData = getRootUser();
  return rootUserData?.company;
};

const setRootUserData = (authData, uniqueIdentifier) => {
  try {
    const existingAuthData = getRootUser();
    if (existingAuthData) {
      localStorage.removeItem(CLIENT_FINDER_API_KEY);
    }

    const { currentRole, allRoles } = getRoles(authData);

    authData.role = currentRole;
    authData.roles = allRoles;
    const data = JSON.stringify({ ...authData, uniqueIdentifier });
    localStorage.setItem(CLIENT_FINDER_API_KEY, data);
  } catch (e) {
    localStorage.removeItem(CLIENT_FINDER_API_KEY);
  }
};

const setRefreshToken = (refreshToken, uniqueIdentifier) => {
  const existingAuthData = getRootUser();
  if (existingAuthData) {
    localStorage.removeItem(CLIENT_FINDER_API_KEY);
  }
  const data = JSON.stringify({
    ...existingAuthData,
    ...refreshToken,
    uniqueIdentifier,
  });
  localStorage.setItem(CLIENT_FINDER_API_KEY, data);
};

const clearAuthData = () => {
  localStorage.clear();
};

const setAuthRole = (newRole) => {
  try {
    const existingAuthData = getRootUser();
    if (existingAuthData && newRole) {
      const data = JSON.stringify({
        ...existingAuthData,
        role: newRole,
      });
      localStorage.setItem(CLIENT_FINDER_API_KEY, data);
    }
    return Promise.resolve();
  } catch {
    return Promise.reject();
  }
};

const getDelegationAgent = () => {
  try {
    const existingAuthData = getRootUser();
    if (existingAuthData && existingAuthData?.delegateUserId) {
      return existingAuthData?.delegateUserId;
    }
    return undefined;
  } catch {
    return undefined;
  }
};

const setDelegateUserId = (delegateUserId) => {
  try {
    const existingAuthData = getRootUser();
    if (existingAuthData) {
      const data = JSON.stringify({
        ...existingAuthData,
        delegateUserId,
      });
      localStorage.setItem(CLIENT_FINDER_API_KEY, data);
    }
    return Promise.resolve();
  } catch {
    return Promise.reject();
  }
};

const authenticationService = {
  getRootUser,
  setRootUserData,
  clearAuthData,
  setRefreshToken,
  setAuthRole,
  getRoles,
  getDelegationAgent,
  setDelegateUserId,
  getRootUserCompany,
};

export const generateUUID = () => {
  let d = new Date().getTime();
  let d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line no-bitwise,no-mixed-operators
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export default authenticationService;
