import { useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import StepsContainer from "./StepsContainer";
import { promoteStepsEnum } from "../../../constants/enums";
import SelectYourGoalStep from "../selectYourGoal/SelectYourGoalView";
import ChooseYourMediaStep from "../chooseYourMedia/ChooseYourMediaStep";
import ReviewCopyView from "../reviewCopy/ReviewCopyView";
import ChooseListingImagesView from "../chooseListingImages/ChooselistingImagesView";
import PreviewYourAdView from "../previewYourAd/PreviewYourAdView";

const PromoteSteps = ({ step }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.history.pushState(null, null, location.pathname);

    const disableBackButton = () => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, null, window.location.href);
      };
    };

    disableBackButton();
  }, []);

  const renderPromoteStep = () => {
    switch (step) {
      case promoteStepsEnum.SelectYourGoal:
        return <SelectYourGoalStep />;
      case promoteStepsEnum.ChooseYourMedia:
        return <ChooseYourMediaStep />;
      case promoteStepsEnum.ReviewCopy:
        return <ReviewCopyView />;
      case promoteStepsEnum.ChooseListingImages:
        return <ChooseListingImagesView />;
      case promoteStepsEnum.ReviewYourAd:
        return <PreviewYourAdView />;
      default:
        return null;
    }
  };

  return <StepsContainer>{renderPromoteStep()}</StepsContainer>;
};

PromoteSteps.propTypes = {
  step: PropTypes.oneOf(Object.values(promoteStepsEnum)).isRequired,
};

export default PromoteSteps;
