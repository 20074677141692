export const selectMarketingBudgets = state => state?.payment?.marketingBudgets || [];
export const selectIsAmpAvailable  = state => state?.payment?.isAmpAvailable;
export const selectIsCreditCardAvailable  = state => state?.payment?.isCreditCardAvailable;
export const selectPaymentType = state => state?.payment?.paymentType;
export const selectRadius = state => state?.payment?.radius;
export const selectAmpBalance = state => state?.payment?.ampBalance;
export const selectIsEnoughAmpBalance = state => state?.payment?.isEnoughAmpBalance;
export const selectPaymentLoading = state => state?.payment?.paymentLoading;
export const selectPayWithCreditCard = state => state?.payment?.payWithCreditCard;
export const selectCreditFullName = state => state?.payment?.payWithCreditCard?.fullName || "";
export const selectCreditCardNumber = state => state?.payment?.payWithCreditCard?.cardNumber || "";
export const selectCreditExpirationDate = state => state?.payment?.payWithCreditCard?.expirationDate || "";
export const selectCreditBillingZipCode = state => state?.payment?.payWithCreditCard?.billingZipCode || "";
