import contentActionTypes from "../actions/content/contentActionTypes";

const initialState = {
    faq: {
        content: [],
        loading: false,
        error: false
    },
};

// eslint-disable-next-line default-param-last
const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case contentActionTypes.GET_FAQ_CONTENT_DATA:
            return {
                ...state,
                faq: {
                    ...initialState.faq,
                    loading: true,
                }
            };
        case contentActionTypes.SAVE_FAQ_CONTENT_DATA:
            return {
                ...state,
                faq: {
                    content: action.payload,
                    loading: false,
                    error: false,
                }
            };
        case contentActionTypes.SET_ERROR_DATA:
            return {
                ...state,
                faq: {
                    ...initialState.faq,
                    error: true,
                }
            };
        default:
            return state;
    }
};

export default contentReducer;
