import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";

import auth from "../../services/auth";
import { routesPath } from "../../constants/constants";
import { loginRequest } from "../../services/authConfig";
import fetchAuthenticateSSO from "../../api/sso";
import { setDoesNotUserCase } from "../../store/actions/permission/permissionActions";
import { AgentRouter, ManagerRouter } from "./Routers";
import { selectIsManager } from "../../store/selectors/permissionSelector";

const MainLayout = () => {
  const company = auth?.getRootUserCompany();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const history = useHistory();
  const isManager = useSelector(selectIsManager);

  const isAuthenticated = useIsAuthenticated();

  const { AUTHENTICATE, ISSUE, SSO } = routesPath;

  // eslint-disable-next-line no-nested-ternary, no-unneeded-ternary
  const redirectionUrl = company ? company : isAuthenticated ? SSO : ISSUE;

  useEffect(() => {
    const company = auth?.getRootUserCompany();

    if (company) {
      return;
    }
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        fetchAuthenticateSSO(response.accessToken)
          .then((res) => {
            if (res?.data?.token) {
              return history.push(`/${AUTHENTICATE}?token=${res.data.token}`);
            }
            history.push(`/${ISSUE}`);
          })
          .catch((error) => {
            if (error?.response?.status === 404) {
              dispatch(setDoesNotUserCase(true));
            }
            history.push(`/${ISSUE}`);
          });
      });
  }, []);

  if (isManager) return <ManagerRouter redirectionUrl={redirectionUrl} />;
  return <AgentRouter redirectionUrl={redirectionUrl} />;
};

export default MainLayout;
