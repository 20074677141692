import auth from "../../services/auth";
import userActionTypes from "../actions/user/userActionTypes";

const delegationUserId = auth.getDelegationAgent();
const personalData = auth.getRootUser();

const initialState = {
  personalData,
  delegateUser: null,
  delegationUserId,
  delegateUserLoading: false,
};

// eslint-disable-next-line default-param-last
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.SET_DELEGATE_USER_LOADING:
      return {
        ...state,
        delegateUserLoading: action.payload,
      };
    case userActionTypes.SET_DELEGATE_USER:
      return {
        ...state,
        delegateUserLoading: false,
        delegateUser: action.payload,
      };

    case userActionTypes.DELETE_DELEGATE_USER:
      return {
        ...state,
        delegateUser: null,
        delegationUserId: undefined,
      };
    case userActionTypes.SET_DELEGATE_USER_ID:
      return {
        ...state,
        delegationUserId: action.payload,
      };
      case userActionTypes.SET_PERSONAL_DATA:
        return {
          ...state,
          personalData: action.payload,
        };
    default:
      return state;
  }
};

export default userReducer;
