import {
  getAssistantsRequest,
  getAssistedForRequest,
} from "../../../api/delegation";
import delegationActionTypes from "./delegationActionTypes";

export const setAssistantsLoading = (loading) => (dispatch) => {
  dispatch({
    type: delegationActionTypes.SET_ASSISTANTS_LOADING,
    payload: loading,
  });
};

export const setAssistedForLoading = (loading) => (dispatch) => {
  dispatch({
    type: delegationActionTypes.SET_ASSISTANTS_FOR_LOADING,
    payload: loading,
  });
};

export const setAssistants = (assistants) => (dispatch) => {
  dispatch({
    type: delegationActionTypes.GET_ASSISTANTS,
    payload: assistants,
  });
};

export const setAssistedFor = (assistedFor) => (dispatch) => {
  dispatch({
    type: delegationActionTypes.GET_ASSISTANTS_FOR,
    payload: assistedFor,
  });
};

export const getAssistantsAction = () => async (dispatch) => {
  try {
    dispatch(setAssistantsLoading(true));
    const response = await getAssistantsRequest();
    dispatch(setAssistants(response.data));
  } catch (error) {
    dispatch(setAssistantsLoading(false));
  }
};

export const getAssistedForAction = () => async (dispatch) => {
  try {
    dispatch(setAssistedForLoading(true));
    const response = await getAssistedForRequest();
    dispatch(setAssistedFor(response.data));
  } catch (error) {
    dispatch(setAssistedForLoading(false));
  }
};
