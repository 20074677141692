import React, { useState } from "react";

const AdGeneratePreviewLogo = ({ logoUrl }) => {
  const [visibility, setVisibility] = useState(Boolean(logoUrl));

  return (
    <div className={`ad-generate-logo ${!visibility ? "invisibility" : ""}`}>
      <img
        src={logoUrl}
        onLoad={() => {
          setVisibility(true);
        }}
        onError={() => {
          setVisibility(false);
        }}
      />
    </div>
  );
};

export default AdGeneratePreviewLogo;
