import React, { useState, memo, useEffect } from "react";
import get from "lodash/get";

const HeaderAvatar = ({ userData }) => {
  const imageUrl = get(userData, "avatarUrl", "");
  const [isImageAvailable, setIsImageAvailable] = useState(Boolean(imageUrl));
  const nameFirstLetter = get(userData, "firstName[0]", "").toUpperCase();

  useEffect(() => {
    if (imageUrl) {
      setIsImageAvailable(true);
    } else {
      setIsImageAvailable(false);
    }
  }, [imageUrl]);
  return (
    <>
      <div className="avatar-column">
        {isImageAvailable ? (
          <img
            src={imageUrl}
            alt="avatar"
            onError={() => setIsImageAvailable(false)}
            className="avatar-img s"
          />
        ) : (
          <span className="avatar-img placeholder s">{nameFirstLetter}</span>
        )}
      </div>
    </>
  );
};

export default memo(HeaderAvatar);
