import cloneDeep from "lodash/cloneDeep";
import {
  agentListOfListingsInfoInitial,
  listingTabsNamesMapper,
} from "../../constants/constants";
import listingActionTypes from "../actions/listing/listingActionTypes";

const initialStateAgentListOfListings = {
  data: [],
  filteredData: [],
  filter: { tab: listingTabsNamesMapper.all.toString(), search: "" },
  info: cloneDeep(agentListOfListingsInfoInitial),
};
export const initialStateAgentListingsFilter = {
  pageNumber: 0,
  selectedType: "listingId",
  searchText: "",
};
export const initialStateListingsImages = { data: {}, loading: false };

const defaultListingAddress = {
  fullAddress: "",
  state: "",
  isActive: true,
  inactive: false,
  externalId: "",
};

const initialState = {
  listingAddress: defaultListingAddress,
  listingAddressLoading: false,
  agentListings: [],
  agentListingsFilter: initialStateAgentListingsFilter,
  agentListingsPage: 0,
  listingImages: initialStateListingsImages,
  isListingAllLoaded: false,
  listingInfo: {},
  listingFbInfo: {},
  googleAdConfig: [],
  agentListingsLoading: true,
  analyticsLoading: true,
  agentListingsError: null,
  promoteListingStep: null,
  paymentListingStep: null,
  stepLoading: false,
  dashboardSelectedTab: null,
  agentListOfListings: initialStateAgentListOfListings,
  listingsUnderMaintenance: false,
  // currentListingActiveStatus: true,
};

// eslint-disable-next-line default-param-last
const listingReducer = (state = initialState, action) => {
  switch (action.type) {
    case listingActionTypes.SAVE_AGENT_ALL_LISTINGS:
      return {
        ...state,
        agentListings: action.payload.listing,
        agentListingsFilter: action.payload.agentListingsFilter,
        isListingAllLoaded: false,
        agentListingsLoading: false,
        agentListingsError: null,
        listingsUnderMaintenance: false,
      };
    case listingActionTypes.EMPTY_AGENT_ALL_LISTINGS:
      return {
        ...state,
        agentListings: [],
      };
    case listingActionTypes.SAVE_AGENT_ALL_LISTINGS_FILTER:
      return {
        ...state,
        agentListingsFilter: action.payload,
      };
    case listingActionTypes.SAVE_AGENT_ALL_LISTING_PAGE_NUMBER:
      return {
        ...state,
        agentListingsPage: action.payload,
      };
    case listingActionTypes.SET_IS_AGENT_ALL_LISTING_LOADED:
      return {
        ...state,
        isListingAllLoaded: action.payload,
        agentListingsLoading: false,
      };
    case listingActionTypes.GET_AGENT_ALL_LISTINGS_LOADING:
      return {
        ...state,
        agentListingsLoading: action.payload,
      };
    case listingActionTypes.SET_ANALYTICS_LOADED:
      return {
        ...state,
        analyticsLoading: action.payload,
      };
    case listingActionTypes.SAVE_AGENT_ALL_LISTINGS_ERROR:
      return {
        ...state,
        agentListingsError: action.payload,
        agentListingsLoading: false,
        stepLoading: false,
      };
    case listingActionTypes.SAVE_LISTING_INFO:
      return {
        ...state,
        listingInfo: action.payload,
        stepLoading: false,
      };
    case listingActionTypes.SAVE_LISTING_FB_INFO:
      return {
        ...state,
        listingFbInfo: action.payload,
      };
    case listingActionTypes.EMPTY_LISTING_INFO:
      return {
        ...state,
        listingInfo: {},
        listingFbInfo: {},
        promoteListingStep: null,
        paymentListingStep: null,
      };
    case listingActionTypes.SET_PROMOTE_LISTING_STEP:
      return {
        ...state,
        promoteListingStep: action.payload,
      };
    case listingActionTypes.SET_PAYMENT_LISTING_STEP:
      return {
        ...state,
        paymentListingStep: action.payload,
      };
    case listingActionTypes.SET_STEP_LOADING:
      return {
        ...state,
        stepLoading: action.payload,
      };
    case listingActionTypes.SET_ANALYTICS_DASHBOARD_TAB:
      return {
        ...state,
        dashboardSelectedTab: action.payload,
      };
    case listingActionTypes.SAVE_AGENT_LIST_OF_LISTINGS:
      return {
        ...state,
        agentListOfListings: {
          ...state.agentListOfListings,
          data: action.payload.data,
          filteredData: action.payload.filteredData,
          info: action.payload.info,
        },
      };
    case listingActionTypes.SAVE_AGENT_LIST_OF_LISTINGS_FILTER:
      return {
        ...state,
        agentListOfListings: {
          ...state.agentListOfListings,
          filter: action.payload,
        },
      };
    case listingActionTypes.SAVE_GOOGLE_AD_CONFIG:
      return {
        ...state,
        googleAdConfig: action.payload,
      };
    case listingActionTypes.EMPTY_AGENT_LIST_OF_LISTINGS:
      return {
        ...state,
        agentListOfListings: initialStateAgentListOfListings,
      };
    case listingActionTypes.SAVE_LISTINGS_ADDRESS:
      return {
        ...state,
        listingAddress: action.payload,
        listingAddressLoading: false,
      };
    case listingActionTypes.SAVE_LISTINGS_ADDRESS_LOADING:
      return {
        ...state,
        listingAddressLoading: action.payload,
      };
    case listingActionTypes.SAVE_LISTINGS_FAIR_HOUSING:
      return {
        ...state,
        listingAddress: action.payload,
        listingAddressLoading: false,
      };
    case listingActionTypes.SET_LISTING_IMAGES_LOADING:
      return {
        ...state,
        listingImages: {
          ...state.listingImages,
          loading: action.payload,
        },
      };
    case listingActionTypes.SAVE_LISTING_IMAGES:
      return {
        ...state,
        listingImages: {
          data: {
            ...state.listingImages.data,
            ...action.payload,
          },
          loading: false,
        },
        loading: false,
      };
    case listingActionTypes.SET_UNDER_MAINTENANCE_LISTING:
      return {
        ...state,
        listingsUnderMaintenance: action.payload,
        isListingAllLoaded: true,
        agentListingsLoading: false,
      };
    default:
      return state;
  }
};

export default listingReducer;
