import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import SSORedirectionView from "./components/sso/SSORedirectionView";
import Root from "./components/Root";
import { getUrlParamsAsObject } from "./helpers/formatters";
import auth from "./services/auth";
import { isSSOAuth } from "./constants/constants";

function App() {
  const rootUser = auth?.getRootUser();
  const urlParams = getUrlParamsAsObject();

  if (isSSOAuth && !urlParams?.token && !rootUser?.token) {
    return (
      <>
        <AuthenticatedTemplate>
          <Root />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <SSORedirectionView />
        </UnauthenticatedTemplate>
      </>
    );
  }

  return <Root />;
}

export default App;
