import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIsManager } from "../../helpers/roles";
import auth, { generateUUID } from "../../services/auth";
import axios from "../../api";
import { networkActions, routesPath } from "../../constants/constants";
import setPermissionIssueStatusAction, {
  setDoesNotUserCase, setIsManager,
} from "../../store/actions/permission/permissionActions";
import { getBrandAction } from "../../store/actions/brand/brandActions";
import { selectBrandName } from "../../store/selectors/brandSelector";
import { selectAgentListingsFilter } from "../../store/selectors/listingSelector";
import { setAgentListingFilterAction } from "../../store/actions/listing/listingActions";
import { setPersonalData } from "../../store/actions/user/userAction";

/**
 * Authenticate component for checking token in url.
 * @returns {ReactNode}
 */
const Authenticate = () => {
  const { ISSUE } = routesPath;

  const history = useHistory();

  const brandName = useSelector(selectBrandName);
  const agentListingFilter = useSelector(selectAgentListingsFilter);

  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getRootUserData();
  }, []);

  useEffect(() => {
    if (brandName) {
      history.replace(`/${brandName.replaceAll(" ", "")}`);
    }
  }, [brandName]);

  const getUrlParamsAsObject = () => {
    const search = window.location.search.substring(1);

    return JSON.parse(
      `{"${decodeURI(search)
        .replace(/"/g, "\\\"")
        .replace(/&/g, "\",\"")
        .replace(/=/g, "\":\"")}"}`
    );
  };

  async function getRootUserData() {
    const urlParams = getUrlParamsAsObject();
    if (urlParams?.token) {
      const uniqueIdentifier = generateUUID();
      auth.setRootUserData({ token: urlParams.token }, uniqueIdentifier);

      try {
        const rootUserDataResponse = await axios.post(
          networkActions.GET_PERSONAL_DATA,
          { uniqueIdentifier }
        );

        auth.setRootUserData(rootUserDataResponse.data, uniqueIdentifier);
        const { currentRole } = auth.getRoles(rootUserDataResponse.data);
        const isManager = getIsManager(currentRole);
        dispatch(setPersonalData(rootUserDataResponse.data));
        dispatch(setIsManager(isManager));
        if (urlParams.listingId) {
          const listingFilter = { ...agentListingFilter };
          listingFilter.listingId = urlParams.listingId;
          await dispatch(setAgentListingFilterAction(listingFilter));
        }

        await dispatch(getBrandAction());
      } catch (error) {
        if (error?.response?.status === 404) {
          dispatch(setDoesNotUserCase(true));
        }
        history.push(`/${ISSUE}`);
      }
    }

    if (!urlParams?.token) {
      history.push(`/${ISSUE}`);
    }
    dispatch(setPermissionIssueStatusAction(!urlParams?.token));
  }

  return <div />;
};

export default Authenticate;
