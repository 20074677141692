import moment from "moment/moment";

export const hasObjectProps = (obj) =>
  !(!obj || (typeof obj === "object" && Object.keys(obj).length === 0));

export const isValidHeadline = (headline) => {
  if (!headline || headline.trim().length === 0) {
    return false;
  }

  // regex

  return true;
};

export const isValidPrimaryText = (primaryText, maxLength) => {
  if (!primaryText) {
    return false;
  }

  if (primaryText.trim().length === 0) {
    return false;
  }

  return primaryText.length <= maxLength;
};

export const isValidFullName = (fullName) => {
  const words = fullName.trim().split(" ");
  const isValidCharacterCount = words.every((word) => word.length >= 2);

  const fullNameValidationRegex =
    /^[A-Za-z]([-']?[A-Za-z]+)*( [A-Za-z]([-']?[A-Za-z]+)*)+$/;

  return isValidCharacterCount && fullNameValidationRegex.test(fullName.trim());
};

export const isValidCardNumber = (cardNumber) => {
  const cardNumberValidationRegex =
    /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

  return cardNumberValidationRegex.test(cardNumber.replaceAll(" ", ""));
};

export const isValidZipCode = (zipCode) => {
  const zipCodeRegex = /^[0-9]{5}(-[0-9]{4})?$/;

  return zipCodeRegex.test(zipCode);
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
};

export const isEllimanEmail = (email) => {
  const ellimanRegex = /@elliman.com$/;
  return ellimanRegex.test(email);
};

export const isValidExpirationDate = (input) => {
  const expiry = moment(input, "MM/YY");
  const currentDate = moment();
  if (input.length > 4) {
    if (expiry.year() === currentDate.year()) {
      return expiry.month() >= currentDate.month();
    } else {
      return expiry.year() > currentDate.year();
    }
  }
  return false;
};

// export const isValidCVV = cvv => {
//     const cvvValidationRegex = /^[0-9]{3,4}$/;
//
//     return cvv && cvvValidationRegex.test(cvv);
// };

export const isOnlyNumbers = (string) => {
  const onlyNumbersRegex = /^\d+$/;

  return onlyNumbersRegex.test(string);
};

export const numberHasDecimalPlace = (n) => n - Math.floor(n) !== 0;

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isUrlValid = (url) => {
  const regex =
    // eslint-disable-next-line no-useless-escape
    /^((https?)(:\/\/))?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

  return regex.test(url.toLowerCase());
};
