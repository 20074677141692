import React, { memo } from "react";
import noop from "lodash/noop";

import Avatar from "../Avatar";

const DelegateSingleUser = ({
  userName,
  userEmail,
  avatarUrl,
  firstName = "",
  lastName = "",
  active = false,
  withIcon = false,
  onClick = noop,
  onClickIcon = noop,
}) => {
  const placeholderName = `${firstName[0]}${lastName[0]}`;
  return (
    <div
      className={`delegate-single-user ${active ? "cursor" : ""}`}
      onClick={onClick}
    >
      <div className="delegate-user-info">
        <div className="delegate-avatar">
          <Avatar
            placeholderName={placeholderName}
            imageUrl={avatarUrl}
            name={userName}
            className="user-avatar"
          />
        </div>
        <div className="delegate-info-container">
          <h5 className="delegate-name">{userName}</h5>
          <p className="delegate-email">{userEmail}</p>
        </div>
      </div>
      {withIcon && (
        <div onClick={onClickIcon} className="delegate-close-container" >
          <i className="icon-close"></i>
        </div>
      )}
    </div>
  );
};

export default memo(DelegateSingleUser);
