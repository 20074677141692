import React, { memo } from "react";

const AdSimpleImage = ({ photoUrl, isHidden = true, setRef }) => (
  <div
    className={`simple-image-container ${isHidden ? "visible-hidden" : ""}`}
    ref={setRef}
  >
    <img src={photoUrl} alt="House Photo" className="simple-image" />
  </div>
);

export default memo(AdSimpleImage);
