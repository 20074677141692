import axios from "../index";
import { networkActions } from "../../constants/constants";

export const managerPublishImage = (formData) =>
  axios.post(networkActions.MANAGER_PUBLISH_IMAGES, formData);

export const managerPublishVideo = (data) =>
  axios.post(networkActions.MANAGER_PUBLISH_VIDEO, data);

export const uploadManagerVideo = (formData) =>
  axios.post(networkActions.MANAGER_UPLOAD_VIDEO, formData);

export const getManagerTargetingRequest = () =>
  axios.get(networkActions.MANAGER_TARGETING);

export const getManagerAdAllAnalyticsRequest = () =>
  axios.get(networkActions.GET_PROMOTED_ADS);

export const getManagerAdDetailsRequest = (adId) =>
  axios.get(networkActions.GET_INSIGHT_DETAILS, {
    params: { adId },
  });

export const cancelManagerAd = (internalAdId) =>
  axios.patch(`${networkActions.CANCEL_AD}?internalAdId=${internalAdId}`);

export const getManagerAdInfoRequest = (adId) =>
  axios.get(networkActions.GET_AD_PREVIEW_INFO, {
    params: { internalAdId: adId },
  });
