import managerActionsTypes from "../actions/manager/managerActionsTypes";

const initialState = {
  data: null,
  loading: false,
  agent: null,
  regions: [],
  managerAgentLoading: false,
  resetManager: false
};
// eslint-disable-next-line default-param-last
const managerReducer = (state = initialState, action) => {
  switch (action.type) {
    case managerActionsTypes.GET_MANAGER_INFO:
      return {
        ...state,
        data: action.payload,
        loading: false,
        resetManager: false
      };
    case managerActionsTypes.SET_MANAGER_INFO_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case managerActionsTypes.GET_MANAGER_AGENT_INFO:
      return {
        ...state,
        agent: action.payload,
      };
    case managerActionsTypes.SET_MANAGER_INFO_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };
    case managerActionsTypes.SET_MANAGER_AGENT_LOADING:
      return {
        ...state,
        managerAgentLoading: action.payload,
      };
      case managerActionsTypes.SET_MANAGER_RESET:
        return {
          ...state,
          resetManager: action.payload,
        };
    default:
      return state;
  }
};

export default managerReducer;
