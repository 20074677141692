import { getDelegationUserRequest } from "../../../api/delegation";
import authenticationService from "../../../services/auth";
import userActionTypes from "./userActionTypes";
import {
  emptyAdAction,
  emptyAgentListOfListingsAction,
  emptyAllListingAction,
  emptyListingInfoAction,
  setAgentListingFilterAction,
  setAgentListingLoading,
} from "../listing/listingActions";
import { emptyAdDataAction } from "../ad/adActions";
import { emptyPaymentDataAction } from "../payment/paymentActions";
import { initialStateAgentListingsFilter } from "../../reducers/listingReducer";

export const setDelegateUser = (delegateUser) => (dispatch) => {
  dispatch({
    type: userActionTypes.SET_DELEGATE_USER,
    payload: delegateUser,
  });
};

export const setPersonalData = (personalData) => (dispatch) => {
  dispatch({
    type: userActionTypes.SET_PERSONAL_DATA,
    payload: personalData,
  });
};

export const setDelegateUserLoading = (loading) => (dispatch) => {
  dispatch({
    type: userActionTypes.SET_DELEGATE_USER_LOADING,
    payload: loading,
  });
};

export const deleteDelegateUser = () => (dispatch) => {
  dispatch({
    type: userActionTypes.DELETE_DELEGATE_USER,
  });
};

export const setDelegateUserId = (delegateUserId) => (dispatch) => {
  dispatch({
    type: userActionTypes.SET_DELEGATE_USER_ID,
    payload: delegateUserId,
  });
};

export const getDelegateUser = (delegationUserId) => async (dispatch) => {
  try {
    dispatch(setDelegateUserLoading(true));
    const response = await getDelegationUserRequest(delegationUserId);
    dispatch(setDelegateUser(response.data));
    dispatch(setDelegateUserId(delegationUserId));
  } catch (error) {
    authenticationService.setDelegateUserId(undefined);
    dispatch(setDelegateUserLoading(false));
    dispatch(deleteDelegateUser());
  }
};

export const changeDelegation =
  (delegationUserId, history) => async (dispatch) => {
    try {
      dispatch(setDelegateUserLoading(true));
      authenticationService.setDelegateUserId(delegationUserId);
      const response = await getDelegationUserRequest(delegationUserId);
      dispatch(emptyAgentListOfListingsAction());
      dispatch(setAgentListingFilterAction(initialStateAgentListingsFilter));
      dispatch(emptyAdAction());
      dispatch(setDelegateUser(response.data));
      dispatch(setDelegateUserId(delegationUserId));
      history.push("/");
    } catch (error) {
      dispatch(setDelegateUserLoading(false));
      dispatch(deleteDelegateUser());
    }
  };

export const discardDelegation = () => (dispatch) => {
  authenticationService.setDelegateUserId("");
  dispatch(emptyAllListingAction());
  dispatch(emptyAdDataAction());
  dispatch(emptyListingInfoAction());
  dispatch(emptyPaymentDataAction());
  dispatch(emptyAllListingAction());
  dispatch(emptyAgentListOfListingsAction());
  dispatch(setAgentListingFilterAction(initialStateAgentListingsFilter));
  dispatch(setDelegateUser(null));
  dispatch(setDelegateUserId(undefined));
  dispatch(setAgentListingLoading(true));
  window.scrollTo(0, 0);
};
