import React from "react";
import SupportPopup from "../support";

const Footer = ({ className = "" }) => (
  <div className={`footer ${className}`}>
    <div className="container">
      <div className="footer-inner">
        <p className="copyright-text">
          <span className="copyright-text-info">Powered by Purlin.ai</span>
        </p>
        <SupportPopup />
      </div>
    </div>
  </div>
);

export default Footer;
