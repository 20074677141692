import React from "react";
import { PropTypes } from "prop-types";
import AutoSizeText from "src/UI/AutoSizeText/AutoSizeText";
import LogoMaker from "./LogoMaker";

const InstagramStorePreview = ({
  agencyLogo,
  agencyName,
  adTitle,
  isAgentViewMode,
  adId,
  adDesignation,
  isTeamViewMode,
  adLogo,
  photos,
  openPopup,
  callToAction,
  headline,
  isActive,
}) => {
  const isClickable = typeof openPopup === "function";
  const popupCondition = isClickable && window.innerWidth > 738;
  const joinedAgencyName = agencyName.replaceAll(" ", "");
  return (
    <div
      className="preview-your-ad-holder"
      onClick={() => (popupCondition ? openPopup() : null)}>
      <div
        className={`story-insta-your-ad-block your-ad-block ${
          isActive ? "show" : "hidden"
        }  ${isClickable ? "" : "not-clickable"}`}>
        {/* remove inline style */}
        <div className="story-insta-your-ad-head">
          <span className="story-insta-time-row" />
          <div className="story-insta-your-ad-head-inner">
            <div className="story-insta-your-ad-head-column">
              <img
                src={agencyLogo}
                alt="Logo"
                className="story-insta-ad-logo"
              />
              <div className="story-insta-ad-head-texts">
                <p>{joinedAgencyName}</p>
                <span>
                  Sponsored · <i className="icon-right" /> Expand story
                </span>
              </div>
            </div>
            <div className="story-insta-your-ad-icons-column">
              <i className="icon-menu story-insta-ad-menu" />
              <i className="icon-close story-insta-ad-menu" />
            </div>
          </div>
        </div>
        <div className="story-insta-your-ad-content">
          <div
            className={`preview-your-ad-card ${
              !adId ? "has-header" : "popup-image"
            }`}>
            <div className="preview-your-ad-card-inner">
              {!adId ? (
                <div className="preview-your-ad-card-head">
                  {isAgentViewMode ? (
                    <div className="preview-your-ad-card-head-column">
                      <h3 className="preview-your-ad-card-user">
                        {isActive && (
                          <AutoSizeText
                            text={adTitle}
                            maxFontSize={15}
                            minFontSize={6}
                            gap={0}
                          />
                        )}
                      </h3>
                      <p className="preview-your-ad-card-user-info">
                        {adDesignation}
                      </p>
                    </div>
                  ) : null}
                  <LogoMaker src={adLogo} isTeamViewMode={isTeamViewMode} />
                </div>
              ) : null}
              <div className="preview-your-ad-card-content">
                <img
                  src={photos?.[0]?.data}
                  alt="House Photo"
                  className="preview-your-ad-card-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="story-insta-your-ad-bottom">
          <div className="story-insta-your-ad-bottom-text-holder">
            <div className="story-insta-your-ad-bottom-text-h">
              <p className="story-insta-your-ad-bottom-text">
                <span> {headline} </span>
              </p>
            </div>
          </div>
          <div className="story-insta-your-ad-button-row">
            <i className="icon-arrow-insta-story" />
            <button>{callToAction}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

InstagramStorePreview.prototype = {
  adId: PropTypes.string,
  mainImageRef: PropTypes.object,
  agencyLogo: PropTypes.string,
  agencyName: PropTypes.string,
  adTitle: PropTypes.string,
  isAgentViewMode: PropTypes.bool,
  adDesignation: PropTypes.object,
  isTeamViewMode: PropTypes.bool,
  adLogo: PropTypes.string,
  photos: PropTypes.array,
};

export default InstagramStorePreview;
