import moment from "moment";
import supportActionTypes from "./supportActionTypes";
import axios from "../../../api";
import { getStore } from "../../index";
import {
  selectIssueAttachments,
  selectIssueDescription,
  selectIssueType,
} from "../../selectors/supportSelector";
import { notificationsServices } from "../../../helpers/notifications";
// eslint-disable-next-line import/named
import {
  issueTypesList,
  networkActions,
  pages,
  routesPath,
} from "../../../constants/constants";
import {
  selectPaymentListingStep,
  selectPromoteListingStep,
} from "../../selectors/listingSelector";
import { paymentStepsEnum, promoteStepsEnum } from "../../../constants/enums";
import { selectCurrentUser } from "../../selectors/userSelector";

export const saveIssueTypeAction = (issueType) => (dispatch) => {
  dispatch({
    type: supportActionTypes.SET_ISSUE_TYPE,
    payload: issueType,
  });
};

export const saveIssueDescriptionAction = (description) => (dispatch) => {
  dispatch({
    type: supportActionTypes.SET_ISSUE_DESCRIPTION,
    payload: description,
  });
};

export const saveAttachmentsAction = (attachments) => (dispatch) => {
  dispatch({
    type: supportActionTypes.SET_ATTACHMENTS,
    payload: attachments,
  });
};

export const setSendEmailLoadingAction = (loading) => (dispatch) => {
  dispatch({
    type: supportActionTypes.SEND_EMAIL_LOADING,
    payload: loading,
  });
};

export const emptySupportData = () => (dispatch) => {
  dispatch({
    type: supportActionTypes.EMPTY_SUPPORT_DATA,
  });
};

export const setSentStatusAction = (status) => (dispatch) => {
  dispatch({
    type: supportActionTypes.SET_SENT_STATUS,
    payload: status,
  });
};

const detectPage = () => {
  const store = getStore();
  const promoteListingStep = selectPromoteListingStep(store);
  const paymentListingStep = selectPaymentListingStep(store);
  const pathname = window.location.pathname;
  const pathList = pathname.split("/");

  const { ALL, TRAFFIC, PERFORMANCE, ANALYTICS } = routesPath;

  if (pathList.includes(ALL)) {
    return pages.paidAdPerformance;
  }

  if (pathList.includes(TRAFFIC)) {
    return pages.totalListingTraffic;
  }

  if (pathList.includes(PERFORMANCE)) {
    return pages.yourAdDetails;
  }

  if (pathList.includes(ANALYTICS)) {
    return pages.allYourActiveListings;
  }

  if (paymentListingStep === paymentStepsEnum.SelectPaidMediaPackage) {
    return pages.selectPaidMediaPackage;
  }

  if (paymentListingStep === paymentStepsEnum.Pay) {
    return pages.pay;
  }

  if (paymentListingStep === paymentStepsEnum.Confirmation) {
    return pages.confirmationPage;
  }

  if (promoteListingStep === promoteStepsEnum.SelectYourGoal) {
    return pages.selectYourGoal;
  }

  if (promoteListingStep === promoteStepsEnum.ChooseYourMedia) {
    return pages.chooseYourMedia;
  }

  if (promoteListingStep === promoteStepsEnum.ReviewCopy) {
    return pages.reviewCopy;
  }

  if (promoteListingStep === promoteStepsEnum.ChooseListingImages) {
    return pages.chooseListingImages;
  }

  if (promoteListingStep === promoteStepsEnum.ReviewYourAd) {
    return pages.previewYourAd;
  }

  return pages.homePage;
};

const createEmailBody = () => {
  const store = getStore();
  const currentUser = selectCurrentUser(store);

  const issueDescription = selectIssueDescription(store);
  const issueType = selectIssueType(store);
  const pageName = detectPage();

  const requestTime = moment().format("L; hh:mm");

  let email = `<b>1. Agent:</b> ${currentUser?.firstName || ""} ${
    currentUser?.lastName || ""
  }<br/><br/>`;
  email += "<b>2. Body of the request:</b><br/>";
  email += `&nbsp;&nbsp;&nbsp;&nbsp;<b>2.1. What issue(s) are you having?</b> - ${
    issueType !== null ? issueTypesList[issueType] : "Other"
  }<br/><br/>`;
  email += `&nbsp;&nbsp;&nbsp;&nbsp;<b>2.2. Please provide a description of the issue(s) you are experiencing (which can include a hyperlink)</b> - ${issueDescription}<br/><br/>`;
  email += `<b>3. Request Time:</b> ${requestTime}<br/><br/>`;
  email += `<b>4. Page from where the Request was sent:</b> ${pageName}`;

  return email;
};

export const sendSupportEmailAction = () => async (dispatch) => {
  try {
    const store = getStore();

    const issueDescription = selectIssueDescription(store);
    const issueType = selectIssueType(store);
    const attachments = selectIssueAttachments(store);

    if (!issueDescription && issueType !== null) {
      return;
    }

    const emailBody = createEmailBody();

    dispatch(setSendEmailLoadingAction(true));

    const formData = new FormData();

    formData.append("EmailBody", emailBody);
    // eslint-disable-next-line array-callback-return
    attachments.map((item) => {
      formData.append("Attachments", item.file);
    });

    await axios.post(networkActions.SEND_SUPPORT_EMAIL, formData);

    dispatch(setSentStatusAction(true));
    dispatch(setSendEmailLoadingAction(false));
  } catch (error) {
    dispatch(setSendEmailLoadingAction(false));
    notificationsServices.error(error);
  }
};
