const GooglePreview = ({ isActive, children, openPopup }) => {
  const isMobile = window.innerWidth < 758;
  const isClickable = typeof openPopup === "function";
  const popupCondition = isClickable && !isMobile;

  return (
    <div className="google-preview__mobile-container">
      <div
        className={`google-preview ${isActive ? "show" : "hidden"} `}
        onClick={popupCondition ? openPopup : null}
      >
        <div className="google-preview__top-circle" />
        <div className="google-preview__inner-frame">{children}</div>
      </div>
    </div>
  );
};

export default GooglePreview;
