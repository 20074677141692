import { managerWriteCopyList } from "../../constants/constants";
import { managerAdSteps } from "../../constants/enums";
import managerAdActionTypes from "../actions/managerAd/managerAdActionTypes";

const initialState = {
  isOpenStepper: false,
  openStep: managerAdSteps.CHOOSE_MEDIA,
  mediaAdType: "",
  feedFile: undefined,
  storyFile: undefined,
  headline: "",
  headlineCheck: [],
  validationLoading: false,
  primary: "",
  primaryCheck: [],
  destinationLink: "",
  destinationLinkErrorMessage: "",
  actionButton: managerWriteCopyList[0],
  destinationLinkChecked: false,
  regionList: [],
  isCorporate: false,
  selectedRegions: [],
  isNational: false,
  facebookBudgetInfo: [],
  selectedBudget: undefined,
  creditFullName: "",
  zipCode: "",
  creditCardNumber: "",
  creditExpirationDate: "",
  publishResult: {},
  publishLoading: false,
  videoAdInfo: {
    feedVideoId: "",
    feedVideoUrl: "",
    storyVideoId: "",
    storyVideoUrl: "",
  },
  videoAdInfoLoading: false,
};

// eslint-disable-next-line default-param-last
const managerAdReducer = (state = initialState, action) => {
  switch (action.type) {
    case managerAdActionTypes.SET_STEPPER_STATE:
      return {
        ...state,
        isOpenStepper: action.payload,
      };
    case managerAdActionTypes.SET_STEPPER_STEP:
      return {
        ...state,
        openStep: action.payload,
      };
    case managerAdActionTypes.SET_MANAGER_MEDIA_TYPE:
      return {
        ...state,
        mediaAdType: action.payload,
      };
    case managerAdActionTypes.SET_MANAGER_FEED_FILE:
      return {
        ...state,
        feedFile: action.payload,
      };
    case managerAdActionTypes.SET_MANAGER_STORY_FILE:
      return {
        ...state,
        storyFile: action.payload,
      };
    case managerAdActionTypes.SET_MANAGER_HEADLINE_TEXT:
      return {
        ...state,
        headline: action.payload,
        validationLoading: true,
      };
    case managerAdActionTypes.SET_MANAGER_PRIMARY_TEXT:
      return {
        ...state,
        primary: action.payload,
        validationLoading: true,
      };
    case managerAdActionTypes.SET_MANAGER_PRIMARY_CHECK:
      return {
        ...state,
        primaryCheck: action.payload,
        validationLoading: false,
      };
    case managerAdActionTypes.SET_MANAGER_HEADLINE_CHECK:
      return {
        ...state,
        headlineCheck: action.payload,
        validationLoading: false,
      };
    case managerAdActionTypes.SET_MANAGER_WRITE_VALIDATION_LOADING:
      return {
        ...state,
        validationLoading: true,
      };
    case managerAdActionTypes.SET_MANAGER_LINK_TEXT:
      return {
        ...state,
        destinationLink: action.payload,
      };
    case managerAdActionTypes.SET_MANAGER_LINK_ERROR:
      return {
        ...state,
        destinationLinkChecked: true,
        destinationLinkErrorMessage: action.payload,
      };
    case managerAdActionTypes.SET_MANAGER_ACTION_BUTTON:
      return {
        ...state,
        actionButton: action.payload,
      };
    case managerAdActionTypes.SET_REGION_LIST:
      return {
        ...state,
        regionList: action.payload,
      };
    case managerAdActionTypes.SET_IS_CORPORATE:
      return {
        ...state,
        isCorporate: action.payload,
      };
    case managerAdActionTypes.SET_SELECTED_REGIONS:
      return {
        ...state,
        selectedRegions: action.payload,
      };
    case managerAdActionTypes.SET_IS_NATIONAL:
      return {
        ...state,
        isNational: action.payload,
      };
    case managerAdActionTypes.SET_BUDGET_INFO:
      return {
        ...state,
        facebookBudgetInfo: action.payload,
      };

    case managerAdActionTypes.SET_CREDIT_FULL_NAME:
      return {
        ...state,
        creditFullName: action.payload,
      };
    case managerAdActionTypes.SET_CREDIT_ZIP_CODE:
      return {
        ...state,
        zipCode: action.payload,
      };
    case managerAdActionTypes.SET_CREDIT_CREDIT_CARD_NUMBER:
      return {
        ...state,
        creditCardNumber: action.payload,
      };

    case managerAdActionTypes.SET_CREDIT_EXPIRATION:
      return {
        ...state,
        creditExpirationDate: action.payload,
      };
    case managerAdActionTypes.SET_SELECTED_BUDGET:
      return {
        ...state,
        selectedBudget: action.payload,
      };
    case managerAdActionTypes.SET_PUBLISH_LOADING:
      return {
        ...state,
        publishLoading: action.payload,
      };
    case managerAdActionTypes.SET_PUBLISH_RESULT:
      return {
        ...state,
        publishResult: action.payload,
      };
    case managerAdActionTypes.SET_VIDEO_AD_INFO:
      return {
        ...state,
        videoAdInfo: action.payload,
      };
    case managerAdActionTypes.SET_VIDEO_AD_INFO_LOADING:
      return {
        ...state,
        videoAdInfoLoading: action.payload,
      };
    case managerAdActionTypes.SET_TARGETING_OPTIONS:
      return {
        ...state,
        facebookBudgetInfo: action.payload?.facebookBudgetInfo?.budgets || [],
        regionList: action.payload?.regions || [],
        isCorporate: action.payload?.isCorporate || false,
        selectedRegions: [],
      };
    case managerAdActionTypes.SET_MANAGER_AD_EMPTY:
      return initialState;
    default:
      return state;
  }
};

export default managerAdReducer;
