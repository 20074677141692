import {
  adMediaTypesEnum,
  callToActionEnum,
  goalsEnum,
  googleAdPreviewTypeEnum,
  googleAdResolutions,
  paymentStepsEnum,
  paymentTypesEnum,
  promoteStepsEnum,
} from "src/constants";
import { imageResize } from "src/api/operations";
import {
  getMaxPhotoCount,
  getSelectMediaType,
  notificationsServices,
  formatAddressString,
} from "src/helpers";
import adActionTypes from "./adActionTypes";
import axios from "../../../api/index";
import { getStore } from "../../index";
import {
  selectAd,
  selectAdFbHousePhotosSingle,
  selectAdGoogleHousePhotos,
  selectAdHousePhotosCarousel,
  selectAdType,
  selectAdGoal,
  selectAdMediaType,
} from "../../selectors/adSelector";
import {
  adTypes,
  invoiceTypes,
  networkActions,
  platformsEnum,
} from "../../../constants/constants";
import {
  prepareDeviceChartData,
  prepareMapData,
} from "../../../helpers/charts";
// eslint-disable-next-line import/no-cycle
import {
  getFairHousingCheckAction,
  setPaymentListingStepAction,
  setPromoteListingStepAction,
  setStepLoadingAction,
} from "../listing/listingActions";
import { getPromotedAdsAction } from "../insight/insightActions";
import {
  selectCreditBillingZipCode,
  selectCreditCardNumber,
  selectCreditExpirationDate,
  selectCreditFullName,
  selectIsAmpAvailable,
  selectIsCreditCardAvailable,
  selectPaymentType,
} from "../../selectors/paymentSelector";

const facebookPlatformKey = "Facebook_Instagram";

export const saveAdNotificationAction = (message) => (dispatch) => {
  dispatch({
    type: adActionTypes.SAVE_AD_NOTIFICATION,
    payload: message,
  });
};

export const setInvoiceInfoAction = (invoice) => (dispatch) => {
  dispatch({
    type: adActionTypes.SAVE_INVOICE_INFO,
    payload: invoice,
  });
};

export const saveAdLoadingAction = (loading) => (dispatch) => {
  dispatch({
    type: adActionTypes.SAVE_AD_LOADING,
    payload: loading,
  });
};

export const addPaymentInfo = (data) => {
  const requestBody = { ...data };
  const store = getStore();
  const paymentType = selectPaymentType(store);
  const isAmpAvailable = selectIsAmpAvailable(store);
  const isCreditCardAvailable = selectIsCreditCardAvailable(store);
  const isPaymentMethod = isAmpAvailable || isCreditCardAvailable;

  requestBody.paymentType = isPaymentMethod ? paymentType : null;

  if (paymentType === paymentTypesEnum.CreditCard && isPaymentMethod) {
    const fullName = selectCreditFullName(store);
    const cardNumber = selectCreditCardNumber(store);
    const expirationDate = selectCreditExpirationDate(store);
    const billingZipCode = selectCreditBillingZipCode(store);

    requestBody.payWithCreditCard = {
      billingFirstName: fullName.split(" ")[0],
      billingLastName: fullName.split(" ")[1],
      cardNumber: cardNumber.replaceAll(" ", ""),
      expirationDate: expirationDate.replace("/", ""),
      billingZipCode,
    };
  }

  return requestBody;
};

export const publishFBAdAction = () => async (dispatch) => {
  try {
    dispatch(saveAdLoadingAction(true));

    const store = getStore();

    const ad = selectAd(store);
    const adType = selectAdType(store);

    let requestBody = {
      createAd: {},
    };
    const firstPhoto =
      ad.adType === adTypes.SINGLE
        ? selectAdFbHousePhotosSingle(store)[0]
        : selectAdHousePhotosCarousel(store)[0];
    const photos = ad.generatedPhotos.map((item) => item.split("base64,")[1]);

    const callAction =
      ad.goal === goalsEnum.LEAD_GENERATION
        ? callToActionEnum.LearnMore
        : ad.callToAction;

    requestBody.createAd.adSpecs = [
      {
        socialPlatform: adMediaTypesEnum.Facebook,
        headline: ad.facebookHeadline,
        primaryText: ad.facebookPrimaryText,
        callToActionButton: callAction,
      },
    ];

    requestBody.createAd.creativeLogoType = ad.facebookAdLogoType;
    requestBody.createAd.photos = photos;
    requestBody.createAd.firstPhoto = firstPhoto;
    requestBody.createAd.budgetId = ad.budgetId;
    requestBody.createAd.listingId = ad.listingId;
    requestBody.createAd.goal = ad.goal;
    requestBody.createAd.adType = adType;

    requestBody = addPaymentInfo(requestBody);

    const response = await axios.post(
      networkActions.PUBLISH_FB_AD,
      requestBody
    );

    if (response?.data?.isSuccess || response?.data?.isAMPPaymentPending) {
      const type = response?.data?.isAMPPaymentPending
        ? invoiceTypes.PENDING
        : invoiceTypes.APPROVED;

      dispatch(
        setInvoiceInfoAction({
          type,
          info: {
            ...response?.data?.invoiceInfo,
            adId: response?.data?.adId,
            platform: response?.data?.mediaType || facebookPlatformKey,
          },
        })
      );

      dispatch(setPaymentListingStepAction(paymentStepsEnum.Confirmation));
      return;
    }

    notificationsServices.error(response?.data?.additionalMessage);
    dispatch(saveAdLoadingAction(false));
  } catch (error) {
    dispatch(saveAdLoadingAction(false));
    notificationsServices.error(error);
  }
};

export const setCancelAdLoadingAction = (loading) => (dispatch) => {
  dispatch({
    type: adActionTypes.CANCEL_AD_LOADING,
    payload: loading,
  });
};

export const setCancelAdSuccessAction = (success) => (dispatch) => {
  dispatch({
    type: adActionTypes.SUCCESSFULLY_CANCEL_AD,
    payload: success,
  });
};

export const setCancelAdErrorAction = (error) => (dispatch) => {
  dispatch({
    type: adActionTypes.CANCEL_AD_ERROR,
    payload: error,
  });
};

export const cancelAdAction =
  (
    listingId,
    internalAdId,
    platform = platformsEnum.FACEBOOK,
    callback = () => {}
  ) =>
  async (dispatch) => {
    try {
      dispatch(setCancelAdLoadingAction(true));

      const cancelAdResponse = await axios.patch(
        `${networkActions.CANCEL_AD}?internalAdId=${internalAdId}&platform=${platform}`
      );

      dispatch(getPromotedAdsAction(listingId));
      if (cancelAdResponse?.data?.successful) {
        return dispatch(
          setCancelAdSuccessAction(cancelAdResponse.data.successful)
        );
      }

      callback();
      dispatch(setCancelAdLoadingAction(false));
      dispatch(setCancelAdErrorAction(cancelAdResponse?.data?.message));
    } catch (error) {
      dispatch(setCancelAdLoadingAction(false));
      notificationsServices.error(error);
      callback();
    }
  };

export const setAdListingIdAction = (listingId) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_LISTING_ID,
    payload: listingId,
  });
};

export const setAdConfigsByGoalAction = (payload) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_CONFIGS_BY_GOAL,
    payload,
  });
};

export const setAdCallAction = (callAction) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_CALL_ACTION,
    payload: callAction,
  });
};

export const setAdTypeAction = (tab) => (dispatch) => {
  const store = getStore();
  const adMediaType = selectAdMediaType(store);
  const isGoogleFlow = adMediaType === adMediaTypesEnum.GooglePreview;

  dispatch({
    type: isGoogleFlow
      ? adActionTypes.SET_AD_TYPE_GOOGLE
      : adActionTypes.SET_AD_TYPE_FACEBOOK,
    payload: tab,
  });
};

export const setAdMediaTypesAction = (adMediaType) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_MEDIA_TYPES,
    payload: adMediaType,
  });
};

export const setAdGoalAction = (goal) => (dispatch) => {
  if (goal === goalsEnum.LEAD_GENERATION) {
    dispatch(setAdTypeAction(adTypes.CAROUSEL));
    dispatch(setAdCallAction(callToActionEnum.LearnMore));
  } else {
    dispatch(setAdCallAction(callToActionEnum.ShopNow));
    dispatch(setAdTypeAction(adTypes.SINGLE));
  }

  dispatch({
    type: adActionTypes.SET_GOAL,
    payload: goal,
  });
};

export const setAdFacebookHeadlineAction = (text) => async (dispatch) => {
  dispatch({
    type: adActionTypes.SET_FACEBOOK_HEADLINE,
    payload: text,
  });

  const violateWords = await getFairHousingCheckAction(text);

  dispatch({
    type: adActionTypes.SET_FACEBOOK_HEADLINE_CHECK,
    payload: Array.isArray(violateWords) ? violateWords : [],
  });
};

export const setAdFacebookPrimaryTextAction = (text) => async (dispatch) => {
  dispatch({
    type: adActionTypes.SET_FACEBOOK_PRIMARY_TEXT,
    payload: text,
  });

  const violateWords = await getFairHousingCheckAction(text);

  dispatch({
    type: adActionTypes.SET_FACEBOOK_PRIMARY_CHECK,
    payload: Array.isArray(violateWords) ? violateWords : [],
  });
};

// google start

export const setAdGoogleReviewCopyInfo =
  (googleReviewCopy) => async (dispatch) => {
    dispatch({
      type: adActionTypes.SET_GOOGLE_REVIEW_COPY_INFO,
      payload: googleReviewCopy,
    });
  };

export const setAdGoogleHeadlineAction = (headline) => async (dispatch) => {
  dispatch({
    type: adActionTypes.SET_GOOGLE_HEADLINE,
    payload: headline,
  });

  const violateWords = await getFairHousingCheckAction(headline);

  dispatch({
    type: adActionTypes.SET_GOOGLE_HEADLINE_CHECK,
    payload: Array.isArray(violateWords) ? violateWords : [],
  });
};

export const setAdGoogleLongHeadlineTextAction = (text) => async (dispatch) => {
  dispatch({
    type: adActionTypes.SET_GOOGLE_LONG_HEADLINE_TEXT,
    payload: text,
  });

  const violateWords = await getFairHousingCheckAction(text);

  dispatch({
    type: adActionTypes.SET_GOOGLE_LONG_HEADLINE_CHECK,
    payload: Array.isArray(violateWords) ? violateWords : [],
  });
};

export const setAdGoogleDescriptionTextAction = (text) => async (dispatch) => {
  dispatch({
    type: adActionTypes.SET_GOOGLE_DESCRIPTION_TEXT,
    payload: text,
  });

  const violateWords = await getFairHousingCheckAction(text);

  dispatch({
    type: adActionTypes.SET_GOOGLE_DESCRIPTION_CHECK,
    payload: Array.isArray(violateWords) ? violateWords : [],
  });
};

// google end

export const setAdHouseFbPhotosSingleAction = (housePhoto) => (dispatch) =>
  dispatch({
    type: adActionTypes.SET_HOUSE_FB_PHOTOS_SINGLE,
    payload: housePhoto,
  });

export const setAdHouseGooglePhotosAction = (housePhoto) => (dispatch) =>
  dispatch({
    type: adActionTypes.SET_HOUSE_GOOGLE_PHOTOS,
    payload: housePhoto,
  });

export const setAdHousePhotosCarouselAction = (housePhotos) => (dispatch) =>
  dispatch({
    type: adActionTypes.SET_HOUSE_PHOTOS_CAROUSEL,
    payload: housePhotos,
  });

export const setIsComingSoon = (isComingSoon) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_IS_COMING_SOON,
    payload: isComingSoon,
  });
};

export const setHousePhotoAction = (photo) => (dispatch) => {
  const store = getStore();

  const adType = selectAdType(store);
  const adHousePhotosCarousel = selectAdHousePhotosCarousel(store);
  const googlePhotos = selectAdGoogleHousePhotos(store);
  const goal = selectAdGoal(store);
  const adMediaType = selectAdMediaType(store);
  const { isSelectedFacebook } = getSelectMediaType(adMediaType);
  const photosMaxCount = getMaxPhotoCount(goal, isSelectedFacebook);
  const photos = isSelectedFacebook ? adHousePhotosCarousel : googlePhotos;
  const updatePhotoAction = isSelectedFacebook
    ? setAdHousePhotosCarouselAction
    : setAdHouseGooglePhotosAction;

  if (adType === adTypes.SINGLE && isSelectedFacebook) {
    return dispatch(setAdHouseFbPhotosSingleAction([photo]));
  } else {
    if (photos.includes(photo)) {
      return dispatch(updatePhotoAction(photos.filter((p) => photo !== p)));
    }

    if (photos.length === photosMaxCount) {
      photos[photos.length - 1] = photo;
      return dispatch(updatePhotoAction(photos));
    }
    return dispatch(updatePhotoAction([...photos, photo]));
  }
};

export const setConvertedHousePhotosAction = (housePhotos) => (dispatch) =>
  dispatch({
    type: adActionTypes.SET_CONVERTED_HOUSE_PHOTOS,
    payload: housePhotos,
  });

export const setHousePhotosHeader = (housePhoto) => (dispatch) =>
  dispatch({
    type: adActionTypes.SET_GENERATED_PHOTOS,
    payload: housePhoto,
  });

export const applyChooseImagesAction = () => async (dispatch) => {
  dispatch(setStepLoadingAction(true));
  dispatch(setPromoteListingStepAction(promoteStepsEnum.ReviewYourAd));
  dispatch(setConvertedHousePhotosAction([]));
  const store = getStore();

  try {
    const adType = selectAdType(store);
    const platform = selectAdMediaType(store);
    const isGoogleFlow = platform === adMediaTypesEnum.Google;
    if (isGoogleFlow) {
      const adHouseGooglePhotos = selectAdGoogleHousePhotos(store);
      const firstPhoto = adHouseGooglePhotos[0];
      const response = await imageResize({
        imageUrl: firstPhoto,
        resolutions: googleAdResolutions,
      });
      const [squareImageUrl, landscapeImageUrl] = response.data;

      const photos = [
        {
          id: 0,
          data: squareImageUrl,
          type: googleAdPreviewTypeEnum.DISCOVER,
        },
        {
          id: 1,
          data: landscapeImageUrl,
          type: googleAdPreviewTypeEnum.DISPLAY,
        },
        {
          id: 2,
          data: squareImageUrl,
          type: googleAdPreviewTypeEnum.GMAIL,
        },
      ];
      dispatch(setConvertedHousePhotosAction(photos));
    } else {
      const adHouseFbPhotosSingle = selectAdFbHousePhotosSingle(store);
      const adHousePhotosCarousel = selectAdHousePhotosCarousel(store);

      const adHousePhotos =
        adType === adTypes.SINGLE
          ? adHouseFbPhotosSingle
          : adHousePhotosCarousel;

      const requestBody = adHousePhotos.map((item, key) => ({
        id: key,
        data: item,
      }));

      const response = await axios.post(
        networkActions.DOWNLOAD_IMAGES,
        requestBody
      );

      dispatch(setConvertedHousePhotosAction(response.data));
    }

    dispatch(setStepLoadingAction(false));
  } catch (error) {
    dispatch(setStepLoadingAction(false));
    notificationsServices.error(error);
  }
};

export const setGooglePreviewImagesAction = (images) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_GOOGLE_PREVIEW_IMAGES,
    payload: images,
  });
};

export const setAdLogoTypeAction = (logoType) => (dispatch) => {
  const store = getStore();
  const ad = selectAd(store);
  if (ad.adMediaType === adMediaTypesEnum.Facebook) {
    dispatch({
      type: adActionTypes.SET_FACEBOOK_AD_LOGO_TYPE,
      payload: logoType,
    });
  } else {
    dispatch({
      type: adActionTypes.SET_GOOGLE_AD_LOGO_TYPE,
      payload: logoType,
    });
  }
};

export const setAdBudgetAction = (budget) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_BUDGET,
    payload: budget,
  });
};

export const emptyAdBudgetAction = () => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_BUDGET,
    payload: { id: null, price: null },
  });
};

export const emptyAdDataAction = () => (dispatch) => {
  dispatch({
    type: adActionTypes.EMPTY_AD_DATA,
  });
};

export const setAdDetails = (adDetails) => (dispatch) => {
  /* eslint-disable-next-line */
  let conversionReportTotal = null;
  if (Array.isArray(adDetails.conversionReport)) {
    conversionReportTotal = adDetails.conversionReport
      ?.map((x) => x.clicks)
      .reduce((a, b) => a + b, 0);
  }
  dispatch({
    type: adActionTypes.SET_AD_DETAILS,
    payload: {
      ...adDetails,
      conversionReportTotal,
      address: formatAddressString(adDetails.address),
    },
  });
};

export const setAdDetailsEmpty = () => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_DETAILS_EMPTY,
    payload: {},
  });
};

export const setMapChartData = (mapChartData) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_MAP_CHART_DATA,
    payload: mapChartData,
  });
};

export const setDeviceChartData = (deviceChartData) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_DEVICE_CHART_DATA,
    payload: deviceChartData,
  });
};

export const setAdDetailsLoading = (adDetailsLoading) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_DETAILS_LOADING,
    payload: adDetailsLoading,
  });
};

export const setAdDetailsError = () => (dispatch) => {
  dispatch({ type: adActionTypes.SET_AD_DETAILS_ERROR });
};

export const setMapChartDataMax = (maxValue) => (dispatch) => {
  dispatch({ type: adActionTypes.SET_MAP_CHART_DATA_MAX, payload: maxValue });
};

export const loadAdDetails = (adId, userId, platform) => async (dispatch) => {
  try {
    dispatch(setAdDetailsLoading(true));
    dispatch(setAdDetailsEmpty());
    const response = await axios.get(networkActions.GET_INSIGHT_DETAILS, {
      params: { adId, userId, platform },
    });

    if (response.status === 200 && response.data) {
      dispatch(setAdDetails(response.data));

      if (Array.isArray(response.data.dmaReport)) {
        const mapData = prepareMapData(response.data.dmaReport, "impressions");
        const values = mapData.map((x) => x.value);
        dispatch(setMapChartDataMax(Math.max(...values)));
        dispatch(setMapChartData(mapData));
      }

      if (Array.isArray(response.data.devicesReport)) {
        const deviceData = prepareDeviceChartData(response.data.devicesReport);
        dispatch(setDeviceChartData(deviceData));
      }

      dispatch(setAdDetailsLoading(false));
    }
  } catch (error) {
    dispatch(setAdDetailsError());
    notificationsServices.error(error);
  }
};

export const setAdPreviewInfoLoading = (loadingState) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_PREVIEW_INFO_LOADING,
    payload: loadingState,
  });
};

export const setAdPreviewInfoError = (errorState) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_PREVIEW_INFO_ERROR,
    payload: errorState,
  });
};

export const emptyAdPreviewInfo = () => (dispatch) => {
  dispatch({ type: adActionTypes.SET_AD_PREVIEW_INFO, payload: {} });
};

export const getAdPreviewInfo =
  (internalAdId, userId, platform = facebookPlatformKey) =>
  async (dispatch) => {
    try {
      dispatch(setAdPreviewInfoLoading(true));
      const response = await axios.get(networkActions.GET_AD_PREVIEW_INFO, {
        params: { internalAdId, userId, platform },
      });

      if (response.status === 200 && response.data) {
        dispatch({
          type: adActionTypes.SET_AD_PREVIEW_INFO,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch(setAdPreviewInfoLoading(false));
      notificationsServices.error(error);
    }
  };

export const setEmptyGoogleAdData = () => (dispatch) => {
  dispatch({ type: adActionTypes.EMPTY_GOOGLE_AD_DATA });
};
