import { googleAdPreviewTypeEnum } from "src/constants";
import GooglePreview from ".";
import { getFormattedAgentName } from "../../helpers/formatters";
import PreviewImage from "./PreviewImage";

const GmailPreview = (props) => {
  const {
    photos,
    isActive,
    agencyName,
    agencyLogo,
    openPopup,
    adCallValue,
    googleHeadline,
    googleLongHeadline,
    googleDescription,
    isPublishedAd,
    publishedImages,
  } = props;

  const formattedAgentName = getFormattedAgentName(agencyName);
  const photoUrl = photos?.find(
    (photo) => photo?.type === googleAdPreviewTypeEnum.GMAIL
  )?.data;
  return (
    <GooglePreview isActive={isActive} openPopup={openPopup}>
      <div className="gmail-container">
        <div className="gmail__head">
          <div className="gmail__logo-container">
            <img src={agencyLogo} alt="Logo" />
          </div>
          <div className="gmail__head-text-container">
            <p className="gmail__agent-name">{formattedAgentName}</p>
            <p className="gmail__secondary-text">to me</p>
          </div>
        </div>
        {isPublishedAd ? (
          <img
            width={"100%"}
            height={"100%"}
            src={publishedImages?.squareImageUrl}
          />
        ) : (
          <div className="gmail__image-container">
            <PreviewImage photoUrl={photoUrl} {...props} />
          </div>
        )}

        <p className="gmail__headline google-preview__headline">
          {googleHeadline || googleLongHeadline}
        </p>
        <p className="gmail__primary-text google-preview__primary-text">
          {googleDescription}
        </p>

        <button className="gmail__call-to-action">{adCallValue}</button>
      </div>
    </GooglePreview>
  );
};

export default GmailPreview;
