import get from "lodash/get";
import brandActionTypes from "./brandActionTypes";
import axios from "../../../api";
import { networkActions } from "../../../constants/constants";
import { setBrandingCssVars } from "../../../helpers/styles";
import { hasObjectProps } from "../../../helpers/validations";

export const saveBrandAction = (brand) => (dispatch) => {
  dispatch({
    type: brandActionTypes.SAVE_BRAND_DATA,
    payload: brand,
  });
};

export const setErrorBranding = () => (dispatch) => {
  dispatch({
    type: brandActionTypes.SET_ERROR_DATA,
  });
};

export const getBrandAction = () => async (dispatch) => {
  try {
    dispatch({ type: brandActionTypes.GET_BRAND_DATA });

    const brand = await axios.get(networkActions.GET_USER_BRANDING);

    if (!hasObjectProps(brand?.data)) {
      dispatch(setErrorBranding());
      return;
    }

    setBrandingCssVars([
      ...get(brand, "data.branding.colors", []),
      { name: "BrandColor", value: get(brand, "data.color", "#100B28") },
    ]);

    dispatch(saveBrandAction(brand.data));
  } catch (error) {
    dispatch(setErrorBranding());
  }
};
