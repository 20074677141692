import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { creativeLogoTypesEnum, googleAdPreviewTypeEnum } from "src/constants";
import { isValidImage } from "src/helpers";
import { setAdLogoTypeAction } from "src/store/actions/ad/adActions";
import { selectGoogleAdLogoType } from "src/store/selectors/adSelector";
import { selectAgentTeamInfo } from "src/store/selectors/listingSelector";
import { selectCurrentUser } from "src/store/selectors/userSelector";

const GoogleNavigation = ({ activeSocial, setActiveSocialView }) => {
  const currentUser = useSelector(selectCurrentUser);
  const adLogoType = useSelector(selectGoogleAdLogoType);
  const teamInfo = useSelector(selectAgentTeamInfo);
  const dispatch = useDispatch();

  const [showTeamLogo, toggleTeamLogoState] = useState(teamInfo?.teamLogo);

  useEffect(() => {
    if (!teamInfo?.teamLogo) {
      return;
    }

    isValidImage(teamInfo?.teamLogo)
      .then(() => toggleTeamLogoState(true))
      .catch(() => toggleTeamLogoState(false));
  }, [currentUser]);

  const adPreviewTab = [
    {
      key: googleAdPreviewTypeEnum.GMAIL,
      name: "Gmail",
    },
    {
      key: googleAdPreviewTypeEnum.DISPLAY,
      name: "Display",
    },
    {
      key: googleAdPreviewTypeEnum.DISCOVER,
      name: "Discover",
    },
  ];

  return (
    <div className="preview-your-ad-content-column-row-cnt">
      <div className="preview-your-ad-info-text">
        Click on the preview image to enlarge it. Additionally, the platform
        will generate a YouTube video ad, and we’ll include a link to that asset
        in your confirmation email.
      </div>

      <div className="preview-social-tabs-container google">
        <div className="preview-social-tabs-title">Ad Preview</div>
        <div className="preview-social-tabs">
          <div className="analytics-page-tabs-column">
            {adPreviewTab.map(({ key, name }) => (
              <div
                key={key}
                onClick={() => setActiveSocialView(key)}
                className={`analytics-page-tab ${
                  key === activeSocial ? "active" : ""
                }`}
              >
                {name}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="review-copy-radio-buttons-row">
        <span className="review-copy-input-r-title">Choose Ad Header</span>
        <form action="" className="review-copy-radio-form">
          <div className="review-copy-radio-buttons-column">
            <input
              checked={adLogoType === creativeLogoTypesEnum.AgentLogo}
              onChange={() =>
                dispatch(setAdLogoTypeAction(creativeLogoTypesEnum.AgentLogo))
              }
              id="0"
              type="radio"
              name="review-copy-radio"
              className="radio-button"
            />
            <label htmlFor="0" className="review-copy-radio-button-label">
              <div className="steps-custom-radio-button" />
              <p className="review-copy-radio-text">Headshot & Name </p>
            </label>
          </div>
          <div
            className={`review-copy-radio-buttons-column ${
              showTeamLogo ? "" : "disabled"
            }`}
          >
            <input
              checked={adLogoType === creativeLogoTypesEnum.CompanyLogo}
              onChange={() =>
                dispatch(setAdLogoTypeAction(creativeLogoTypesEnum.CompanyLogo))
              }
              id="1"
              type="radio"
              name="review-copy-radio"
              className="radio-button"
            />
            <label htmlFor="1" className="review-copy-radio-button-label">
              <div className="steps-custom-radio-button" />
              <p className="review-copy-radio-text">Logo</p>
            </label>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GoogleNavigation;
