export const prepareMapData = (response, field = "pageViews") => {
    const states = new Set(response.map(x => x.state));
    const result = [];
    /* eslint-disable-next-line */
    for (const state of states) {
        const pageViews = response.filter(x => x.state === state).map(x => x[field]);
        if (pageViews) {
            result.push({ state, value: pageViews.reduce((a, b) => a + b, 0) });
        }
    }

    return result;
};

export const prepareDeviceChartData = response => (
    response.reduce((prev, item) => {
        prev.series.data.push([item.deviceType, item.pageViews]);
        if (item.pageViews) {
            prev.hasDevicesData = true;
        }
        return prev;
    }, { series: { data: [] }, hasDevicesData: false })
);
