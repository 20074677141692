import { toast } from "react-toastify";
import { get } from "lodash";
import { hasObjectProps } from "./validations";

const defaultErrorMessage = "Something went wrong";
const defaultSuccessMessage = "Successfully";

export const getMessageString = (message, defaultMessage) => {
  if (typeof message === "string") {
    return message;
  }
  if (hasObjectProps(message)) {
    const commonError = get(message, "response.data.Message");
    const paymentError = get(message, "message");
    const validationError = get(
      message,
      "response.data.ValidationMessages"
    )?.join(", ");
    return commonError || validationError || paymentError || defaultMessage;
  }
  return defaultMessage;
};

export const notificationsServices = {
  error: (message) => {
    if (message?.response?.status !== 401) {
      const messageString = getMessageString(message, defaultErrorMessage);
      toast.error(messageString, { toastId: messageString });
    }
  },
  success: (message) => {
    const messageString = getMessageString(message, defaultSuccessMessage);
    toast.success(messageString);
  },
  successWithComponent: (MessageComponent) => {
    toast.success(MessageComponent);
  },
  errorWithComponent: (MessageComponent) => {
    toast.error(MessageComponent);
  },
};
