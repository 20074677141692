import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { routesPath } from "../../constants/constants";
import { loginRequest } from "../../services/authConfig";

const SSORedirectionView = () => {
  const history = useHistory();
  const { instance } = useMsal();
  const { ISSUE } = routesPath;

  useEffect(() => {
    instance.loginRedirect(loginRequest).catch(() => {
      history.push(`/${ISSUE}`);
    });
  }, []);

  return <></>;
};

export default SSORedirectionView;
