import React from "react";
import { useSelector } from "react-redux";
import { goalsEnum } from "src/constants";
import { getSelectMediaType } from "src/helpers";
import {
  selectAdMediaType,
  selectAdGoal,
} from "../../../store/selectors/adSelector";
import ReviewCopyForm from "./FBReviewCopyForm";
import { selectStepLoading } from "../../../store/selectors/listingSelector";
import GoogleReviewCopyForm from "./GoogleReviewCopyForm";

const FBReviewCopyForm = () => {
  const adMediaType = useSelector(selectAdMediaType);
  const adGoal = useSelector(selectAdGoal);
  const stepLoading = useSelector(selectStepLoading);
  const { isSelectedFacebook, isSelectedGoogle } =
    getSelectMediaType(adMediaType);
  const callToActionFormVisible = adGoal === goalsEnum.LINK_CLICKS;

  return (
    <div className="review-copy-step step-cnt">
      <div className="container">
        {stepLoading ? (
          <div className="loader" />
        ) : (
          <>
            {isSelectedFacebook ? (
              <ReviewCopyForm
                callToActionFormVisible={callToActionFormVisible}
              />
            ) : null}
            {isSelectedGoogle ? (
              <GoogleReviewCopyForm
                callToActionFormVisible={callToActionFormVisible}
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default FBReviewCopyForm;
