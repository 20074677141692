import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  promoteStepsEnum,
  facebookPrimaryTextMaxLength,
  adMediaTypesEnum,
} from "src/constants";
import { getSelectMediaType } from "src/helpers";
import Footer from "../../layout/Footer";
import { setPromoteListingStepAction } from "../../../store/actions/listing/listingActions";
import { selectPromoteListingStep } from "../../../store/selectors/listingSelector";
import {
  selectAdMediaType,
  selectFacebookHeadline,
  selectFacebookHeadlineCheck,
  selectGoogleHeadline,
  selectGoogleHeadlineCheck,
  selectFacebookPrimaryText,
  selectFacebookPrimaryTextCheck,
  selectFacebookValidationLoading,
  selectGoogleValidationLoading,
  selectGoogleLongHeadline,
  selectGoogleDescription,
  selectGoogleLongHeadlineCheck,
  selectGoogleDescriptionCheck,
} from "../../../store/selectors/adSelector";
import StepsCountColumn from "./StepsCountColumn";
import PromoteStepsHeader from "./PromoteStepsHeader";
import {
  isValidPrimaryText,
  isValidHeadline,
} from "../../../helpers/validations";

const mainWrapperSelector = "#root > div";

const StepsContainer = (props) => {
  const promoteListingStep = useSelector(selectPromoteListingStep);
  const adMediaType = useSelector(selectAdMediaType);
  const facebookHeadline = useSelector(selectFacebookHeadline);
  const facebookPrimaryText = useSelector(selectFacebookPrimaryText);
  const facebookHeadlineCheck = useSelector(selectFacebookHeadlineCheck);
  const facebookPrimaryTextCheck = useSelector(selectFacebookPrimaryTextCheck);
  const facebookValidationLoading = useSelector(
    selectFacebookValidationLoading
  );
  const googleHeadline = useSelector(selectGoogleHeadline);
  const googleHeadlineCheck = useSelector(selectGoogleHeadlineCheck);
  const googleValidationLoading = useSelector(selectGoogleValidationLoading);
  const isFacebookFlow = adMediaType === adMediaTypesEnum.Facebook;
  const isMobile = window.innerWidth < 758;
  const googleLongHeadline = useSelector(selectGoogleLongHeadline);
  const googleDescription = useSelector(selectGoogleDescription);
  const googleLongHeadlineCheck = useSelector(selectGoogleLongHeadlineCheck);
  const googleDescriptionCheck = useSelector(selectGoogleDescriptionCheck);

  const { isSelectedFacebook, isSelectedGoogle } =
    getSelectMediaType(adMediaType);
  const dispatch = useDispatch();

  function previewCopyDataHasErrors() {
    if (promoteListingStep === promoteStepsEnum.ChooseYourMedia) {
      return !adMediaType;
    }
    if (promoteListingStep === promoteStepsEnum.ReviewCopy) {
      if (isSelectedFacebook) {
        const isValidFacebookHeadline =
          !isValidHeadline(facebookHeadline) ||
          facebookHeadlineCheck?.length > 0;
        const isValidFacebookPrimaryText =
          !isValidPrimaryText(
            facebookPrimaryText,
            facebookPrimaryTextMaxLength
          ) || facebookPrimaryTextCheck?.length > 0;

        if (isValidFacebookHeadline || isValidFacebookPrimaryText) {
          return true;
        }
      }

      if (isSelectedGoogle) {
        const isValidGoogleHeadline =
          !isValidHeadline(googleHeadline) || googleHeadlineCheck?.length > 0;
        const isValidGoogleLongHeadline =
          !isValidHeadline(googleLongHeadline) ||
          googleLongHeadlineCheck?.length > 0;
        const isValidGoogleDescription =
          !isValidHeadline(googleDescription) ||
          googleDescriptionCheck?.length > 0;
        if (
          isValidGoogleHeadline ||
          isValidGoogleLongHeadline ||
          isValidGoogleDescription
        ) {
          return true;
        }
      }
    }
    return false;
  }
  const disableNextStep = previewCopyDataHasErrors();

  const checkFacebookValidationLoading =
    promoteListingStep === promoteStepsEnum.SelectYourGoal
      ? false
      : facebookValidationLoading;
  const checkGoogleValidationLoading =
    promoteListingStep === promoteStepsEnum.SelectYourGoal
      ? false
      : googleValidationLoading;

  const isNextArrowDisabled =
    promoteListingStep === promoteStepsEnum.ReviewYourAd ||
    checkFacebookValidationLoading ||
    checkGoogleValidationLoading ||
    disableNextStep;

  const goBack = () => {
    dispatch(setPromoteListingStepAction(promoteListingStep - 1));
  };

  const navigateToNextStep = () => {
    if (isNextArrowDisabled) {
      return;
    }

    dispatch(setPromoteListingStepAction(promoteListingStep + 1));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const mainWrapper = document.querySelector(mainWrapperSelector);
    if (mainWrapper) {
      mainWrapper.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="steps-wrapper">
      <PromoteStepsHeader />
      <div className={`steps-content ${isFacebookFlow ? "" : "google"}`}>
        {props.children}
      </div>
      {promoteListingStep !== promoteStepsEnum.ChooseListingImages ? (
        <div className="steps-bottom">
          {promoteListingStep !== promoteStepsEnum.ReviewYourAd ? (
            <div className="next-step-row">
              <div className="container">
                <div className="next-step-row-inner">
                  <div className="next-step-left-column">
                    <div className="next-step-button-block">
                      <button onClick={goBack} className={"button back-button"}>
                        Go Back
                      </button>
                      <button
                        onClick={navigateToNextStep}
                        className={`button ${
                          isNextArrowDisabled ? "disabled" : ""
                        }`}
                      >
                        next step
                      </button>
                    </div>
                  </div>
                  <StepsCountColumn />
                </div>
              </div>
            </div>
          ) : null}
          {!isFacebookFlow &&
          promoteListingStep === promoteStepsEnum.ReviewYourAd &&
          !isMobile ? null : (
            <Footer />
          )}
        </div>
      ) : null}
    </div>
  );
};

StepsContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default StepsContainer;
