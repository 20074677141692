import React from "react";
import { useSelector } from "react-redux";
import { CLIENT_FINDER_API_KEY, isSSOAuth } from "../../constants/constants";
import SSORedirectionView from "../sso/SSORedirectionView";
import { selectDoesNotUserCase } from "../../store/selectors/permissionSelector";

const PermissionIssue = () => {
  const isDoesNotUserCase = useSelector(selectDoesNotUserCase);
  localStorage.removeItem(CLIENT_FINDER_API_KEY);

  if (isSSOAuth && !isDoesNotUserCase) return <SSORedirectionView />;

  return (
    <div className="permission-issue">
      <div className="permission-issue-content">
        <div className="permission-issue-head">
          <h3 className="permission-issue-title">Permission Issue</h3>
          <p className="permission-issue-text">
            Sorry, it seems that you don't have the necessary permissions to use
            this application.
          </p>
        </div>
        <div className="permission-issue-bottom">
          <p className="permission-issue-b-text">
            Contact{" "}
            <a href="mailto:adpro@purlin.com" target="_blank">
            adpro@purlin.com
            </a>
            <br />
            if you think you reached this page in error.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PermissionIssue;
