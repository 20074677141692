import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import {
  adMediaTypesEnum,
  paymentStepsEnum,
  promoteStepsEnum,
  SocialEnum,
} from "src/constants";
import Footer from "src/components/layout/Footer";

import StepsCountColumn from "../promoteLayout/StepsCountColumn";
import NavigationText from "./NavigationText";
import AdView from "../../shared/AdView";
import AdPopup from "../../shared/AdPopup";
import Spinner from "../../shared/Spinner";
import {
  setPaymentListingStepAction,
  setPromoteListingStepAction,
} from "../../../store/actions/listing/listingActions";
import { setHousePhotosHeader } from "../../../store/actions/ad/adActions";
import {
  selectPromoteListingStep,
  selectStepLoading,
} from "../../../store/selectors/listingSelector";
import { notificationsServices } from "../../../helpers/notifications";
import SliderAds from "../../shared/AdView/SliderAds";
import { facebookPreviewDimensions } from "../../../constants/constants";
import { selectAdMediaType } from "../../../store/selectors/adSelector";
import { getStore } from "../../../store";
import PortalCarouselImages from "./PortalCarouselImages";
import GoogleNavigation from "./GoogleNavigation";

const mainImageOptions = {
  width: facebookPreviewDimensions.width,
  height: facebookPreviewDimensions.height,
  useCORS: true,
  allowTaint: true,
};

const carouselOptions = {
  width: 540,
  height: 540,
  useCORS: true,
  allowTaint: true,
};

const generateImage = async (element, options) => {
  try {
    const canvas = await html2canvas(element, options);

    const imageUrl = canvas.toDataURL("image/png");
    return imageUrl;
  } catch (err) {
    return err;
  }
};

const PreviewYourAdView = () => {
  const [loading, setLoading] = useState(false);
  const [convertedRefs, setConvertedRefs] = useState([]);
  const [activeSocial, setActiveSocialView] = useState(SocialEnum.FACEBOOK);
  const [popupState, setPopupState] = useState("none");
  const mainImageRef = useRef(null);
  const sliderRef = useRef(null);
  const store = getStore();

  const promoteListingStep = useSelector(selectPromoteListingStep);
  const stepLoading = useSelector(selectStepLoading);
  const adMediaType = selectAdMediaType(store);
  const isFacebookFlow = adMediaType === adMediaTypesEnum.Facebook;
  const isMobile = window.innerWidth < 758;

  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(setPromoteListingStepAction(promoteListingStep - 1));
  };

  const generateGooglePreview = async () => {
    setLoading(true);
    dispatch(
      setPaymentListingStepAction(paymentStepsEnum.SelectPaidMediaPackage)
    );
  };

  const generateFacebookPreview = () => {
    const mainImage = mainImageRef?.current;
    const scaleX = 1080 / mainImage.clientWidth;
    const scaleY = 1080 / mainImage.clientHeight;
    mainImage.style.transform = `scale(${scaleX}, ${scaleY})`;
    const getMainImage = generateImage(mainImage, mainImageOptions);

    const getCollapseImages = convertedRefs.map((item) =>
      generateImage(item.element, carouselOptions));

    Promise.all([getMainImage, ...getCollapseImages])
      .then((imageUrls) => {
        setLoading(false);
        dispatch(setHousePhotosHeader(imageUrls));
        dispatch(
          setPaymentListingStepAction(paymentStepsEnum.SelectPaidMediaPackage)
        );
      })
      .catch(() => {
        setLoading(false);
        notificationsServices.error("Network error");
      });
  };

  const handlePurchaseAd = async () => {
    setLoading(true);
    if (adMediaType === adMediaTypesEnum.Google) {
      generateGooglePreview();
    } else {
      generateFacebookPreview();
    }
  };

  return (
    <>
      {stepLoading || loading ? (
        <Spinner />
      ) : (
        <div className="preview-your-ad step-cnt">
          <div className="container">
            <div
              className={`preview-your-ad-content ${
                isFacebookFlow ? "" : "google"
              }`}>
              <SliderAds
                sliderRef={sliderRef}
                activeSocial={activeSocial}
                mainImageRef={mainImageRef}
                openPopup={() => setPopupState("flex")}
              />
              <div className="preview-your-ad-content-column space">
                {isFacebookFlow ? (
                  <NavigationText
                    activeSocial={activeSocial}
                    setActiveSocialView={setActiveSocialView}
                  />
                ) : (
                  <GoogleNavigation
                    activeSocial={activeSocial}
                    setActiveSocialView={setActiveSocialView}
                  />
                )}
                <div className="preview-your-ad-content-column-row">
                  <div className="preview-your-ad-content-column-button">
                    <button className={"button back-button"} onClick={goBack}>
                      Go Back
                    </button>
                    <button className="button" onClick={handlePurchaseAd}>
                      purchase ad
                    </button>
                  </div>
                  <StepsCountColumn />
                </div>

                {!isFacebookFlow &&
                promoteListingStep === promoteStepsEnum.ReviewYourAd &&
                !isMobile ? (
                  <div className="preview-your-ad__google-footer-container">
                    <Footer />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <AdPopup popupState={popupState} setPopupState={setPopupState}>
            <AdView sliderRef={sliderRef} activeSocial={activeSocial} />
          </AdPopup>
          {isFacebookFlow && (
            <PortalCarouselImages setConvertedRefs={setConvertedRefs} />
          )}
        </div>
      )}
      {/* TO DO need to delete after google release */}
      {/* <Portal>
        <GoogleGeneratePreview setGoogleRefs={setGoogleRefs} />
      </Portal> */}
    </>
  );
};

export default PreviewYourAdView;
