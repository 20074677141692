import { useEffect } from "react";

const useOutsideClick = (ref, action, condition = true) => {
    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            if (e.target.classList.contains("header_calendar") ||
                e.target.classList.contains("analytics-page-table-dropdown-text")) return;

            if (typeof action === "function" && condition) action();
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, condition]);
};

export default useOutsideClick;
