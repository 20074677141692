import { promoteAdsFilterObjectInitialState } from "../../constants/constants";
import insightActionTypes from "../actions/insight/insightActionTypes";

const initialState = {
  promotedAds: [],
  total: {},
  promoteAdsLoading: false,
  filteredPromoteAds: [],
  promoteAdsFilterObject: promoteAdsFilterObjectInitialState,
  insightError: null,
  exposure: [],
  exposureTotal: 0,
  exposureLoading: false,
  effectiveness: [],
  effectivenessLoading: false,
  devicesReport: [],
  deviceChartData: { series: { data: [] } },
  devicesLoading: false,
  citiesReport: [],
  citiesChartMax: 0,
  citiesChartData: [],
  citiesChartDataMax: 0,
  citiesLoading: false,
  conversionReport: [],
  conversionReportTotal: 0,
  conversionLoading: false,
  shareMetricLoading: false,
  hasAd: false,
};

// eslint-disable-next-line default-param-last
const insightReducer = (state = initialState, action) => {
  switch (action.type) {
    case insightActionTypes.SET_PROMOTED_AD_INFO_LOADING:
      return {
        ...state,
        promoteAdsLoading: action.payload,
      };
    case insightActionTypes.SAVE_PROMOTED_AD_INFO:
      return {
        ...state,
        promotedAds: action.payload || [],
        promoteAdsLoading: false,
      };
    case insightActionTypes.SAVE_FILTERED_PROMOTED_ADS:
      return {
        ...state,
        filteredPromoteAds: action.payload.filteredPromoteAds,
        total: action.payload.total,
        promoteAdsLoading: false,
      };
    case insightActionTypes.SET_PROMOTED_ADS_FILTER:
      return {
        ...state,
        promoteAdsFilterObject: action.payload,
        promoteAdsLoading: true,
      };
    case insightActionTypes.SET_INSIGHT_ERROR:
      return {
        ...state,
        insightError: action.payload,
        promoteAdsLoading: false,
      };
    case insightActionTypes.SET_INSIGHT_EFFECTIVENESS:
      // eslint-disable-next-line no-case-declarations

      return {
        ...state,
        effectiveness: action.payload?.adEffectiveness,
        hasAd: action.payload?.hasAd,
        effectivenessLoading: false,
      };
    case insightActionTypes.SET_INSIGHT_EXPOSURE:
      // eslint-disable-next-line no-case-declarations
      const exposureTotal = action.payload?.reduce(
        (result, item) => result + item.views,
        0
      );

      return {
        ...state,
        exposure: action.payload,
        exposureLoading: false,
        exposureTotal,
      };

    case insightActionTypes.SET_INSIGHT_CONVERSION:
      // eslint-disable-next-line no-case-declarations
      const conversionTotal = action.payload?.reduce(
        (result, item) => result + item.clicks,
        0
      );
      return {
        ...state,
        conversionReport: action.payload,
        conversionReportTotal: conversionTotal,
        conversionLoading: false,
      };
    case insightActionTypes.SET_INSIGHT_DEVICES:
      return {
        ...state,
        devicesReport: action.payload,
        devicesLoading: false,
      };

    case insightActionTypes.SET_INSIGHT_DEVICES_CHART_DATA:
      return {
        ...state,
        deviceChartData: action.payload,
        devicesLoading: false,
      };

    case insightActionTypes.SET_INSIGHT_CITIES_CHART_DATA:
      return {
        ...state,
        citiesChartData: action.payload,
      };
    case insightActionTypes.SET_INSIGHT_CITIES_CHART_DATA_MAX:
      return {
        ...state,
        citiesChartDataMax: action.payload,
      };
    case insightActionTypes.SET_INSIGHT_CITIES:
      return {
        ...state,
        citiesReport: action.payload,
        citiesLoading: false,
      };
    case insightActionTypes.SET_INSIGHT_CITIES_LOADING:
      return {
        ...state,
        citiesLoading: action.payload,
      };
    case insightActionTypes.SET_INSIGHT_DEVICES_LOADING:
      return {
        ...state,
        devicesLoading: action.payload,
      };
    case insightActionTypes.SET_INSIGHT_CONVERSION_LOADING:
      return {
        ...state,
        conversionLoading: action.payload,
      };
    case insightActionTypes.SET_INSIGHT_EFFECTIVENESS_LOADING:
      return {
        ...state,
        effectivenessLoading: action.payload,
      };
    case insightActionTypes.SET_INSIGHT_EXPOSURE_LOADING:
      return {
        ...state,
        exposureLoading: action.payload,
      };
    case insightActionTypes.SHARE_METRIC_LOADER:
      return {
        ...state,
        shareMetricLoading: action.payload,
      };
    case insightActionTypes.EMPTY_INSIGHT_INFO:
      return initialState;
    default:
      return state;
  }
};

export default insightReducer;
