import authenticationService from "../../services/auth";
import permissionActionTypes from "../actions/permission/permissionActionTypes";
import { getIsManager } from "../../helpers/roles";

const rootUser = authenticationService?.getRootUser();
const isManager = getIsManager(rootUser.role);

const initialState = {
  issueStatus: false,
  isDoesNotUserCase: false,
  isManager,
};

// eslint-disable-next-line default-param-last
const insightReducer = (state = initialState, action) => {
  switch (action.type) {
    case permissionActionTypes.SET_PERMISSION_ISSUE_STATUS:
      return { ...state, issueStatus: action.payload };
    case permissionActionTypes.SET_DOES_NOT_USER_CASE:
      return { ...state, isDoesNotUserCase: action.payload };
    case permissionActionTypes.SET_MANAGER_ROLE:
      return { ...state, isManager: action.payload };
    default:
      return state;
  }
};

export default insightReducer;
