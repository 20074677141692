import React, { useEffect } from "react";
import Dialog from "../Dialog";

const suspendMessageStart = "Are you sure you want to remove ";
const suspendMessageEnd = "'s access from your AdPro?";

const SuspendDelegation = ({ onClose, agent, onSuspend }) => {
  useEffect(() => {
    if (!agent) {
      onClose();
    }
  }, [agent]);

  const disableBubbling = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  if (!agent) return null;

  return (
    <Dialog onMouseDown={disableBubbling} className="suspend-dialog">
      <div className="suspend">
        <div className="suspend-close" onClick={onClose}>
          <i className="icon-close " />
        </div>

        <div className="suspend-body">
          <div>
            {suspendMessageStart} <span>{agent?.email}</span>
            {suspendMessageEnd}
          </div>
        </div>
        <div className="suspend-footer">
          <button className="suspend-cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button className="suspend-submit-button" onClick={onSuspend}>
            Remove
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default SuspendDelegation;
