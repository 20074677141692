import { ROLES } from "../constants/constants";

const managerTitle = "Manager";
const agentTitle = "Agent";
const employeeTitle = "Employee";
const regionalExecutiveTitle = "Regional Executive";
const regionalManagerTitle = "Regional Manager";
const salesManagerTitle = "Sales Manager";
const marketingManagerTitle = "";

export const getIsManager = (role) => {
  switch (role) {
    case ROLES.REGIONALEXECUTIVE:
      return true;
    case ROLES.REGIONALMANAGER:
      return true;
    case ROLES.SALESMANAGER:
      return true;
    case ROLES.MANAGER:
      return true;
    default:
      return false;
  }
};

export const getIsMarketingManager = (isManger, roles) => {
  if (isManger) {
    return roles.includes(ROLES.MARKETINGMANAGER);
  }
  return false;
};

export const getIsMultipleRoleAgent = (roles) => {
  if (roles && roles.length > 1) {
    const isRegionalExecutive = roles.includes(ROLES.REGIONALEXECUTIVE);
    const isRegionalManager = roles.includes(ROLES.REGIONALMANAGER);
    const isSalesManager = roles.includes(ROLES.SALESMANAGER);
    const hasManagerRole =
      isRegionalExecutive || isRegionalManager || isSalesManager;

    const isAgent = roles.includes(ROLES.AGENT);

    return hasManagerRole && isAgent;
  }
  return false;
};

export const getRoleTitle = (rootUser, isManager, currentUser) => {
  const roles = rootUser?.roles;
  const currentRole = currentUser?.role;
  if (!isManager) {
    if (rootUser.id !== currentUser.id) {
      if (currentRole === "Employee") return employeeTitle;
      return agentTitle;
    }
    const isEmployee = roles?.includes(ROLES.EMPLOYEE);
    if (isEmployee) return employeeTitle;
    return agentTitle;
  }
  if (roles && roles.length >= 1) {
    const isRegionalExecutive = roles.includes(ROLES.REGIONALEXECUTIVE);
    if (isRegionalExecutive) return regionalExecutiveTitle;
    const isRegionalManager = roles.includes(ROLES.REGIONALMANAGER);
    if (isRegionalManager) return regionalManagerTitle;
    const isSalesManager = roles.includes(ROLES.SALESMANAGER);
    if (isSalesManager) return salesManagerTitle;
    const isMarketingManager = roles.includes(ROLES.MARKETINGMANAGER);
    if (isMarketingManager) return marketingManagerTitle;
    return managerTitle;
  }
  return managerTitle;
};
