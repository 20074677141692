import noop from "lodash/noop";

import React from "react";
import { Portal } from "react-portal";

const Dialog = ({ children, onMouseDown = noop, className = "" }) => (
  <Portal>
    <div className={`${className} dialog-container`} onMouseDown={onMouseDown}>
      <div className="dialog-content">{children}</div>
    </div>
  </Portal>
);

export default Dialog;
