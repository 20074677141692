import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";

const useSwipeWithScrollTo = ({
  swipeableRef,
  disabled,
  stepsCount = 1,
  corrector = 0
}) => {
  const [swipeIndex, _setSwipeIndex] = useState(0);

  const getPositions = () => {
    const { scrollWidth, scrollLeft, offsetWidth } = swipeableRef.current || {};
    const scrollSize = scrollWidth - offsetWidth;

    return { scrollSize, scrollLeft };
  };

  const setSwipeIndex = (index) => {
    const { scrollSize } = getPositions();
    const scrollPosition =
      (scrollSize * index) / (stepsCount - 1) + corrector * (index / stepsCount);

    _setSwipeIndex(index);
    swipeableRef.current?.scrollTo?.({ left: scrollPosition, behavior: "smooth" });
  };

  const onSwiped = debounce((event) => {
    event.stopPropagation();
    const { scrollLeft, scrollSize } = getPositions();
    const index = Math.round((scrollLeft / scrollSize) * (stepsCount - 1));
    setSwipeIndex(index);
  }, 200);

  const windowResizeListener = useCallback(() => {
    _setSwipeIndex(0);
    swipeableRef.current?.scrollTo?.({ left: 0, behavior: "smooth" });
  });

  const swipeListener = useCallback(() => {
    if (swipeableRef.current?.addEventListener) {
      swipeableRef.current.addEventListener("scroll", onSwiped);
      window.addEventListener("resize", windowResizeListener);
    }
    if (swipeableRef.current?.style && !disabled) {
      swipeableRef.current.style.overflowX = "scroll";
    }
    return () => {
      swipeableRef.current?.removeEventListener?.("scroll", onSwiped);
      window.removeEventListener("resize", windowResizeListener);
    };
  }, [swipeableRef.current]);

  useEffect(swipeListener, [swipeableRef.current]);

  return [swipeIndex, setSwipeIndex];
};

export default useSwipeWithScrollTo;
