import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import autosize from "autosize";
import {
  facebookHeadlineLabel,
  facebookHeadlineMaxLength,
  facebookPrimaryLabel,
  adCallActionsFacebook,
  facebookPrimaryTextMaxLength,
} from "src/constants";
import { getReviewCopyClassName, getReviewCopyInputLabel } from "src/helpers";
import { Tooltip2 } from "@blueprintjs/popover2";
import Facebook from "../../../assets/images/fb.svg";
import Instagram from "../../../assets/images/instagram.svg";

import {
  setAdFacebookHeadlineAction,
  setAdFacebookPrimaryTextAction,
} from "../../../store/actions/ad/adActions";
import {
  isValidPrimaryText,
  isValidHeadline,
} from "../../../helpers/validations";

import {
  selectFacebookHeadline,
  selectFacebookHeadlineCheck,
  selectFacebookPrimaryText,
  selectFacebookPrimaryTextCheck,
  selectIsComingSoon,
} from "../../../store/selectors/adSelector";
import CallToActionForm from "./CallToActionForm";

const ReviewCopyForm = ({ callToActionFormVisible }) => {
  const primaryInputRef = useRef(null);
  const headlineInputRef = useRef(null);
  const [headlineValidation, setHeadlineValidation] = useState(true);
  const [primaryTextValidation, setPrimaryTextValidation] = useState(true);
  const dispatch = useDispatch();

  const listingAdHeadline = useSelector(selectFacebookHeadline);
  const listingAdPrimaryText = useSelector(selectFacebookPrimaryText);
  const facebookHeadlineCheck = useSelector(selectFacebookHeadlineCheck);
  const facebookPrimaryTextCheck = useSelector(selectFacebookPrimaryTextCheck);
  const isComingSoon = useSelector(selectIsComingSoon);

  const headlineValidationMessage = facebookHeadlineCheck.join(", ");
  const primaryTextValidationMessage = facebookPrimaryTextCheck.join(", ");

  const errorHeadlineClassName = getReviewCopyClassName(
    headlineValidation,
    headlineValidationMessage
  );

  const errorPrimaryClassName = getReviewCopyClassName(
    primaryTextValidation,
    primaryTextValidationMessage
  );

  const onHeadlineChange = (e) => {
    const value = e.target.value;
    dispatch(setAdFacebookHeadlineAction(value));
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter" && !e.target.value) {
      e.preventDefault();
    }
  };

  const onPrimaryTextChange = (e) => {
    const value = e.target.value;
    dispatch(setAdFacebookPrimaryTextAction(value));
  };

  useEffect(() => {
    autosize(primaryInputRef.current);
  }, [primaryInputRef]);

  useEffect(() => {
    setPrimaryTextValidation(
      facebookPrimaryTextCheck.length > 0 ||
        isValidPrimaryText(listingAdPrimaryText, facebookPrimaryTextMaxLength)
    );
  }, [facebookPrimaryTextCheck]);

  useEffect(() => {
    setHeadlineValidation(
      facebookHeadlineCheck.length > 0 || isValidHeadline(listingAdHeadline)
    );
  }, [facebookHeadlineCheck]);

  useEffect(() => {
    if (primaryInputRef.current) {
      const end = listingAdPrimaryText?.length || 0;
      primaryInputRef.current.setSelectionRange(end, end);
      primaryInputRef.current.focus();
    } else {
      headlineInputRef.current.focus();
    }
  }, []);

  return (
    <div className="review-copy-inputs-row">
      <div className="review-copy-inputs-column">
        <div className="review-copy-social-row">
          <div className="review-copy-social-row-column">
            <div className="review-copy-s-img-block">
              <img src={Facebook} alt="" className="review-copy-s-img" />
            </div>
            <span className="review-copy-social-row-name">facebook</span>
          </div>
          <div className="review-copy-social-row-column">
            <div className="review-copy-s-plus">
              <i className="icon-plus" />
            </div>
          </div>
          <div className="review-copy-social-row-column">
            <div className="review-copy-s-img-block">
              <img src={Instagram} alt="" className="review-copy-s-img" />
            </div>
            <span className="review-copy-social-row-name">instagram</span>
          </div>
        </div>

        <div className={`review-copy-input-row ${errorPrimaryClassName}`}>
          <span className="review-copy-input-r-title">
            {getReviewCopyInputLabel(
              primaryTextValidationMessage,
              errorPrimaryClassName,
              facebookPrimaryLabel
            )}
          </span>
          <textarea
            ref={primaryInputRef}
            className="custom-editable-text"
            value={listingAdPrimaryText}
            maxLength={facebookPrimaryTextMaxLength}
            onKeyDown={onKeyDown}
            onChange={onPrimaryTextChange}
          />
          <p className="review-copy-input-r-latter-count">
            {`${listingAdPrimaryText.length}/${facebookPrimaryTextMaxLength} (max)`}
          </p>
        </div>
        <div className={`review-copy-input-row ${errorHeadlineClassName}`}>
          <span className="review-copy-input-r-title">
            {getReviewCopyInputLabel(
              headlineValidationMessage,
              errorHeadlineClassName,
              facebookHeadlineLabel
            )}
            {isComingSoon && (
              <Tooltip2
                popoverClassName="headline-tooltip"
                content={
                  <div className="headline-tooltip-content">
                    MLS mandates live showing date in advertising.
                  </div>
                }
                placement="top"
                usePortal={false}>
                <i className="icon-info headline-info" />
              </Tooltip2>
            )}
          </span>
          <input
            ref={headlineInputRef}
            disabled={isComingSoon}
            type="text"
            className="review-copy-input"
            value={listingAdHeadline}
            maxLength={facebookHeadlineMaxLength}
            onChange={onHeadlineChange}
          />
          <p className="review-copy-input-r-latter-count">
            {`${listingAdHeadline.length}/${facebookHeadlineMaxLength} (max)`}
          </p>
        </div>
        {callToActionFormVisible ? (
          <CallToActionForm adCallActions={adCallActionsFacebook} />
        ) : null}
      </div>
    </div>
  );
};

ReviewCopyForm.propTypes = {
  callToActionFormVisible: PropTypes.bool,
};

export default ReviewCopyForm;
