import axios from "../../../api";
import { networkActions } from "../../../constants/constants";
import { getStore } from "../../index";
import { selectFaqContent } from "../../selectors/contentSelector";
import contentActionTypes from "./contentActionTypes";

export const saveFaqContentAction = (Content) => (dispatch) => {
  dispatch({
    type: contentActionTypes.SAVE_FAQ_CONTENT_DATA,
    payload: Content,
  });
};

export const setErrorContent = () => (dispatch) => {
  dispatch({
    type: contentActionTypes.SET_ERROR_DATA,
  });
};

export const getFaqContentAction = () => async (dispatch) => {
  try {
    const store = getStore();
    const { content } = selectFaqContent(store);
    if (content && content.length > 0) {
      return;
    }

    dispatch({ type: contentActionTypes.GET_FAQ_CONTENT_DATA });

    const response = await axios.get(networkActions.GET_FAQ_CONTENT);

    dispatch(saveFaqContentAction(response.data));
  } catch (error) {
    dispatch(setErrorContent());
  }
};
