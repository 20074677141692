import paymentActionTypes from "./paymentActionTypes";
import axios from "../../../api";
import { feePercent, networkActions } from "../../../constants/constants";
import { getStore } from "../../index";
import { selectPayWithCreditCard } from "../../selectors/paymentSelector";
import { selectAdBudgetPrice } from "../../selectors/adSelector";
import { notificationsServices } from "../../../helpers/notifications";

export const setPaymentLoadingAction = (loading) => (dispatch) => {
  dispatch({
    type: paymentActionTypes.SET_PAYMENT_LOADING,
    payload: loading,
  });
};

export const setPaymentErrorAction = (error) => (dispatch) => {
  dispatch({
    type: paymentActionTypes.SET_PAYMENT_ERROR,
    payload: error.message,
  });
};

export const saveMarketingBudgetsAction = (payload) => (dispatch) => {
  dispatch({
    type: paymentActionTypes.SAVE_MARKETING_BUDGETS,
    payload,
  });
};

export const setPaymentTypeAction = (paymentType) => (dispatch) => {
  try {
    dispatch({
      type: paymentActionTypes.SET_PAYMENT_TYPE,
      payload: paymentType,
    });
  } catch (error) {
    dispatch(setPaymentErrorAction(error.message));
  }
};

export const emptyCreditCardInfoAction = () => (dispatch) => {
  dispatch({
    type: paymentActionTypes.EMPTY_CREDIT_CARD_INFO,
  });
};

export const setCreditCardInfoAction = (data) => (dispatch) => {
  try {
    const store = getStore();

    const creditCardInfo = selectPayWithCreditCard(store);

    const payload = {
      ...creditCardInfo,
      ...data,
    };

    dispatch({
      type: paymentActionTypes.SET_CREDIT_CARD_INFO,
      payload,
    });
  } catch (error) {
    dispatch(setPaymentErrorAction(error.message));
  }
};

export const getAmpBalanceAction = () => async (dispatch) => {
  try {
    dispatch(setPaymentLoadingAction(true));

    const response = await axios.get(networkActions.GET_AMP_BALANCE);

    if (response.status === 200) {
      const store = getStore();
      const budgetPrice = selectAdBudgetPrice(store);

      const ampBalance = response?.data?.ampBalance || null;
      const isEnoughAmpBalance =
        ampBalance &&
        ampBalance - (budgetPrice * (1 + feePercent)).toFixed(0) >= 0;

      dispatch({
        type: paymentActionTypes.SAVE_AMP_BALANCE,
        payload: { ampBalance, isEnoughAmpBalance },
      });
    }
  } catch (error) {
    dispatch(setPaymentLoadingAction(false));
    notificationsServices.error(error);
  }
};

export const emptyPaymentDataAction = () => (dispatch) => {
  dispatch({
    type: paymentActionTypes.EMPTY_PAYMENT_DATA,
  });
};
