import get from "lodash/get";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adGoogleMinCount,
  adPhotosMaxCount,
  adPhotosMinCount,
  adTypes,
  goalsEnum,
} from "src/constants";
import { getSelectMediaType } from "src/helpers";
import {
  setAdTypeAction,
  setHousePhotoAction,
} from "src/store/actions/ad/adActions";
import {
  selectAd,
  selectAdFbHousePhotosSingle,
  selectAdGoogleHousePhotos,
  selectAdHousePhotosCarousel,
  selectAdMediaType,
  selectAdType,
} from "src/store/selectors/adSelector";
import { selectListingPhotos } from "src/store/selectors/listingSelector";
import Footer from "../../layout/Footer";
import Notification from "../../shared/Notification";
import ImagesBottomList from "./ImagesBottomList";

const { SINGLE, CAROUSEL } = adTypes;
const title1 =
  "The “single-image ad” option drives approximately 2x better traffic than carousel ads. We strongly recommend using single-image ads for traffic ads.";
const title2 =
  "The “carousel ad” drives approximately 1.5x more leads. We strongly recommend using multiple images/carousel ads for lead generation ads.";

const googleTitle =
  "Please select a minimum of 3 images. We strongly recommend using 5 for optimal results.";

const googleErrorTitle =
  "To proceed, a minimum of 3 images is required for your listing. Please ensure you have selected at least 3 images before proceeding.";

const singleImageAdErrorTitle =
  "To proceed, a minimum of 1 valid image is required for your listing. Please ensure you have at least 1 valid image before proceeding.";

const carouselAdErrorTitle =
  "To proceed, a minimum of 3 valid images is required for your listing. Please ensure you have at least 3 valid images before proceeding.";

const ChooseListingImagesView = () => {
  const dispatch = useDispatch();

  const adMediaType = useSelector(selectAdMediaType);
  const adHouseFbPhotosSingle = useSelector(selectAdFbHousePhotosSingle);
  const adHouseGooglePhotos = useSelector(selectAdGoogleHousePhotos);
  const adHousePhotosCarousel = useSelector(selectAdHousePhotosCarousel);
  const listingPhotos = useSelector(selectListingPhotos);
  const ad = useSelector(selectAd);
  const adType = useSelector(selectAdType);

  const goal = get(ad, "goal");
  const { isSelectedFacebook, isSelectedGoogle } =
    getSelectMediaType(adMediaType);
  const isCarouselTabDisabled = listingPhotos?.length < adPhotosMaxCount;
  const isLeads = goal === goalsEnum.LEAD_GENERATION;

  const isCarouselTabActive = adType === adTypes.CAROUSEL && isSelectedFacebook;
  const houseSinglePhotos = isSelectedFacebook
    ? adHouseFbPhotosSingle
    : adHouseGooglePhotos;
  const adHousePhotos = isCarouselTabActive
    ? adHousePhotosCarousel
    : houseSinglePhotos;

  const getTabsProps = (tabName) => ({
    className: `choose-listing-content-tabs-column-tab
            ${tabName === adType ? "active" : ""}
            ${tabName === CAROUSEL && isCarouselTabDisabled ? "disabled" : ""}
            ${tabName === SINGLE && isLeads ? "hidden" : ""}
        `,
    onClick: () => {
      if (tabName !== adType) {
        dispatch(setAdTypeAction(tabName));
      }
    },
  });

  const { notificationTitle, isWarning } = useMemo(() => {
    if (isSelectedGoogle) {
      const isWarning = listingPhotos.length < adGoogleMinCount;
      if (listingPhotos.length < adGoogleMinCount) {
        return { notificationTitle: googleErrorTitle, isWarning };
      }
      return { notificationTitle: googleTitle, isWarning };
    }
    if (isCarouselTabActive && listingPhotos.length < adPhotosMinCount) {
      return { notificationTitle: carouselAdErrorTitle, isWarning: true };
    }
    if (!isCarouselTabActive && listingPhotos.length < 1) {
      return { notificationTitle: singleImageAdErrorTitle, isWarning: true };
    }
    if (isLeads) {
      return {
        notificationTitle: title2,
        isWarning: isCarouselTabActive && !isLeads,
      };
    }
    return {
      notificationTitle: title1,
      isWarning: isCarouselTabActive && !isLeads,
    };
  }, [isSelectedGoogle, isCarouselTabActive, listingPhotos.length]);

  return (
    <div className="choose-listing-images-view-step step-cnt">
      <div className="choose-listing-content">
        <div className="scroll-holder">
          <div className="scrollable-container">
            <div className="container choose-media-container">
              <div className="choose-listing-content-row">
                <div className="choose-listing-content-tabs-column">
                  {isSelectedFacebook ? (
                    <>
                      <div {...getTabsProps(SINGLE)}>Single-image Ad</div>
                      <div {...getTabsProps(CAROUSEL)}>Carousel Ad</div>
                    </>
                  ) : (
                    <div {...getTabsProps(SINGLE)}>Google Ad</div>
                  )}
                </div>
                <Notification title={notificationTitle} warning={isWarning} />
              </div>
              <div className="choose-listing-content-inner">
                {Array.isArray(listingPhotos) && listingPhotos.length > 0 ? (
                  listingPhotos.map((item) => (
                    <div
                      key={item}
                      onClick={() => dispatch(setHousePhotoAction(item))}
                      className={`choose-listing-card ${
                        adHousePhotos?.includes(item) ? "active" : ""
                      }`}>
                      <span className="choose-listing-card-count">
                        {adHousePhotos.indexOf(item) + 1 || ""}
                      </span>
                      <div className="choose-listing-card-img-cnt">
                        <img
                          src={item}
                          alt="house photo"
                          className="choose-listing-card-img"
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div></div>
                )}
              </div>
              <Footer className="choose-media" />
            </div>
          </div>
        </div>
        <ImagesBottomList />
      </div>
    </div>
  );
};

export default ChooseListingImagesView;
