import { useState, useEffect, useRef } from "react";
import { getComputedStyle } from "../helpers/styles";

let timeOut;
const useSwipe = (
  ref,
  options = {
    freeMode: false,
    slidesPerView: 1,
    isScrollable: false,
    onSlideChanged: () => {},
    childCount: 0,
    disabled: false,
    showFullSlide: false,
  }
) => {
  const [position, _setPosition] = useState({ left: 0, x: 0 });
  const [realIndex, setRealIndex] = useState(options.defaultIndex || 0);
  const [animationStatus, setAnimationStatus] = useState(!realIndex);

  const positionRef = useRef(position);
  const realIndexRef = useRef(realIndex);
  const freeModeRef = useRef(false);
  const slidesPerViewRef = useRef(1);
  const isScrollableRef = useRef(false);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    setSlidesPerView(options.slidesPerView);
  }, [options.slidesPerView]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    setFreeMode(options.freeMode);
  }, [options.freeMode]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    setIsScrollable(options.isScrollable);
  }, [options.isScrollable]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    setRealIndexRef(realIndex);
  }, [realIndex]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    window.addEventListener("resize", handleWindowResize);
    return () => {
      clearTimeout(timeOut);
      // eslint-disable-next-line no-use-before-define
      ref.current?.removeEventListener("touchstart", onMouseDown);
      // eslint-disable-next-line no-use-before-define
      ref.current?.removeEventListener("mousedown", onMouseDown);
      // eslint-disable-next-line no-use-before-define
      window.removeEventListener("resize", handleWindowResize);
      // eslint-disable-next-line no-use-before-define
      document.removeEventListener("touchend", onMouseUp);
      // eslint-disable-next-line no-use-before-define
      document.removeEventListener("mouseup", onMouseUp);
      // eslint-disable-next-line no-use-before-define
      document.removeEventListener("touchcancel", onMouseUp);
    };
  }, []);

  useEffect(() => {
    if (ref && !options.disabled) {
      // eslint-disable-next-line no-use-before-define
      ref.current?.addEventListener("touchstart", onMouseDown);
      // eslint-disable-next-line no-use-before-define
      ref.current?.addEventListener("mousedown", onMouseDown);
    }
  }, [ref]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    const childWidth = getChildParams();

    if (childWidth) {
      // eslint-disable-next-line no-nested-ternary
      let lx =
        // eslint-disable-next-line no-nested-ternary
        realIndex !== 0 ? (realIndex === options.childCount - 1 ? 70 : 35) : 0;
      if (options.showFullSlide) {
        lx = 0;
      }
      // eslint-disable-next-line no-use-before-define
      setScrollPosition(realIndex * childWidth - lx);
    }

    if (typeof options.onSlideChanged === "function") {
      options.onSlideChanged();
    }
  }, [realIndex]);

  const handleWindowResize = () => {
    if (!freeModeRef.current) {
      // eslint-disable-next-line no-use-before-define
      const { index } = getCurrentScrollPositionParams();
      setRealIndex(index);
    }
  };
  const setPosition = (data) => {
    positionRef.current = data;
    _setPosition(data);
  };

  const setSlidesPerView = (data) => {
    if (data) {
      slidesPerViewRef.current = data;
    } else {
      slidesPerViewRef.current = 1;
    }
  };

  const setIsScrollable = (data) => {
    if (data) {
      isScrollableRef.current = data;
    } else {
      isScrollableRef.current = false;
    }
  };

  const setFreeMode = (data) => {
    if (data) {
      freeModeRef.current = data;
    } else {
      freeModeRef.current = false;
    }
  };

  const setRealIndexRef = (data) => {
    if (data) {
      realIndexRef.current = data;
    } else {
      realIndexRef.current = 0;
    }
  };

  const onMouseDown = (event) => {
    if (!ref.current || event.target.tagName === "INPUT") {
      return;
    }

    let scrollLeft;

    if (isScrollableRef.current) {
      scrollLeft = ref.current?.scrollLeft;
    } else {
      scrollLeft = -parseInt(
        ref.current.style?.transform
          ?.replace("translate3d(", "")
          .split("px,")[0],
        10
      );
    }

    const body = document.getElementsByTagName("body")[0];
    body.classList.add("disable-user-select");

    const newPosition = {
      left: scrollLeft,
      x: event.clientX ?? event.touches[0].clientX,
      y: event.clientY ?? event.touches[0].clientY,
      startTime: Date.now(),
    };

    setPosition(newPosition);

    if (isScrollableRef.current) {
      // eslint-disable-next-line no-use-before-define
      document.addEventListener("touchmove", onScroll);
      // eslint-disable-next-line no-use-before-define
      document.addEventListener("mousemove", onScroll);
    } else {
      // eslint-disable-next-line no-use-before-define
      document.addEventListener("touchmove", onMouseMove);
      // eslint-disable-next-line no-use-before-define
      document.addEventListener("mousemove", onMouseMove);
    }
    // eslint-disable-next-line no-use-before-define
    document.addEventListener("touchend", onMouseUp);
    // eslint-disable-next-line no-use-before-define
    document.addEventListener("mouseup", onMouseUp);
    // eslint-disable-next-line no-use-before-define
    document.addEventListener("touchcancel", onMouseUp);
  };

  const onMouseMove = (event) => {
    const clientX = event.clientX ?? event.touches[0].clientX;
    const clientY = event.clientY ?? event.touches[0].clientY;
    const dx = clientX - positionRef.current.x;
    const dy = clientY - positionRef.current.y;

    const body = document.getElementsByTagName("body")[0];
    if (
      Date.now() - positionRef.current.startTime > 200 ||
      Math.abs(dy) / Math.sqrt(dx ** 2 + dy ** 2) < 0.5
    ) {
      if (!positionRef.current.isSwiping) {
        // eslint-disable-next-line no-use-before-define
        window.addEventListener("touchmove", documentTouchMove, {
          passive: false,
        });
        body.classList.add("pointer-events-none");
        body.style.touchAction = "none";
        positionRef.current.isSwiping = true;
      }
      if (ref?.current) {
        ref.current.style.transform = `translate3d(${-(
          positionRef.current.left - dx
        )}px, 0px, 0px)`;
      }
    }
  };

  const onScroll = (event) => {
    const clientX = event.clientX ?? event.touches[0].clientX;
    const dx = clientX - positionRef.current.x;
    // const body = document.getElementsByTagName("body")[0];
    // body.classList.add("pointer-events-none");

    if (ref?.current) {
      // const childWidth = getChildParams();
      // if (Math.abs(positionRef.current.left - dx) > 0.1 * childWidth) {
      ref.current.scrollLeft = positionRef.current.left - dx;
      // disableScroll();
      // }
    }
  };

  const onMouseUp = () => {
    positionRef.current.isSwiping = false;
    // eslint-disable-next-line no-use-before-define
    window.removeEventListener("touchmove", documentTouchMove, {
      passive: false,
    });
    const body = document.getElementsByTagName("body")[0];
    body.style.touchAction = "";
    // enableScroll();
    if (!freeModeRef.current) {
      // eslint-disable-next-line no-use-before-define
      const index = autoScroll();
      setRealIndex(index);
    }

    if (isScrollableRef.current) {
      document.removeEventListener("touchmove", onScroll);
      document.removeEventListener("mousemove", onScroll);
    } else {
      document.removeEventListener("touchmove", onMouseMove);
      document.removeEventListener("mousemove", onMouseMove);
    }

    document.addEventListener("touchend", onMouseUp);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("touchcancel", onMouseUp);
    body.classList.remove("disable-user-select");
    body.classList.remove("pointer-events-none");
  };

  const documentTouchMove = (event) => {
    event.preventDefault();
  };

  const autoScroll = () => {
    if (!ref.current || ref.current.children.length < 1) {
      return;
    }

    // eslint-disable-next-line no-use-before-define
    const { currentScroll, index } = getCurrentScrollPositionParams();

    // eslint-disable-next-line no-use-before-define
    setScrollPosition(currentScroll);

    return index;
  };

  const getChildParams = () => {
    const firstChild = ref.current?.children[1];

    if (!firstChild) {
      return {};
    }

    const marginLeft = getComputedStyle(firstChild, "marginLeft");
    const marginRight = getComputedStyle(firstChild, "marginRight");

    return firstChild.clientWidth + marginLeft + marginRight;
  };

  const setScrollPosition = (currentScroll) => {
    if (ref.current) {
      if (animationStatus) {
        ref.current.style.transitionDuration = "300ms";
      }

      if (isScrollableRef.current) {
        ref.current.scrollTo({ left: currentScroll, behavior: "smooth" });
      } else {
        ref.current.style.transform = `translate3d(${-currentScroll}px, 0px, 0px)`;
      }

      if (!animationStatus) {
        setAnimationStatus(true);
      }

      timeOut = setTimeout(() => {
        if (ref.current) {
          ref.current.style.transitionDuration = "0ms";
        }
      }, 300);
    }
  };

  const getCurrentScrollPositionParams = () => {
    let scrollLeft;
    if (isScrollableRef.current) {
      scrollLeft = positionRef.current.left;
    } else {
      scrollLeft = -parseInt(
        ref.current.style?.transform
          ?.replace("translate3d(", "")
          .split("px,")[0],
        10
      );
    }

    const childWidth = getChildParams();

    // const distance = positionRef.current.left - scrollLeft;
    // const time = Date.now() - positionRef.current.startTime;
    // const speed = distance / time;

    let currentScroll = 0;
    let index;

    index =
      Math.round(scrollLeft / (childWidth * slidesPerViewRef.current)) *
      slidesPerViewRef.current;

    // eslint-disable-next-line no-nested-ternary
    let lx =
      // eslint-disable-next-line no-nested-ternary
      realIndexRef.current !== 0
        ? realIndexRef.current === options.childCount - 1
          ? 80
          : 40
        : 0;
    if (options.showFullSlide) {
      lx = 0;
    }
    if (index + slidesPerViewRef.current >= ref.current.children.length) {
      index = ref.current.children.length - slidesPerViewRef.current;

      currentScroll = index * childWidth - lx;
    } else if (scrollLeft <= 0) {
      currentScroll = 0;
      index = 0;
    } else {
      currentScroll =
        Math.round(index / slidesPerViewRef.current) *
          slidesPerViewRef.current *
          childWidth -
        lx;
    }

    return { index, currentScroll };
  };

  return { realIndex, setRealIndex };
};

export default useSwipe;
