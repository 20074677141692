import { CHART_CSS_VAR_PREFIX } from "../constants/constants";

export const getComputedStyle = (ref, type) =>
  parseInt(window.getComputedStyle(ref)[type].replace("px", ""), 10);

export const hexToRgbA = (hex) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");

    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;
    // eslint-disable-next-line no-bitwise
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
  }
};

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : "0, 0, 0";
};

export const setBrandingCssVars = (brandingColors) => {
  const headTag = document.getElementsByTagName("head")[0];
  const styleTag = document.createElement("style");

  const generateCssVariable = ({ name, value }) => {
    const cssName = String(name)
      .replace(/([A-Z])/g, "-$1")
      .toLowerCase();
    const cssValue = cssName.includes(CHART_CSS_VAR_PREFIX)
      ? value
      : hexToRgb(value);

    return `-${cssName}: ${cssValue};`;
  };

  const brandCssVars = brandingColors.reduce((prev, { name, value }) => {
    if (name && value) {
      prev += generateCssVariable({ name, value });
    }
    return prev;
  }, "");

  styleTag.innerHTML = `:root {${brandCssVars}}`;
  headTag.appendChild(styleTag);
};
