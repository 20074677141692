import React, { lazy, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { getBrandAction } from "../../store/actions/brand/brandActions";
import {
  selectBrand,
  selectBrandLoadingState,
  selectIsErrorBrand,
} from "../../store/selectors/brandSelector";
import { hasObjectProps } from "../../helpers/validations";
import {
  selectPromoteListingStep,
  selectPaymentListingStep,
} from "../../store/selectors/listingSelector";
import selectPermissionIssueStatus from "../../store/selectors/permissionSelector";
import { routesPath } from "../../constants/constants";

import Spinner from "../shared/Spinner";
import Header from "./Header";
import PromoteSteps from "../promoteListing/promoteLayout/PromoteSteps";
import PaymentSteps from "../payment/PaymentSteps";
import auth from "../../services/auth";
import LicensingDiscrepancyPopup from "../popups/licensingDiscrepancyPopup";
import { getDelegateUser } from "../../store/actions/user/userAction";
import { selectManagerAdStepperState } from "../../store/selectors/managerAd";

const ManagerAdFlow = lazy(() => import("../ManagerAdFlow"));

const { ISSUE, MAINTENANCE } = routesPath;

const ComponentWrapper = (ComposedComponent) => {
  const Component = () => {
    const history = useHistory();

    const isAuthenticated = useIsAuthenticated();

    const brand = useSelector(selectBrand);
    const isErrorBrand = useSelector(selectIsErrorBrand);
    const loading = useSelector(selectBrandLoadingState);
    const paymentStep = useSelector(selectPaymentListingStep);
    const isOpenStepper = useSelector(selectManagerAdStepperState);
    const promoteStep = useSelector(selectPromoteListingStep);
    const permissionIssueStatus = useSelector(selectPermissionIssueStatus);

    const dispatch = useDispatch();

    const company = auth?.getRootUserCompany();

    const setDelegationUser = () => {
      const delegationUserId = auth?.getDelegationAgent();
      if (delegationUserId) {
        dispatch(getDelegateUser(delegationUserId));
      }
    };

    const initialization = () => {
      dispatch(getBrandAction());
      setDelegationUser();
    };

    useEffect(() => {
      if (hasObjectProps(brand)) {
        document.title = brand.alias || "Client Finder";

        return;
      }
      if (isAuthenticated && !company) {
        return;
      }

      initialization();
    }, [brand]);

    useEffect(() => {
      if (permissionIssueStatus) {
        history.push(`/${ISSUE}`);
      }
      if (isErrorBrand) {
        history.push(`/${MAINTENANCE}`);
      }
    }, [permissionIssueStatus, isErrorBrand]);

    const renderComponent = useMemo(() => {
      if (paymentStep) {
        return <PaymentSteps step={paymentStep} />;
      }

      if (promoteStep) {
        return <PromoteSteps step={promoteStep} />;
      }
      if (isOpenStepper) {
        return <ManagerAdFlow />;
      }
      return <ComposedComponent />;
    }, [paymentStep, promoteStep, isOpenStepper]);

    return loading ? (
      <Spinner />
    ) : (
      <div id="wrapper" className="client-finder">
        <Header />
        <LicensingDiscrepancyPopup />
        <div
          className={`client-finder-content ${
            isOpenStepper ? "manager-ad" : ""
          }`}>
          {renderComponent}
        </div>
      </div>
    );
  };

  return Component;
};

ComponentWrapper.propTypes = {
  ComposedComponent: PropTypes.node.isRequired,
};

export default ComponentWrapper;
