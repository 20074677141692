const insightActionTypes = {
    SAVE_PROMOTED_AD_INFO: "save_promoted_ad_info",
    SET_PROMOTED_AD_INFO_LOADING: "set_promoted_ad_info_loading",
    SET_PROMOTED_ADS_FILTER: "set_promoted_ads_filter",
    SAVE_FILTERED_PROMOTED_ADS: "save_filtered_promoted_ads",
    SET_INSIGHT_ERROR: "set_insight_error",
    EMPTY_INSIGHT_INFO: "empty_insight_info",
    SET_INSIGHT_EFFECTIVENESS: "set_insight_effectiveness",
    SET_INSIGHT_EFFECTIVENESS_LOADING: "set_insight_effectiveness_loading",
    SET_INSIGHT_EXPOSURE: "set_insight_exposure",
    SET_INSIGHT_EXPOSURE_LOADING: "set_insight_exposure_loading",
    SET_INSIGHT_DEVICES: "set_insight_devices",
    SET_INSIGHT_DEVICES_LOADING: "set_insight_devices_loading",
    SET_INSIGHT_CITIES: "set_insight_cities",
    SET_INSIGHT_CITIES_LOADING: "set_insight_cities_loading",
    SET_INSIGHT_CONVERSION: "set_insight_conversion",
    SET_INSIGHT_CONVERSION_LOADING: "set_insight_conversion_loading",
    SET_INSIGHT_DEVICES_CHART_DATA: "set_insight_devices_chart_data",
    SET_INSIGHT_CITIES_CHART_DATA: "set_insight_cities_chart_data",
    SET_INSIGHT_CITIES_CHART_DATA_MAX: "set_insight_cities_chart_data_max",
    SHARE_METRIC_LOADER: "share_metric_loader",
};

export default insightActionTypes;
