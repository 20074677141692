import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getFaqContentAction } from "../../../store/actions/content/contentActions";
import { selectFaqContent } from "../../../store/selectors/contentSelector";
import { popupStatusesEnum } from "../../../constants/enums";
import QuestionAnswerRow from "./questionAnswerRow";

const skeletonArray = [
  { id: 0, w: 25 },
  { id: 1, w: 20 },
  { id: 2, w: 15 },
  { id: 3, w: 25 },
  { id: 4, w: 20 },
];

const FaqContainer = ({ setPopupStatus, isPopupClosed }) => {
  const [activeList, setActiveList] = useState(null);
  const { content: faqList, loading } = useSelector(selectFaqContent);
  const dispatch = useDispatch();

  const handleFAQListToggle = (toggledElement) => {
    if (loading) {
      return;
    }

    if (toggledElement === activeList) {
      setActiveList(null);
    } else {
      setActiveList(toggledElement);
    }
  };

  useEffect(() => {
    dispatch(getFaqContentAction());
  }, []);

  useEffect(() => {
    if (isPopupClosed) setActiveList(null);
  }, [isPopupClosed]);

  return (
    <div className="request-popup-section-holder faq-container">
      <div className="request-popup-section">
        {loading ? (
          <div className="request-popup-description">
            <div className="cf-skeleton-d" />
            <div className="cf-skeleton-d" />
            <div className="cf-skeleton-d" />
          </div>
        ) : (
          <p className="request-popup-description">
            We are sorry you are experiencing an issue with AdPro. Check out the
            list of frequently asked questions, you may find the answer to your
            question among them.
          </p>
        )}

        <ul className="accordion-list">
          {(loading ? skeletonArray : faqList).map((faqItem) => (
            <QuestionAnswerRow
              key={faqItem.id}
              faqItem={faqItem}
              activeId={activeList}
              handleFAQListToggle={handleFAQListToggle}
            />
          ))}
        </ul>
      </div>
      <div className="request-popup-bottom">
        {loading ? (
          <div className="request-popup-description">
            <div className="cf-skeleton-d" />
            <div className="cf-skeleton-d" />
            <div className="cf-skeleton-d-half" />
          </div>
        ) : (
          <div className="request-popup-description faq-description cf-skeleton-p">
            <p>Can't find the answer you are looking for?</p>
            <p>
              You can report your problem to us by creating Support Request and
              we will get back to you within 24 hours.
            </p>
          </div>
        )}
        <button
          onClick={() => setPopupStatus(popupStatusesEnum.SUPPORT)}
          className="request-popup-button cf-skeleton-b"
        >
          Support Request
        </button>
      </div>
    </div>
  );
};

FaqContainer.propTypes = {
  setPopupStatus: PropTypes.func.isRequired,
  isPopupClosed: PropTypes.bool.isRequired,
};

export default FaqContainer;
