import React, { useRef } from "react";
import { Portal } from "react-portal";
import useOutsideClick from "../../hooks/useOutsideClick";

const RightSidebarModal = ({ isOpen, children, onClose }) => {
  const modalRef = useRef(null);

  useOutsideClick(modalRef, onClose, isOpen);

  return (
    <Portal>
      <div className={`right-side-modal ${isOpen ? "open" : ""}`}>
        <div className="modal-content" ref={modalRef}>
          {isOpen ? children : null}
        </div>
      </div>
    </Portal>
  );
};

export default RightSidebarModal;
