import React from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Portal } from "react-portal";
import { selectListingInfo } from "../../store/selectors/listingSelector";
import { emptyListingInfoAction } from "../../store/actions/listing/listingActions";

const LicensingDiscrepancyPopup = () => {
    const listingInfo = useSelector(selectListingInfo);
    const dispatch = useDispatch();

    const isAvailableForAd = get(listingInfo, "isAvailableForAd", true);
    const errorMessage = get(listingInfo, "errorMessage", "");
    const errorTitle = get(listingInfo, "errorTitle", "").replace("\\n", "\n");

    const handleCloseRequestForm = () => {
        dispatch(emptyListingInfoAction());
    };

    return (
        <Portal>
            <div className={`licensing-discrepancy-popup ${isAvailableForAd ? "" : "open"}`}>
                <div className="licensing-discrepancy-popup-container">
                    <button
                        onClick={handleCloseRequestForm}
                        className="popup-close"
                    >
                        <i className="icon-close" />
                    </button>
                    <div className="licensing-discrepancy-popup-section">
                        <h1 className="licensing-discrepancy-popup-section-title">
                            {errorTitle}
                        </h1>
                        <p className="licensing-discrepancy-popup-section-info">
                            {errorMessage}
                        </p>
                    </div>
                    <button
                        onClick={handleCloseRequestForm}
                        className="licensing-discrepancy-popup-footer"
                    >
                        close
                    </button>
                </div>
            </div>
        </Portal>
    );
};

export default LicensingDiscrepancyPopup;
