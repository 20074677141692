import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Spinner from "../shared/Spinner";

import { routesPath } from "../../constants/constants";

const ListingView = lazy(() => import("../listing/ListingView"));
const RmManager = lazy(() => import("../rmManager/rmManager"));
const MMAnalyticsPage = lazy(() => import("../MMAnalyticsPage"));
const MMAdDetailsPage = lazy(() => import("../MMAdDetailsPage"));

const AnalyticsListingView = lazy(() => import("../analytics/analyticsListingView"));
const AdDetailsView = lazy(() => import("../analytics/adDetails/AdDetailsView"));

const AnalyticsWrapper = lazy(() => import("../analytics/AnalyticWrapper"));

const { ANALYTICS, PERFORMANCE, MANAGER_AD_ANALYTICS } = routesPath;

export const ManagerRouter = ({ redirectionUrl }) => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <Route
      path={`/:brandName/${MANAGER_AD_ANALYTICS}/:adId`}
      component={MMAdDetailsPage}
    />
      <Route
        path={`/:brandName/${MANAGER_AD_ANALYTICS}`}
        component={MMAnalyticsPage}
      />
      <Route
        path={`/:brandName/${ANALYTICS}/manager/:userId`}
        component={AnalyticsListingView}
      />
      <Route
        path={`/:brandName/${ANALYTICS}/:listingId/:adId/${PERFORMANCE}/:userId`}
        component={AdDetailsView}
      />
      <Route
        path={`/:brandName/${ANALYTICS}/:listingId/:userId/:tab`}
        component={AnalyticsWrapper}
      />
      <Route
        path={`/:brandName/${ANALYTICS}`}
        component={AnalyticsListingView}
      />
      <Route path="/:brandName" component={RmManager} />
      <Route path="/" component={RmManager}>
        <Redirect to={`/${redirectionUrl}`} />
      </Route>
    </Switch>
  </Suspense>
);

export const AgentRouter = ({ redirectionUrl }) => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <Route
        path={`/:brandName/${ANALYTICS}/:listingId/:adId/${PERFORMANCE}`}
        component={AdDetailsView}
      />
      <Route
        path={`/:brandName/${ANALYTICS}/:listingId`}
        component={AnalyticsWrapper}
      />
      <Route
        path={`/:brandName/${ANALYTICS}/`}
        component={AnalyticsListingView}
      />
      <Route path="/:brandName" component={ListingView} />
      <Route path="/" component={ListingView}>
        <Redirect to={`/${redirectionUrl}`} />
      </Route>
    </Switch>
  </Suspense>
);
