const managerAdActionTypes = {
  SET_STEPPER_STATE: "SET_STEPPER_STATE",
  SET_STEPPER_STEP: "SET_STEPPER_STEP",
  SET_MANAGER_MEDIA_TYPE: "SET_MANAGER_MEDIA_TYPE",
  SET_MANAGER_FEED_FILE: "SET_MANAGER_FEED_FILE",
  SET_MANAGER_STORY_FILE: "SET_MANAGER_STORY_FILE",
  SET_MANAGER_HEADLINE_TEXT: "SET_MANAGER_HEADLINE_TEXT",
  SET_MANAGER_HEADLINE_CHECK: "SET_MANAGER_HEADLINE_CHECK",
  SET_MANAGER_PRIMARY_TEXT: "SET_MANAGER_PRIMARY_TEXT",
  SET_MANAGER_AD_EMPTY: "SET_MANAGER_AD_EMPTY",
  SET_MANAGER_PRIMARY_CHECK: "SET_MANAGER_PRIMARY_CHECK",
  SET_MANAGER_LINK_TEXT: "SET_MANAGER_LINK_TEXT",
  SET_MANAGER_ACTION_BUTTON: "SET_MANAGER_ACTION_BUTTON",
  SET_MANAGER_LINK_ERROR: "SET_MANAGER_LINK_ERROR",
  SET_MANAGER_WRITE_VALIDATION_LOADING: "SET_MANAGER_WRITE_VALIDATION_LOADING",
  SET_REGION_LIST: "SET_REGION_LIST",
  SET_IS_CORPORATE: "SET_IS_CORPORATE",
  SET_IS_NATIONAL: "SET_IS_NATIONAL",
  SET_SELECTED_REGIONS: "SET_SELECTED_REGIONS",
  SET_BUDGET_INFO: "SET_BUDGET_INFO",
  SET_TARGETING_OPTIONS: "SET_TARGETING_OPTIONS",
  SET_SELECTED_BUDGET: "SET_SELECTED_BUDGET",
  SET_CREDIT_FULL_NAME: "SET_CREDIT_FULL_NAME",
  SET_CREDIT_ZIP_CODE: "SET_CREDIT_ZIP_CODE",
  SET_CREDIT_CREDIT_CARD_NUMBER: "SET_CREDIT_CREDIT_CARD_NUMBER",
  SET_CREDIT_EXPIRATION: "SET_CREDIT_EXPIRATION",
  SET_PUBLISH_RESULT: "SET_PUBLISH_RESULT",
  SET_PUBLISH_LOADING: "SET_PUBLISH_LOADING",
  SET_VIDEO_AD_INFO: "SET_VIDEO_AD_INFO",
  SET_VIDEO_AD_INFO_LOADING: "SET_VIDEO_AD_INFO_LOADING",
};

export default managerAdActionTypes;
