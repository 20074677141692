import axios from "../index";
import { networkActions } from "../../constants/constants";

export const sendInvoicePdf = (blob, fileName, adId, platform) => {
  const formData = new FormData();
  formData.append("File", blob, fileName); // data.file

  return axios.post(networkActions.OPERATION_SAVE_INVOICE, formData, {
    params: { adId, platform },
  });
};

export const imageResize = (data) =>
  axios.post(networkActions.IMAGE_RESIZE, data);

export default sendInvoicePdf;
