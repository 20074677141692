export const selectCurrentUser = (state) => {
  if (state.user.delegateUser) {
    return state.user.delegateUser;
  }
  return state.user.personalData;
};

export const selectDelegateUser = (state) => state.user.delegateUser;

export const selectIsDelegateActive = (state) =>
  state.user.delegateUser && state.user.delegationUserId;

export const selectPersonalData = (state) => state.user.personalData;

export const selectDelegateUserLoading = (state) =>
  state.user.delegateUserLoading;
