const listingActionTypes = {
    GET_AGENT_ALL_LISTINGS_LOADING: "get_agent_all_listings_loading",
    SAVE_AGENT_ALL_LISTINGS: "save_agent_all_listings",
    EMPTY_AGENT_ALL_LISTINGS: "empty_agent_all_listings",
    SAVE_AGENT_ALL_LISTINGS_FILTER: "save_agent_all_listings_filter",
    SAVE_AGENT_ALL_LISTING_PAGE_NUMBER: "save_agent_all_listing_page_number",
    SET_IS_AGENT_ALL_LISTING_LOADED: "set_is_agent_all_listing_loaded",
    SET_ANALYTICS_LOADED: "set_analytics_loaded",
    SAVE_AGENT_ALL_LISTINGS_ERROR: "save_agent_all_listings_error",
    SAVE_LISTING_IMAGES: "set_listing_images",
    SET_LISTING_IMAGES_LOADING: "set_listing_images_loading",
    SAVE_LISTING_INFO: "save_listing_info",
    SAVE_LISTING_FB_INFO: "save_listing_fb_info",
    SAVE_GOOGLE_AD_CONFIG: "save_google_ad_config",
    EMPTY_LISTING_INFO: "empty_listing_info",
    SET_PROMOTE_LISTING_STEP: "set_promote_listing_step",
    SET_PAYMENT_LISTING_STEP: "set_payment_listing_step",
    SET_STEP_LOADING: "set_step_loading",
    SET_ANALYTICS_DASHBOARD_TAB: "set_analytics_dashboard_tab",
    SAVE_AGENT_LIST_OF_LISTINGS: "save_agent_listof_listings",
    SAVE_AGENT_LIST_OF_LISTINGS_FILTER: "save_agent_listof_listings_filter",
    EMPTY_AGENT_LIST_OF_LISTINGS: "empty_agent_listof_listings",
    SAVE_LISTINGS_ADDRESS_LOADING: "save_listings_address_loading",
    SAVE_LISTINGS_ADDRESS_ERROR: "save_listings_address_error",
    SAVE_LISTINGS_ADDRESS: "save_listings_address",
    SAVE_LISTINGS_FAIR_HOUSING: "save_listings_fairHousing_check",
    SET_UNDER_MAINTENANCE_LISTING: "set_under_maintenance_listing",
};

export default listingActionTypes;
