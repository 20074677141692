import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, matchPath, useLocation } from "react-router-dom";
import HeaderAvatar from "../shared/HeaderAvatar";
import auth from "../../services/auth";
import { emptyAdAction } from "../../store/actions/listing/listingActions";
import { selectPromoteListingStep } from "../../store/selectors/listingSelector";
import {
  selectBrandColor,
  selectBrandLogoUrl,
  selectBrandFullLogoUrl,
} from "../../store/selectors/brandSelector";
import {
  getIsMarketingManager,
  getIsMultipleRoleAgent,
  getRoleTitle,
} from "../../helpers/roles";
import RightSidebarModal from "../../UI/RightSidebarModal/RightSidebarModal";
import { selectIsManager } from "../../store/selectors/permissionSelector";
import DelegateUsers from "./DelegateUsers";
import SharedUsers from "./SharedUsers";
import { userLayoutScreenEnum } from "../../constants/enums";
import DelegateUserFirstScreen from "./DelegateUserFirstScreen";
import DelegateAccessPopup from "../popups/delegateAccessPopup";
import {
  selectCurrentUser,
  selectIsDelegateActive,
} from "../../store/selectors/userSelector";
import { getAssistantsAction } from "../../store/actions/delegation/delegationAction";
import { discardDelegation } from "../../store/actions/user/userAction";
import { selectManagerAdStepperState } from "../../store/selectors/managerAd";

const Header = React.memo(({ withFullLogo = false }) => {
  const rootUser = auth?.getRootUser();

  const history = useHistory();
  const location = useLocation();
  const isDelegateActive = useSelector(selectIsDelegateActive);
  const currentUser = useSelector(selectCurrentUser);
  const promoteStep = useSelector(selectPromoteListingStep);
  const fullLogoUrl = useSelector(selectBrandFullLogoUrl);
  const brandLogoUrl = useSelector(selectBrandLogoUrl);
  const brandColor = useSelector(selectBrandColor);
  const isManager = useSelector(selectIsManager);
  const isOpenStepper = useSelector(selectManagerAdStepperState);
  const isMultipleRoleAgent = getIsMultipleRoleAgent(rootUser.roles);
  const isMarketingManager = getIsMarketingManager(isManager, rootUser.roles);
  const roleTitle = getRoleTitle(rootUser, isManager, currentUser);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelegateForm, setOpenDelegateForm] = useState(false);
  const [activeScreen, setActiveScreen] = useState(
    userLayoutScreenEnum.FirstScreen
  );

  const agentName = `${currentUser?.firstName || ""} ${
    currentUser?.lastName || ""
  }`;
  const isCurrentRouteMainPage = useMemo(
    () =>
      matchPath(location.pathname, {
        path: "/:brandName",
        exact: true,
        strict: true,
      }),
    [location, location.pathname]
  );
  const isShowDelegateBack = isDelegateActive && !withFullLogo;
  const isClickableAvatar = isCurrentRouteMainPage && !isOpenStepper;
  const isShowFlyoutDots = !isShowDelegateBack && isClickableAvatar;

  const navigateToHomePage = () => {
    dispatch(emptyAdAction());

    history.push(`/${rootUser?.company}`);
  };

  const renderLogo = () => {
    if (withFullLogo && fullLogoUrl) {
      return (
        <img
          src={fullLogoUrl}
          onClick={navigateToHomePage}
          alt="Logo"
          className="logo-img"
        />
      );
    }
    if (brandLogoUrl) {
      return (
        <img
          src={brandLogoUrl}
          onClick={navigateToHomePage}
          alt="Logo"
          className="logo-img"
        />
      );
    }
    return null;
  };

  const handleOpenRequestForm = () => {
    if (isShowFlyoutDots && !isOpenStepper) {
      setIsOpen(true);
      setActiveScreen(userLayoutScreenEnum.FirstScreen);
      document.body.classList.add("overflow-hidden");
    }
  };

  const toDelegate = () => {
    setActiveScreen(userLayoutScreenEnum.SecondScreen);
  };

  const toShared = () => {
    setActiveScreen(userLayoutScreenEnum.ThirdScreen);
  };

  const backToTheFirstScreen = () => {
    setActiveScreen(userLayoutScreenEnum.FirstScreen);
  };

  const onCloseSidebar = () => {
    setIsOpen(false);
    document.body.classList.remove("overflow-hidden");
  };

  const openDelegateForm = () => {
    setOpenDelegateForm(true);
    onCloseSidebar();
  };

  const onCloseDelegateForm = () => {
    setOpenDelegateForm(false);
    setIsOpen(true);
    toDelegate();
    document.body.classList.add("overflow-hidden");
  };

  const onSuccessDelegate = () => {
    setOpenDelegateForm(false);
    dispatch(getAssistantsAction());
  };

  const handleDelegateBack = () => {
    dispatch(discardDelegation());
    history.push("/");
  };

  return (
    <div className={`header ${isShowDelegateBack ? "active-delegate" : ""}`}>
      {isShowDelegateBack && (
        <button className="active-delegate-button" onClick={handleDelegateBack}>
          <i className="icon-arrow-left" />
          <span>Back to my account</span>
        </button>
      )}

      <div className="header-content">
        <div className="header-column-left">
          <div className="logo-block">{renderLogo()}</div>
        </div>
        <div className="header-column-right">
          {promoteStep ? null : (
            <div
              className={`user-block ${isManager ? "manager" : ""} ${
                isClickableAvatar ? "pointer" : ""
              }`}
              onClick={handleOpenRequestForm}>
              <div className="user-name-column">
                <h3 className="user-name">{agentName}</h3>
                <span style={{ color: brandColor }} className="user-info">
                  {roleTitle}
                </span>
              </div>
              <HeaderAvatar userData={currentUser} />
              {isShowFlyoutDots && (
                <i className="icon-settings avatar-settings"></i>
              )}
            </div>
          )}

          <RightSidebarModal onClose={onCloseSidebar} isOpen={isOpen}>
            <DelegateUserFirstScreen
              isMarketingManager={isMarketingManager}
              isMultipleRoleAgent={isMultipleRoleAgent}
              rootUser={rootUser}
              isOpen={activeScreen === userLayoutScreenEnum.FirstScreen}
              isManager={isManager}
              toggleDelegate={toDelegate}
              toggleShared={toShared}
              onCloseSidebar={onCloseSidebar}
              roleTitle={roleTitle}
            />
            {!isMarketingManager && (
              <>
                <DelegateUsers
                  isOpen={activeScreen === userLayoutScreenEnum.SecondScreen}
                  onClick={backToTheFirstScreen}
                  onOpenDelegateForm={openDelegateForm}
                />
                <SharedUsers
                  isOpen={activeScreen === userLayoutScreenEnum.ThirdScreen}
                  onClick={backToTheFirstScreen}
                  onClose={onCloseSidebar}
                />
              </>
            )}
          </RightSidebarModal>
        </div>

        {isOpenDelegateForm && (
          <DelegateAccessPopup
            onClose={onCloseDelegateForm}
            onSuccess={onSuccessDelegate}
          />
        )}
      </div>
    </div>
  );
});

export default Header;
