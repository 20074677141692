export const selectManagerAdStepperState = (state) =>
  state?.managerAd?.isOpenStepper;
export const selectStepperStep = (state) => state?.managerAd?.openStep;
export const selectManagerAdMediaType = (state) =>
  state?.managerAd?.mediaAdType;
export const selectManagerAdFeedFile = (state) => state?.managerAd?.feedFile;
export const selectManagerAdStoryFile = (state) => state?.managerAd?.storyFile;
export const selectIsDisableNextButtonUploadPage = (state) =>
  !state?.managerAd?.feedFile || !state?.managerAd?.storyFile;
export const selectManagerHeadline = (state) => state?.managerAd?.headline;
export const selectManagerHeadlineCheck = (state) =>
  state?.managerAd?.headlineCheck;
export const selectManagerValidationLoading = (state) =>
  state?.managerAd?.validationLoading;
export const selectManagerPrimary = (state) => state?.managerAd?.primary;
export const selectManagerPrimaryCheck = (state) =>
  state?.managerAd?.primaryCheck;

export const selectDestinationLink = (state) =>
  state?.managerAd?.destinationLink;

export const selectDestinationLinkError = (state) =>
  state?.managerAd?.destinationLinkErrorMessage;

export const selectActionButton = (state) => state?.managerAd?.actionButton;

export const selectRegionList = (state) => state?.managerAd?.regionList;
export const selectIsCorporate = (state) => state?.managerAd?.isCorporate;
export const selectSelectedRegions = (state) =>
  state?.managerAd?.selectedRegions;
export const selectIsNational = (state) => state?.managerAd?.isNational;
export const selectBudgetInfo = (state) => state?.managerAd?.facebookBudgetInfo;
export const selectSelectedBudget = (state) => state?.managerAd?.selectedBudget;
export const selectPublishLoading = (state) => state?.managerAd?.publishLoading;
export const selectBudgetPrice = (state) =>
  state?.managerAd?.selectedBudget?.price;

export const selectCreditFullName = (state) => state?.managerAd?.creditFullName;
export const selectCreditBillingZipCode = (state) => state?.managerAd?.zipCode;
export const selectPublishResult = (state) => state?.managerAd?.publishResult;
export const selectCreditCardNumber = (state) =>
  state?.managerAd?.creditCardNumber;
export const selectCreditExpirationDate = (state) =>
  state?.managerAd?.creditExpirationDate;

export const selectVideoAdInfo = (state) => state?.managerAd?.videoAdInfo;
export const selectVideoAdInfoLoading = (state) =>
  state?.managerAd?.videoAdInfoLoading;

export const selectWriteCopyNextDisabled = (state) => {
  const { managerAd } = state;
  const {
    actionButton,
    destinationLinkErrorMessage,
    destinationLink,
    primaryCheck,
    headlineCheck,
    destinationLinkChecked,
  } = managerAd;
  const isValidPrimary = primaryCheck.length === 0;
  const isValidHeadline = headlineCheck.length === 0;
  const isValidDestination =
    !!destinationLink && !destinationLinkErrorMessage && destinationLinkChecked;

  return (
    isValidPrimary && isValidHeadline && isValidDestination && !!actionButton
  );
};
