import axios from "axios";
import { networkActions } from "../../constants/constants";

const fetchAuthenticateSSO = (accessToken) =>
  axios.post(
    `${process.env.REACT_APP_BASE_API_URL}${networkActions.AUTHENTICATE_SSO}`,
    {
      token: accessToken,
    }
  );

export default fetchAuthenticateSSO;
