import axios from "../index";
import { networkActions } from "../../constants/constants";

export const addDelegationRequest = (delegateUserEmail) =>
  axios.post(networkActions.DELEGATION_ACCESS, { delegateUserEmail });

export const getAssistantsRequest = () =>
  axios.get(networkActions.DELEGATION_ACCESS);

export const getAssistedForRequest = () =>
  axios.get(networkActions.DELEGATION_ACCESS_OWNER);

export const acceptDelegationRequest = (fromUserId) =>
  axios.put(`${networkActions.ACCEPT_DELEGATION_ACCESS}/${fromUserId}`);

export const suspendDelegationRequest = (userId) =>
  axios.put(`${networkActions.DELEGATION_ACCESS}/${userId}`);

export const getDelegationUserRequest = () =>
  axios.post(`${networkActions.GET_PERSONAL_DATA}`, {});
