const paymentActionTypes = {
    SAVE_MARKETING_BUDGETS: "save_marketing_budgets",
    SAVE_AMP_BALANCE: "save_amp_balance",
    SET_PAYMENT_LOADING: "set_payment_loading",
    SET_PAYMENT_TYPE: "set_payment_type",
    SET_CREDIT_CARD_INFO: "set_credit_card_info",
    EMPTY_CREDIT_CARD_INFO: "empty_credit_card_info",
    SET_PAYMENT_ERROR: "set_payment_error",
    EMPTY_PAYMENT_DATA: "empty_payment_data"
};

export default paymentActionTypes;
