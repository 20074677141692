export const isValidImage = (url) => new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = function () {
            if (this.width > 0) {
                resolve(url);
            } else {
                reject(url);
            }
        };
        image.onerror = function () {
            reject(url);
        };
        image.src = url;
    });

export default isValidImage;
