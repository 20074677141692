import emailActionTypes from "../actions/email/emailActionTypes";

const initialState = {
    shareAnalyticsLoading: false,
    openShareAnalyticsModal: false,
};

// eslint-disable-next-line default-param-last
const emailReducer = (state = initialState, action) => {
    switch (action.type) {
        case emailActionTypes.SET_SHARE_ANALYTICS_LOADING:
            return { ...state, shareAnalyticsLoading: action.payload };
        case emailActionTypes.SET_SHARE_ANALYTICS_OPEN:
            return { ...state, openShareAnalyticsModal: action.payload };
        default:
            return state;
    }
};

export default emailReducer;
