const managerActionsTypes = {
  GET_MANAGER_INFO: "GET_MANAGER_INFO",
  GET_MANAGER_AGENT_INFO: "GET_MANAGER_AGENT_INFO",
  SET_MANAGER_INFO_LOADING: "SET_MANAGER_INFO_LOADING",
  SET_MANAGER_INFO_REGIONS: "SET_MANAGER_INFO_REGIONS",
  SET_MANAGER_AGENT_LOADING: "SET_MANAGER_AGENT_LOADING",
  SET_MANAGER_RESET: "SET_MANAGER_RESET",
};

export default managerActionsTypes;
