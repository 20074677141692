const managerAdAnalyticsTypes = {
  SET_MANAGER_AD_DETAILS_LOADING: "SET_MANAGER_AD_DETAILS_LOADING",
  SET_MANAGER_AD_DETAILS: "SET_MANAGER_AD_DETAILS",
  SET_MANAGER_AD_ANALYTICS_ERROR: "SET_MANAGER_AD_ANALYTICS_ERROR",
  SET_MANAGER_AD_ANALYTICS_LOADING: "SET_MANAGER_AD_ANALYTICS_LOADING",
  SET_MANAGER_AD_ANALYTICS: "SET_MANAGER_AD_ANALYTICS",
  SET_MANAGER_AD_INFO: "SET_MANAGER_AD_INFO",
  SET_MANAGER_AD_INFO_LOADING: "SET_MANAGER_AD_INFO_LOADING",
  SET_MANAGER_AD_INFO_ERROR: "SET_MANAGER_AD_INFO_ERROR",
  SET_MANAGER_CANCEL_OPEN_AD_ID: "SET_MANAGER_CANCEL_OPEN_AD_ID",
};

export default managerAdAnalyticsTypes;
