import adActionTypes from "../actions/ad/adActionTypes";
import {
  callToActionEnum,
  creativeLogoTypesEnum,
  goalsEnum,
} from "../../constants/enums";
import { adTypes } from "../../constants/constants";

const initialState = {
  invoice: {
    type: "",
    info: {},
  },
  listingId: "",
  goal: goalsEnum.LINK_CLICKS,
  adMediaType: null,
  facebookHeadline: "",
  facebookHeadlineCheck: [],
  facebookValidationLoading: false,
  facebookPrimaryText: "",
  facebookPrimaryTextCheck: [],

  googleHeadline: "",
  googleAllHeadlines: [],
  googleHeadlineCheck: [],
  googleLongHeadline: "",
  googleAllLongHeadlines: [],
  googleLongHeadlineCheck: [],
  googleDescription: "",
  googleAllDescriptions: [],
  googleDescriptionCheck: [],

  googleValidationLoading: false,
  callToAction: callToActionEnum.ShopNow,
  houseFbPhotosSingle: [],
  houseGooglePhotos: [],
  housePhotosCarousel: [],
  adConfigsByGoal: {},
  adTypeFacebook: adTypes.SINGLE,
  adTypeGoogle: adTypes.SINGLE,
  convertedHousePhotos: [],
  generatedPhotos: [],
  budgetId: null,
  facebookAdLogoType: creativeLogoTypesEnum.AgentLogo,
  googleAdLogoType: creativeLogoTypesEnum.AgentLogo,
  price: null,
  notification: "",
  adCancelSuccess: false,
  adCancelLoading: false,
  adCancelError: "",
  adDetails: {},
  mapChartData: [],
  mapChartMax: 0,
  deviceChartData: { series: { data: [] } },
  adDetailsLoading: false,
  adDetailsError: false,
  loading: false,
  adPreviewInfo: {
    loading: false,
    data: {},
  },
  googlePreviewImages: [],
  isComingSoon: false,
};

// eslint-disable-next-line default-param-last
const adReducer = (state = initialState, action) => {
  switch (action.type) {
    case adActionTypes.SAVE_INVOICE_INFO:
      return {
        ...state,
        invoice: { ...action.payload },
        loading: false,
      };
    case adActionTypes.SET_LISTING_ID:
      return {
        ...state,
        listingId: action.payload,
      };
    case adActionTypes.SET_AD_CONFIGS_BY_GOAL:
      return {
        ...state,
        adConfigsByGoal: action.payload,
      };
    case adActionTypes.SET_GOAL:
      return {
        ...state,
        goal: action.payload,
      };
    case adActionTypes.SET_AD_MEDIA_TYPES:
      return {
        ...state,
        adMediaType: action.payload,
      };
    case adActionTypes.SET_FACEBOOK_HEADLINE:
      return {
        ...state,
        facebookHeadline: action.payload,
        facebookValidationLoading: true,
      };
    case adActionTypes.SET_FACEBOOK_HEADLINE_CHECK:
      return {
        ...state,
        facebookHeadlineCheck: action.payload,
        facebookValidationLoading: false,
      };
    case adActionTypes.SET_FACEBOOK_PRIMARY_TEXT:
      return {
        ...state,
        facebookPrimaryText: action.payload,
        facebookValidationLoading: true,
      };
    case adActionTypes.SET_FACEBOOK_PRIMARY_CHECK:
      return {
        ...state,
        facebookPrimaryTextCheck: action.payload,
        facebookValidationLoading: false,
      };

    // start google
    case adActionTypes.SET_GOOGLE_REVIEW_COPY_INFO:
      return {
        ...state,
        googleHeadline: action.payload?.headline || "",
        googleLongHeadline: action.payload?.longHeadline || "",
        googleDescription: action.payload?.description || "",
        googleAllHeadlines: action.payload?.allHeadlines || [],
        googleAllLongHeadlines: action.payload?.allLongHeadlines || [],
        googleAllDescriptions: action.payload?.allDescriptions || [],
      };

    case adActionTypes.SET_GOOGLE_HEADLINE:
      return {
        ...state,
        googleHeadline: action.payload,
        googleValidationLoading: true,
      };
    case adActionTypes.SET_GOOGLE_HEADLINE_CHECK:
      return {
        ...state,
        googleHeadlineCheck: action.payload,
        googleValidationLoading: false,
      };
    case adActionTypes.SET_GOOGLE_LONG_HEADLINE_TEXT:
      return {
        ...state,
        googleLongHeadline: action.payload,
        googleValidationLoading: false,
      };

    case adActionTypes.SET_GOOGLE_LONG_HEADLINE_CHECK:
      return {
        ...state,
        googleLongHeadlineCheck: action.payload,
        googleValidationLoading: false,
      };
    case adActionTypes.SET_GOOGLE_DESCRIPTION_TEXT:
      return {
        ...state,
        googleDescription: action.payload,
        googleValidationLoading: false,
      };
    case adActionTypes.SET_GOOGLE_DESCRIPTION_CHECK:
      return {
        ...state,
        googleDescriptionCheck: action.payload,
        googleValidationLoading: false,
      };
    case adActionTypes.SET_HOUSE_GOOGLE_PHOTOS:
      return {
        ...state,
        houseGooglePhotos: action.payload,
      };

    // end google
    case adActionTypes.SET_AD_CALL_ACTION:
      return {
        ...state,
        callToAction: action.payload,
      };
    case adActionTypes.SET_HOUSE_FB_PHOTOS_SINGLE:
      return {
        ...state,
        houseFbPhotosSingle: action.payload,
      };

    case adActionTypes.SET_HOUSE_PHOTOS_CAROUSEL:
      return {
        ...state,
        housePhotosCarousel: action.payload,
      };
    case adActionTypes.SET_CONVERTED_HOUSE_PHOTOS:
      return {
        ...state,
        convertedHousePhotos: action.payload,
      };
    case adActionTypes.SET_GENERATED_PHOTOS:
      return {
        ...state,
        generatedPhotos: action.payload,
      };
    case adActionTypes.SET_AD_TYPE_FACEBOOK:
      return {
        ...state,
        adTypeFacebook: action.payload,
      };
    case adActionTypes.SET_AD_TYPE_GOOGLE:
      return {
        ...state,
        adTypeGoogle: action.payload,
      };
    case adActionTypes.SET_FACEBOOK_AD_LOGO_TYPE:
      return {
        ...state,
        facebookAdLogoType: action.payload,
      };
    case adActionTypes.SET_GOOGLE_AD_LOGO_TYPE:
      return {
        ...state,
        googleAdLogoType: action.payload,
      };
    case adActionTypes.SAVE_AD_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
        loading: false,
      };
    case adActionTypes.SAVE_AD_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case adActionTypes.SET_BUDGET:
      return {
        ...state,
        budgetId: action.payload.id,
        price: action.payload.price,
      };
    case adActionTypes.SET_AD_DETAILS:
      return {
        ...state,
        adDetails: action.payload,
        adDetailsError: false,
      };
    case adActionTypes.SET_AD_DETAILS_EMPTY:
      return {
        ...state,
        adDetails: {},
        adDetailsError: false,
      };
    case adActionTypes.SET_MAP_CHART_DATA:
      return {
        ...state,
        mapChartData: action.payload,
      };
    case adActionTypes.SET_MAP_CHART_DATA_MAX:
      return {
        ...state,
        mapChartDataMax: action.payload,
      };
    case adActionTypes.SET_DEVICE_CHART_DATA:
      return {
        ...state,
        deviceChartData: action.payload,
      };
    case adActionTypes.SET_AD_DETAILS_LOADING:
      return {
        ...state,
        adDetailsLoading: action.payload,
        adDetailsError: false,
      };
    case adActionTypes.SET_AD_DETAILS_ERROR:
      return {
        ...state,
        adDetailsLoading: false,
        adDetailsError: true,
      };
    case adActionTypes.SUCCESSFULLY_CANCEL_AD:
      return {
        ...state,
        adCancelSuccess: action.payload,
        adCancelLoading: false,
      };
    case adActionTypes.CANCEL_AD_ERROR:
      return {
        ...state,
        adCancelError: action.payload,
        adCancelLoading: false,
      };
    case adActionTypes.CANCEL_AD_LOADING:
      return {
        ...state,
        adCancelLoading: action.payload,
      };
    case adActionTypes.SET_AD_PREVIEW_INFO_LOADING:
      return {
        ...state,
        adPreviewInfo: {
          ...state.adPreviewInfo,
          loading: action.payload,
        },
      };
    case adActionTypes.SET_AD_PREVIEW_INFO_ERROR:
      return {
        ...state,
        adPreviewInfo: {
          data: {},
          loading: false,
          error: action.payload,
        },
      };
    case adActionTypes.SET_IS_COMING_SOON:
      return {
        ...state,
        isComingSoon: action.payload,
      };
    case adActionTypes.SET_AD_PREVIEW_INFO:
      return {
        ...state,
        adPreviewInfo: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    case adActionTypes.SET_GOOGLE_PREVIEW_IMAGES:
      return {
        ...state,
        googlePreviewImages: action.payload,
      };
    case adActionTypes.EMPTY_GOOGLE_AD_DATA:
      return {
        ...state,
        googleHeadline: state?.googleAllHeadlines[0] || "",
        googleHeadlineCheck: [],
        googleLongHeadline: state?.googleAllLongHeadlines[0] || "",
        googleLongHeadlineCheck: [],
        googleDescription: state?.googleAllDescriptions[0] || "",
        googleDescriptionCheck: [],
        googleValidationLoading: false,
      };
    case adActionTypes.EMPTY_AD_DATA:
      return initialState;
    default:
      return state;
  }
};

export default adReducer;
