import React from "react";
import { useSelector } from "react-redux";
import { selectPromoteListingStep } from "../../../store/selectors/listingSelector";

const PromoteStepsHeader = () => {
  const promoteListingStep = useSelector(selectPromoteListingStep);

  return (
    <div className="step-info-row promote-listings">
      <div className="container">
        <div className={`step-info-row-content step-${promoteListingStep}`}>
          <p className="step-info-title">
            {`STEP ${promoteListingStep} OF 5`}
            <span className="step-info-title-hide">
              to promote your listing
            </span>
          </p>
          <div className="step-info-row-content-inner">
            <div className="step-info-row-text-column">
              <div className={`step-name-container step-${promoteListingStep}`}>
                <h3 className="step-name">select your goal</h3>
                <h3 className="step-name">choose your media</h3>
                <h3 className="step-name">review copy</h3>
                <h3 className="step-name">
                  choose <span className="step-name-hide">listing</span> images
                </h3>
                <h3 className="step-name">preview your ad</h3>
              </div>
            </div>
            <div className="step-info-row-arrows-column">
              <div className="steps-arrows">
                {/* <i */}
                {/*    onClick={() => */}
                {/*        // eslint-disable-next-line max-len */}
                {/* eslint-disable-next-line max-len */}
                {/*        dispatch(setPromoteListingStepAction(promoteListingStep + 1))} */}
                {/* eslint-disable-next-line max-len */}
                {/*    className={`icon-arrow-right step-arrow ${isNextArrowDisabled ? "disabled" : ""}`} */}
                {/* /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="steps-progress-row">
        <div
          className="steps-progress"
          style={{ width: `${promoteListingStep * 20}%` }}
        />
      </div>
    </div>
  );
};

export default PromoteStepsHeader;
