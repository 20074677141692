import axios from "axios";
import authenticationService from "../services/auth";
import setPermissionIssueStatusAction from "../store/actions/permission/permissionActions";
import store from "../store/index";
// import { networkActions } from "../constants/constants";

const unauthorizedStatusCodes = [401, 403];
const currentExecutingRequests = {};

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const refreshAccessToken = async () => {
  // const { refreshToken, uniqueIdentifier } =
    authenticationService.getRootUser();
  return Promise.resolve();
  // if (!refreshToken && !uniqueIdentifier) {
  //   return;
  // }

  // try {
  //   const refreshTokenResponse = await fetch(
  //     process.env.REACT_APP_BASE_API_URL + networkActions.GET_REFRESH_TOKEN,
  //     {
  //       method: "POST",
  //       body: JSON.stringify({ refreshToken, uniqueIdentifier }),
  //       headers,
  //     }
  //   );

  //   if (refreshTokenResponse.status === 200) {
  //     const responseBody = await refreshTokenResponse.json();
  //     authenticationService.setRefreshToken(responseBody, uniqueIdentifier);
  //     return responseBody.token;
  //   }
  // } catch (error) {
  //   // TODO: Handle error case
  // }
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers,
});

instance.interceptors.request.use(
  (req) => {
    const originalRequest = req;

    const authData = authenticationService.getRootUser();
    const delegateUserId = authenticationService.getDelegationAgent();
    if (!authData || !authData.token) {
      return originalRequest;
    }

    originalRequest.headers.Authorization = `Bearer ${authData.token}`;

    if (delegateUserId) {
      originalRequest.headers.delegateUserId = delegateUserId;
    }

    if (currentExecutingRequests[req.url]) {
      const source = currentExecutingRequests[req.url];
      delete currentExecutingRequests[req.url];
      source.cancel();
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    originalRequest.cancelToken = source.token;
    currentExecutingRequests[req.url] = source;

    instance.cancelPendingRequests = (cancellationReason) => {
      Object.keys(currentExecutingRequests).forEach((url) => {
        currentExecutingRequests[url].cancel(cancellationReason);
        delete currentExecutingRequests[url];
      });
    };
    return originalRequest;
  },
  (err) => Promise.reject(err)
);

instance.interceptors.response.use(
  (response) => {
    if (currentExecutingRequests[response.request.responseURL]) {
      // here you clean the request
      delete currentExecutingRequests[response.request.responseURL];
    }
    return response;
  },
  (error) => {
    const { config } = error;
    const originalRequest = config;

    if (axios.isCancel(error)) {
      // here you check if this is a cancelled request to drop it silently (without error)
      return new Promise(() => {});
    }

    if (currentExecutingRequests[originalRequest.url]) {
      // here you clean the request
      delete currentExecutingRequests[originalRequest.url];
    }

    return Promise.reject(error);
  }
);

// Refresh the access token if it becomes invalid
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { dispatch } = store;

    if (
      unauthorizedStatusCodes.indexOf(error.response.status) !== -1 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      dispatch(setPermissionIssueStatusAction(false));

      const token = await refreshAccessToken();

      dispatch(setPermissionIssueStatusAction(!token));

      if (token) {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return instance(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
