import permissionActionTypes from "./permissionActionTypes";

const setPermissionIssueStatusAction = (status) => (dispatch) => {
  dispatch({
    type: permissionActionTypes.SET_PERMISSION_ISSUE_STATUS,
    payload: status,
  });
};

export const setDoesNotUserCase = (status) => (dispatch) => {
  dispatch({
    type: permissionActionTypes.SET_DOES_NOT_USER_CASE,
    payload: status,
  });
};

export const setIsManager = (isManager) => (dispatch) => {
  dispatch({
    type: permissionActionTypes.SET_MANAGER_ROLE,
    payload: isManager,
  });
};

export default setPermissionIssueStatusAction;
