const supportActionTypes = {
    SET_ISSUE_TYPE: "set_issue_type",
    SET_ISSUE_DESCRIPTION: "set_issue_description",
    SET_ATTACHMENTS: "set_attachments",
    SET_SENT_STATUS: "set_sent_status",
    SEND_EMAIL_LOADING: "send_email_loading",
    EMPTY_SUPPORT_DATA: "empty_support_data"
};

export default supportActionTypes;
