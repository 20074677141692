import moment from "moment";
import { dayStatuses } from "../constants/constants";

export const getDatesDiffByDays = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const utcToLocal = (date) => {
  const utcTime = moment.utc(date);
  const localTime = utcTime?.local();
  return localTime?.format();
};

export const dateRenderer = (date) => {
  if (!date) {
    return "";
  }

  const d = new Date(date);

  return moment(d).format("MMM D, YYYY");
};

export const dayStatusRenderer = (start, end, isCanceled, prefix = "") => {
  if (!start || !end) {
    return "";
  }
  const startLocal = moment(utcToLocal(start)).format();
  const endLocal =  moment(utcToLocal(end)).format();

  const isEnded = new Date() - new Date(endLocal) >= 0;

  if (isCanceled || isEnded) {
    return dayStatuses.ENDED;
  }

  const adPeriodDays = getDatesDiffByDays(
    new Date(startLocal),
    new Date(endLocal)
  );
  const leftDays = getDatesDiffByDays(new Date(), new Date(startLocal));

  return `${prefix ? `${prefix} ` : ""}Day ${leftDays} of ${adPeriodDays}`;
};

export const getDefaultSelectValues = () => {
  const todayRange = [moment().toDate(), moment().toDate()];
  const yesterdayRange = [
    moment().add(-1, "day").toDate(),
    moment().add(-1, "day").toDate(),
  ];
  const lastWeekRange = [moment().add(-1, "week").toDate(), moment().toDate()];

  const lastMonthRange = [
    moment().add(-1, "months").toDate(),
    moment().toDate(),
  ];
  const lastThreeMonthRange = [
    moment().add(-3, "months").toDate(),
    moment().toDate(),
  ];
  const lastSixMonthRange = [
    moment().add(-6, "months").toDate(),
    moment().toDate(),
  ];

  const todayTitle = "Today";
  const yesterdayTitle = "Yesterday";
  const lastWeekTitle = "Last week";
  const lastMonthTitle = "Last month";
  const lastThreeMonthTitle = "Last 3 months";
  const lastSixMonthTitle = "Last 6 months";

  return [
    {
      title: todayTitle,
      range: todayRange,
    },
    {
      title: yesterdayTitle,
      range: yesterdayRange,
    },
    {
      title: lastWeekTitle,
      range: lastWeekRange,
    },
    {
      title: lastMonthTitle,
      range: lastMonthRange,
    },
    {
      title: lastThreeMonthTitle,
      range: lastThreeMonthRange,
    },
    {
      title: lastSixMonthTitle,
      range: lastSixMonthRange,
    },
  ];
};

export const getRangeByDefaultValues = (title) => {
  const defaultSelectValues = getDefaultSelectValues();
  const selectedDefaultValue = defaultSelectValues.find(
    (item) => item.title === title
  );
  const selectedRange = selectedDefaultValue?.range;
  return selectedRange;
};
