import { asanaFormLink } from "../../constants/constants";

const MessageForDelegate = () => (
  <div className="message_for_delegate">
    Something went wrong. Please try again later or submit a support ticket by
    clicking
    <a href={asanaFormLink} target="_blank">
      here
    </a>
    .
  </div>
);

export default MessageForDelegate;
