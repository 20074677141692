import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { promoteStepsEnum } from "../../../constants/enums";
import { setPromoteListingStepAction } from "../../../store/actions/listing/listingActions";
import { selectPromoteListingStep } from "../../../store/selectors/listingSelector";

const StepsCountColumn = () => {
  const promoteListingStep = useSelector(selectPromoteListingStep);

  const dispatch = useDispatch();

  return (
    <div className="steps-count-column">
      <div className="steps-count-column-inner">
        <h4 className="steps-count-title">
          <span>building your ad</span>
        </h4>
        <div className="steps-count-items">
          {Object.keys(promoteStepsEnum).map((item, key) => (
            <span
              key={key}
              onClick={() => dispatch(setPromoteListingStepAction(key + 1))}
              className={`steps-count-item ${
                key < promoteListingStep ? "active" : ""
              }`}
            />
          ))}
        </div>
        <p className="steps-count-bottom">
          Step <span>{promoteListingStep}</span> of 5
        </p>
      </div>
    </div>
  );
};

export default StepsCountColumn;
