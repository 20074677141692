import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip2 } from "@blueprintjs/popover2";
import SimpleCard from "./SimpleCard";
import DelegateSingleUser from "../../UI/DelegateSingleUser";
import {
  selectAssistants,
  selectAssistantsLoading,
} from "../../store/selectors/delegationSelector";
import { getAssistantsAction } from "../../store/actions/delegation/delegationAction";
import SuspendDelegation from "../../UI/SuspendDelegation";
import { suspendDelegationRequest } from "../../api/delegation";
import { notificationsServices } from "../../helpers/notifications";
import MessageForDelegate from "../../UI/MessageForDelegate";

const DelegateUsers = ({ onClick, isOpen, onOpenDelegateForm }) => {
  const [isSuspendOpen, setIsSuspendOpen] = useState(false);
  const [selectAgent, setSelectAgent] = useState(null);

  const dispatch = useDispatch();
  const assistants = useSelector(selectAssistants);
  const assistantsLoading = useSelector(selectAssistantsLoading);

  useEffect(() => {
    if (!assistants.length) {
      dispatch(getAssistantsAction());
    }
  }, []);

  const openSuspend = (agent) => () => {
    setIsSuspendOpen(true);
    setSelectAgent(agent);
  };

  const closeSuspend = () => {
    setIsSuspendOpen(false);
    setSelectAgent(null);
  };

  const onSuspend = async () => {
    const agentId = selectAgent?.userId;
    try {
      await suspendDelegationRequest(agentId);
      dispatch(getAssistantsAction());
      closeSuspend();
    } catch (error) {
      notificationsServices.errorWithComponent(MessageForDelegate);
    }
  };

  const hasDelegates = assistantsLoading || !!assistants.length;
  const firstLoading = assistantsLoading && assistants.length === 0;

  return (
    <>
      <div className={`delegate-content ${isOpen ? "open" : ""}`}>
        <div className="delegate-top">
          <SimpleCard>
            <div className="delegate-access">
              <h3 className="box-title">
                <i className="icon-arrow-left-b" onClick={onClick}></i>
                Delegate Access
                <Tooltip2
                  portalClassName="delegate-tooltip"
                  content={
                    <div className="delegate-tooltip-content">
                      Click "Add Delegate" button to give another DE user access to
                      place ads and view analytics on your behalf.
                    </div>
                  }
                  placement="top"
                  usePortal
                >
                  <i className="icon-info delegate-access-icon-info" />
                </Tooltip2>
              </h3>
              <button className="button" onClick={onOpenDelegateForm}>
                Add Delegate
              </button>
            </div>
          </SimpleCard>
        </div>
        <div className="delegate-body">
          {hasDelegates && (
            <SimpleCard loading={firstLoading}>
              <h3 className="box-title">Current Delegates</h3>
              <div className="delegate-users-list">
                {assistants.map((user) => (
                  <DelegateSingleUser
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                    onClickIcon={openSuspend(user)}
                    key={user?.userId}
                    avatarUrl={user?.avatarUrl}
                    userName={user?.fullName}
                    userEmail={user?.email}
                    withIcon
                  />
                ))}
              </div>
            </SimpleCard>
          )}
        </div>
      </div>
      {isSuspendOpen && (
        <SuspendDelegation
          onClose={closeSuspend}
          agent={selectAgent}
          onSuspend={onSuspend}
        />
      )}
    </>
  );
};

export default DelegateUsers;
