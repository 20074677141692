import React, { memo, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Switch } from "react-router-dom";
import Authenticate from "./auth/Authenticate";
import PermissionIssue from "./permissionIssue";
import ComponentWrapper from "./layout/ComponentWrapper";
import MainLayout from "./layout/MainLayout";
import { routesPath } from "../constants/constants";
import Spinner from "./shared/Spinner";
import Maintenance from "./maintenance";
import DelegatePage from "./delegate";

const spinner = <span />;
const SpinnerComponent = () => <Spinner />;

const Root = () => {
  const { AUTHENTICATE, ISSUE, MAINTENANCE, SSO, DELEGATE } = routesPath;

  return (
    <div className="main-wrapper">
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName="toastBody"
      />
      <Suspense fallback={spinner}>
        <Switch>
          <Route path={`/${AUTHENTICATE}`} component={Authenticate} />
          <Route path={`/${ISSUE}`} component={PermissionIssue} />
          <Route path={`/${SSO}`} component={SpinnerComponent} />
          <Route path={`/${MAINTENANCE}`} component={Maintenance} />
          <Route path={`/${DELEGATE}`} component={DelegatePage} />
          <Route path="/" component={ComponentWrapper(MainLayout)} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default memo(Root);
