import moment from "moment";
import { getRangeByDefaultValues } from "../../../helpers/dates";

const requestDateFormat = "YYYY-MM-DD";
const defaultStartDay = "2022-01-01";
const defaultEndDate = moment().format(requestDateFormat);

export const getRangeByDateFilter = (filter) => {
  let dateRange = [defaultStartDay, defaultEndDate];
  if (filter) {
    if (filter?.dateRange.length > 1) {
      dateRange = [
        moment(filter.dateRange[0]).format(requestDateFormat),
        moment(filter.dateRange[1]).format(requestDateFormat),
      ];
    } else if (filter?.selectedRange) {
      const range = getRangeByDefaultValues(filter.selectedRange);
      if (range) {
        dateRange = [
          moment(range[0]).format(requestDateFormat),
          moment(range[1]).format(requestDateFormat),
        ];
      }
    }
  }

  return dateRange;
};

export const getURLSearchParams = (filter, searchValue, regionFilter) => {
  const params = new URLSearchParams();
  const [startDate, endDate] = getRangeByDateFilter(filter);
  if (searchValue) {
    params.append("SearchTerm", searchValue);
  }
  params.append("Date.From", startDate);
  params.append("Date.To", endDate);

  if (regionFilter && Array.isArray(regionFilter)) {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < regionFilter.length; index++) {
      const element = regionFilter[index];
      params.append("Regions", element);
    }
  }
  return params;
};
