import React, { useState, useRef, useEffect } from "react";
import autosize from "autosize";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import useOutsideClick from "../../../hooks/useOutsideClick";
import {
  saveIssueTypeAction,
  saveIssueDescriptionAction,
  saveAttachmentsAction,
  sendSupportEmailAction,
} from "../../../store/actions/support/supportActions";
import { issueTypesList } from "../../../constants/constants";
import {
  selectIssueAttachments,
  selectIssueDescription,
  selectIssueType,
  selectSendEmailLoading,
} from "../../../store/selectors/supportSelector";
import { popupStatusesEnum } from "../../../constants/enums";

const attachmentsMaxCount = 3;

const getIsHaveSameFile = (files, newFile) =>
  files.some((attachment) =>
    newFile.some((item) => {
      // this case need for rejected files
      if (item.errors) {
        return item.file.name === attachment.file.name;
      }
      return item.name === attachment.file.name;
    }));

const SupportRequestForm = ({ popupStatus }) => {
  const selectRef = useRef(null);
  const descriptionRef = useRef(null);

  const [issueSelectOpenStatus, setIssueSelectOpenStatus] = useState(false);
  const [isValidated, setValidated] = useState(false);

  const selectedIssueType = useSelector(selectIssueType);
  const description = useSelector(selectIssueDescription);
  const attachments = useSelector(selectIssueAttachments);
  const loading = useSelector(selectSendEmailLoading);

  const dispatch = useDispatch();

  useOutsideClick(selectRef, () => setIssueSelectOpenStatus(false));

  useEffect(() => {
    autosize(descriptionRef.current);
  }, [descriptionRef]);

  useEffect(() => {
    if (popupStatus !== popupStatusesEnum.NONE) {
      descriptionRef.current.style.height = "88px";
    }

    if (popupStatus !== popupStatusesEnum.SUPPORT) {
      setValidated(false);
    }
  }, [popupStatus]);

  const onDrop = (acceptedFiles) => {
    const isHave = getIsHaveSameFile(attachments, acceptedFiles);
    if (!isHave) {
      const remainingCount = attachmentsMaxCount - attachments.length;
      const newAcceptedFiles = acceptedFiles
        .slice(0, remainingCount)
        .map((item) => ({
          file: item,
          error: "",
        }));
      dispatch(saveAttachmentsAction([...attachments, ...newAcceptedFiles]));
    }
  };

  const onDropRejected = (rejectedAttachments) => {
    const isHave = getIsHaveSameFile(attachments, rejectedAttachments);
    if (!isHave) {
      const remainingCount = attachmentsMaxCount - attachments.length;
      const newAcceptedFiles = rejectedAttachments
        .slice(0, remainingCount)
        .map((item) => ({
          file: item.file,
          error: item.errors,
        }));
      dispatch(saveAttachmentsAction([...attachments, ...newAcceptedFiles]));
    }
  };

  const onDescriptionKeyDown = (e) => {
    if (e.key === "Enter" && !description) {
      e.preventDefault();
    }
  };

  const handleChangeDescription = (e) => {
    let value = e.target.value;

    value = value.trimStart();
    value = value.replace(/  +/g, " ");

    if (/\n\s*\n/g.test(value)) {
      return;
    }

    dispatch(saveIssueDescriptionAction(value));
  };

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDropRejected,
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxSize: 7 * 1024 * 1024,
    accept: {
      "image/*": [".png", ".jpg"],
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
      "application/zip": [".zip"],
    },
  });
  const isSelectIssueType = selectedIssueType !== null;

  const handleSelectIssueType = (type) => {
    dispatch(saveIssueTypeAction(type));
    setIssueSelectOpenStatus(false);
  };

  const handleDeleteFile = (e, key) => {
    e.preventDefault();
    const attachmentsCopy = [...attachments];
    attachmentsCopy.splice(key, 1);
    dispatch(saveAttachmentsAction(attachmentsCopy));
    if (inputRef.current && inputRef.current?.value) {
      inputRef.current.value = "";
    }
  };

  const isAttachmentsError = attachments.some((item) => !!item.error);

  const onSubmit = () => {
    if (description && isSelectIssueType && !isAttachmentsError) {
      setValidated(false);
      dispatch(sendSupportEmailAction());
    } else {
      setValidated(true);
    }
  };

  return (
    <>
      <div className="request-popup-section-holder">
        <div className="request-popup-section sm support-request">
          <p className="request-popup-description">
            We are sorry you are experiencing an issue with AdPro. We are here
            to help solve it quickly. To get started, please fill out this brief
            form, which should only take a minute or two of your time.
          </p>
          <div
            className={`request-popup-form-row ${
              isValidated && !isSelectIssueType ? "with-error" : ""
            }`}
          >
            <p className="request-popup-form-title">
              What issue(s) are you{" "}
              <span className="nowrap">
                having?<span className="asterisks">*</span>
              </span>
            </p>
            <div
              ref={selectRef}
              className={`request-dropdown ${
                issueSelectOpenStatus ? "open" : ""
              }`}
            >
              <div
                onClick={() => setIssueSelectOpenStatus(!issueSelectOpenStatus)}
                className={`request-dropdown-head ${
                  isSelectIssueType ? "select" : ""
                }`}
              >
                <span className="request-dropdown-text">
                  {isSelectIssueType
                    ? issueTypesList[selectedIssueType]
                    : "select"}
                </span>
                <i className="icon-down request-dropdown-icon" />
              </div>
              <ul className="request-dropdown-content">
                {issueTypesList.map((item, key) => (
                  <li
                    key={key}
                    className={`request-dropdown-row ${
                      selectedIssueType === key ? "active" : ""
                    }`}
                    onClick={() => handleSelectIssueType(key)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            {isValidated && !isSelectIssueType ? (
              <p className="request-popup-form-error">
                This is a required field
              </p>
            ) : null}
          </div>
          <div
            className={`request-popup-form-row lh-small ${
              isValidated && !description ? "with-error" : ""
            }`}
          >
            <p className="request-popup-form-title">
              Please provide a description of the issue(s) you are experiencing
              (which can include a{" "}
              <span className="nowrap">
                hyperlink)<span className="asterisks">*</span>
              </span>
            </p>
            <textarea
              ref={descriptionRef}
              placeholder="Enter Text"
              className="request-form-textarea success"
              value={description}
              onChange={handleChangeDescription}
              onKeyDown={onDescriptionKeyDown}
            />
            {isValidated && !description ? (
              <p className="request-popup-form-error">
                This is a required field
              </p>
            ) : null}
          </div>
          <div className="request-popup-select-file-row">
            <p className="request-popup-select-file-title">
              Additional information
            </p>
            <p className="request-popup-select-file-description">
              Please attach any relevant docs (.txt or .pdf), images,
              screenshots (.png or .jpg), or .zip files that help illustrate the
              issue(s) you are experiencing (no more than 3 files).
            </p>
            <div className="select-file-row">
              <div className="select-file-states">
                {Array.isArray(attachments) && attachments.length > 0 ? (
                  <>
                    <div className="select-file-file-added-state">
                      <div className="select-file-files-column">
                        {attachments.map((item, key) => (
                          <div
                            key={key}
                            className={`select-file-files-row ${
                              item.error ? "error" : ""
                            }`}
                          >
                            {/* ad class "error" */}
                            <i className="icon-file-added select-file-add-icon" />
                            <p className="select-file-row-text">
                              {item.file.name}
                            </p>
                            <i
                              onClick={(e) => handleDeleteFile(e, key)}
                              className="icon-delete-h select-file-delete-icon"
                            />
                          </div>
                        ))}
                      </div>
                      {attachments.length < attachmentsMaxCount ? (
                        <div
                          {...getRootProps()}
                          className="select-file-plus-column"
                        >
                          {isDragActive ? (
                            <label
                              htmlFor="SelectFile"
                              className="select-file-add-plus-block drag-drop"
                            >
                              <i className="icon-attach-files select-file-add-dd-icon" />
                            </label>
                          ) : (
                            <label
                              htmlFor="SelectFile"
                              className="select-file-add-plus-block default"
                            >
                              <i className="icon-plus-h select-file-add-plus-icon" />
                            </label>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <p
                      className={`select-file-drag-here-text select-file-error-text ${
                        isAttachmentsError ? "show" : ""
                      }`}
                    >
                      Either the file type is incorrect or the size of the
                      attachment is over 7Mb
                    </p>
                  </>
                ) : (
                  <>
                    {
                      <div
                        {...getRootProps()}
                        className={`select-file-state ${
                          isDragActive
                            ? "select-file-drag-drop-state hover"
                            : "select-file-default-state"
                        }`}
                      >
                        {isDragActive ? (
                          <>
                            <i className="icon-attach-files select-file-drag-icon" />
                            <p className="select-file-drag-here-text select-file-default-text">
                              Drag and drop the files to transfer it
                            </p>
                          </>
                        ) : (
                          <>
                            <label
                              htmlFor="SelectFile"
                              className="select-file-label"
                            >
                              Select files
                            </label>
                            <p className="select-file-drag-here-text">
                              or drag them here
                            </p>
                          </>
                        )}
                      </div>
                    }
                  </>
                )}
              </div>
              <input
                {...getInputProps()}
                accept={".png, .jpg, .txt, .pdf, .zip"}
                className="select-file-input"
                id="SelectFile"
              />
            </div>
          </div>
        </div>
        <div className="request-popup-bottom sm">
          <button
            onClick={onSubmit}
            className={`request-popup-button ${!loading ? "" : "disabled"}`}
          >
            Send Support Request
          </button>
          <p className="request-popup-bottom-text">
            We will get back to you within 24 hours.
          </p>
        </div>
      </div>
    </>
  );
};

SupportRequestForm.propTypes = {
  popupStatus: PropTypes.number.isRequired,
};

export default SupportRequestForm;
