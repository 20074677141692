import React from "react";

const UserProfileOptionItem = ({ title, onClick }) => (
  <div onClick={onClick} className="user-profile-option-item">
    <p className="option-title">{title}</p>
    <i className="icon-arrow-right-b"></i>
  </div>
);

export default UserProfileOptionItem;
