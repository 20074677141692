import { adMediaTypesEnum } from "src/constants";

export const selectAd = state => state?.ad;
export const selectAdInvoiceInfo = state => state?.ad?.invoice?.info;
export const selectAdInvoiceType = state => state?.ad?.invoice?.type;
export const selectAdListingId = state => state?.ad?.listingId;
export const selectAdGoal = state => state?.ad?.goal;
export const selectAdConfigsByGoal = state => state?.ad?.adConfigsByGoal;
export const selectAdMediaType = state => state?.ad?.adMediaType;
export const selectFacebookHeadline = state => state?.ad?.facebookHeadline;
export const selectFacebookHeadlineCheck = state => state?.ad?.facebookHeadlineCheck;
export const selectFacebookPrimaryText = state => state?.ad?.facebookPrimaryText;
export const selectFacebookPrimaryTextCheck = state => state?.ad?.facebookPrimaryTextCheck;
export const selectFacebookValidationLoading = state => state?.ad?.facebookValidationLoading;

export const selectGoogleHeadline = state => state?.ad?.googleHeadline;
export const selectGoogleHeadlineCheck = state => state?.ad?.googleHeadlineCheck;
export const selectGoogleValidationLoading = state => state?.ad?.googleValidationLoading;
export const selectGoogleLongHeadline = state => state?.ad?.googleLongHeadline;
export const selectGoogleLongHeadlineCheck = state => state?.ad?.googleLongHeadlineCheck;
export const selectGoogleDescription = state => state?.ad?.googleDescription;
export const selectGoogleDescriptionCheck = state => state?.ad?.googleDescriptionCheck;

export const selectAdCallToAction = state => state?.ad?.callToAction;
export const selectAdFbHousePhotosSingle = state => state?.ad?.houseFbPhotosSingle;
export const selectAdGoogleHousePhotos = state => state?.ad?.houseGooglePhotos;
export const selectAdHousePhotosCarousel = state => state?.ad?.housePhotosCarousel;
export const selectAdType = state => (selectAdMediaType(state) === adMediaTypesEnum.Facebook
    ? state?.ad?.adTypeFacebook
    : state?.ad?.adTypeGoogle);
export const selectConvertedHousePhotos = state => state?.ad?.convertedHousePhotos;
export const selectAdBudgetId = state => state?.ad?.budgetId;
export const selectGoogleAdLogoType = state => state?.ad?.googleAdLogoType;
export const selectFacebookAdLogoType = state => state?.ad?.facebookAdLogoType;
export const selectAdBudgetPrice = state => state?.ad?.price;
export const selectAdNotification = state => state?.ad?.notification;
export const selectAdLoading = state => state?.ad?.loading;
export const selectAdDetails = state => state?.ad?.adDetails;
export const selectMapChartData = state => state?.ad?.mapChartData;
export const selectMapChartDataMax = state => state?.ad?.mapChartDataMax;
export const selectDeviceChartData = state => state?.ad?.deviceChartData;
export const selectAdDetailsLoading = state => state?.ad?.adDetailsLoading;
export const selectAdDetailsError = state => state?.ad?.adDetailsError;
export const selectAdCancelSuccess = state => state?.ad?.adCancelSuccess;
export const selectAdCancelLoading = state => state?.ad?.adCancelLoading;
export const selectAdCancelError = state => state?.ad?.adCancelError;
export const selectAdPreviewInfo = state => state?.ad?.adPreviewInfo;
export const selectGooglePreviewImages = state => state?.ad?.googlePreviewImages;
export const selectIsComingSoon = state => state?.ad?.isComingSoon;
