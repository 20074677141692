import supportActionTypes from "../actions/support/supportActionTypes";

const initialState = {
    issueType: null,
    description: "",
    attachments: [],
    sentStatus: false,
    loading: false
};

// eslint-disable-next-line default-param-last
const supportReducer = (state = initialState, action) => {
    switch (action.type) {
        case supportActionTypes.SET_ISSUE_TYPE:
            return {
                ...state,
                issueType: action.payload
            };
        case supportActionTypes.SET_ISSUE_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            };
        case supportActionTypes.SET_ATTACHMENTS:
            return {
                ...state,
                attachments: action.payload
            };
        case supportActionTypes.SEND_EMAIL_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case supportActionTypes.SET_SENT_STATUS:
            return {
                ...state,
                sentStatus: action.payload
            };
        case supportActionTypes.EMPTY_SUPPORT_DATA:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default supportReducer;
