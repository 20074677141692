import { useMemo, useState } from "react";
import { addDelegationRequest } from "../../api/delegation";
import { notificationsServices } from "../../helpers/notifications";
import { isEllimanEmail, isValidEmail } from "../../helpers/validations";
import DelegateAccessPopup from "../../UI/DelegateAccessPopup";

const errorMessage = "Invalid email address";
const errorMessageElliman =
  "Invalid email address. Must be an @elliman.com email address.";

const successMessage = "You have successfully shared your AdPro dashboard with";
const errorResponseMessage =
  "We ran into an unexpected issue. Please try again later or contact support at AdProSupport@elliman.com.";

const validateEmail = (email) => {
  if (!email.trim()) {
    return {
      isErrorEmail: false,
      isErrorElliman: false,
    };
  }
  const isValid = isValidEmail(email);
  const isElliman = isEllimanEmail(email);
  return {
    isErrorEmail: !isValid,
    isErrorElliman: !isElliman,
  };
};

const DelegateAccess = ({ onClose, onSuccess }) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const handleBlur = () => {
    const { isErrorEmail, isErrorElliman } = validateEmail(value);
    if (isErrorEmail) {
      setError(errorMessage);
    } else if (isErrorElliman) {
      setError(errorMessageElliman);
    } else {
      setError("");
    }
  };

  const isDisabled = useMemo(() => {
    const { isErrorEmail, isErrorElliman } = validateEmail(value);
    return !value || isErrorEmail || isErrorElliman;
  }, [value]);

  const handleInputChange = (e) => {
    if (error) {
      setError("");
    }
    setValue(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await addDelegationRequest(value);
      notificationsServices.success(`${successMessage} ${value}`);
      onSuccess();
    } catch (error) {
      const validationMessage = error?.response?.data?.ValidationMessages?.[0];
      const badRequestMessage = error?.response?.data?.Message;
      if (validationMessage) {
        setError(validationMessage);
      } else if (badRequestMessage) {
        setError(badRequestMessage);
      } else {
        notificationsServices.error(errorResponseMessage);
        onClose();
      }
    }
  };

  return (
    <DelegateAccessPopup
      onClose={onClose}
      value={value}
      error={error}
      isDisabled={isDisabled}
      onBlur={handleBlur}
      onSubmit={handleSubmit}
      onChange={handleInputChange}
    />
  );
};

export default DelegateAccess;
