import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import {
  adMediaTypesEnum,
  creativeLogoTypesEnum,
  goalsEnum,
  googleAdPreviewTypeEnum,
  SocialEnum,
} from "src/constants";
import {
  selectAdCallToAction,
  selectAdGoal,
  selectAdMediaType,
  selectAdPreviewInfo,
  selectAdType,
  selectConvertedHousePhotos,
  selectFacebookAdLogoType,
  selectFacebookHeadline,
  selectFacebookPrimaryText,
  selectGoogleAdLogoType,
  selectGoogleDescription,
  selectGoogleHeadline,
  selectGoogleLongHeadline,
} from "../../../store/selectors/adSelector";
import { selectBrand } from "../../../store/selectors/brandSelector";
import {
  selectAgentDesignation,
  selectAgentTeamInfo,
  selectListingFbInfo,
} from "../../../store/selectors/listingSelector";

import { adTypes, callToActionTypes } from "../../../constants/constants";
import {
  emptyAdPreviewInfo,
  getAdPreviewInfo,
} from "../../../store/actions/ad/adActions";

import Logo from "../../../assets/images/de.svg";
import { capitalizeFirstLetter } from "../../../helpers/formatters";
import FacebookPreview from "./FacebookPreview";
import InstagramPreview from "./InstagramPreview";
import InstagramStorePreview from "./InstagramStorePreview";
import { selectCurrentUser } from "../../../store/selectors/userSelector";
import AdGeneratePreview from "../../../UI/AdGeneratePreview";
import GmailPreview from "../../../UI/GooglePreview/GmailPreview";
import DisplayPreview from "../../../UI/GooglePreview/DisplayPreview";
import DiscoverPreview from "../../../UI/GooglePreview/DiscoverPreview";

const facebookPlatformKey = "Facebook_Instagram";

const getIsLead = (adGoal, previewGoal) => {
  if (previewGoal) {
    return goalsEnum[previewGoal] === goalsEnum.LEAD_GENERATION;
  } else {
    return adGoal === goalsEnum.LEAD_GENERATION;
  }
};

const AdView = ({
  openPopup,
  adId = "",
  mainImageRef = null,
  activeSocial = SocialEnum.FACEBOOK,
  disableCarouselSwipe = false,
  isPopup = false,
  userId,
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const adMediaType = useSelector(selectAdMediaType);
  const convertedHousePhotos = useSelector(selectConvertedHousePhotos);
  const adCall = useSelector(selectAdCallToAction);
  const facebookHeadline = useSelector(selectFacebookHeadline);
  const facebookPrimaryText = useSelector(selectFacebookPrimaryText);

  const googleHeadline = useSelector(selectGoogleHeadline);
  const googleLongHeadline = useSelector(selectGoogleLongHeadline);
  const googleDescription = useSelector(selectGoogleDescription);

  const isFacebookFlow = adMediaType === adMediaTypesEnum.Facebook;
  const adLogoType = useSelector(
    isFacebookFlow ? selectFacebookAdLogoType : selectGoogleAdLogoType
  );
  const agentDesignation = useSelector(selectAgentDesignation);
  const adGoal = useSelector(selectAdGoal);
  const listingFbInfo = useSelector(selectListingFbInfo);
  const teamInfo = useSelector(selectAgentTeamInfo);
  const brand = useSelector(selectBrand);
  const { data, loading } = useSelector(selectAdPreviewInfo);
  const adType = useSelector(selectAdType);

  const pageName =
    activeSocial === SocialEnum.FACEBOOK
      ? get(data, "fbPageInfo.fbPageName", "")
      : get(data, "fbPageInfo.instagramPageName", "");
  const listingInfoName =
    activeSocial === SocialEnum.FACEBOOK
      ? get(listingFbInfo, "fbPageName", "")
      : get(listingFbInfo, "instagramPageName", "");

  const isSingleImage = isPopup
    ? data?.photos?.length === 1
    : adType === adTypes.SINGLE;

  const dispatch = useDispatch();

  const isAgentViewMode = adLogoType === creativeLogoTypesEnum.AgentLogo;
  const isTeamViewMode = adLogoType === creativeLogoTypesEnum.CompanyLogo;
  const agencyLogo = data?.agencyLogo || currentUser?.previewLogoUrl || "";
  const agencyName =
    pageName || listingInfoName || data?.agencyName || brand?.name || "";
  const primaryText = data?.primaryText || facebookPrimaryText || "";
  const photos = data?.photos || convertedHousePhotos || [];
  const headline = data?.headline || facebookHeadline || "";
  const adCallValue = adCall
    ? callToActionTypes[adCall]
    : callToActionTypes[0];
  const callToAction = data?.callToAction
    ? capitalizeFirstLetter(data.callToAction?.replaceAll("_", " "))
    : adCallValue;
  const websiteUrl = brand?.websiteUrl || "";
  const isLead = getIsLead(adGoal, data?.goal);
  const isMobile = window.innerWidth < 758;
  const caption = data?.caption || isLead ? "FORM ON FACEBOOK" : websiteUrl;

  let adLogo,
    adTitle,
    adDesignation = "";

  if (isAgentViewMode) {
    adLogo = currentUser?.avatarUrl;
    adTitle = `${currentUser?.firstName} ${currentUser?.lastName}`;
    adDesignation = agentDesignation;
  }

  if (isTeamViewMode) {
    adLogo = isFacebookFlow
      ? teamInfo?.teamLightLogo
      : teamInfo?.teamDarkLogo || Logo;

    adTitle = teamInfo?.teamName;
    adDesignation = currentUser?.teamDesignation;
  }

  useEffect(() => {
    if (adId) {
      dispatch(getAdPreviewInfo(adId, userId, facebookPlatformKey));
      return () => emptyAdPreviewInfo();
    }
  }, []);

  const renderAdView = () => {
    const props = {
      agencyLogo,
      agencyName,
      adTitle,
      isAgentViewMode,
      adId,
      adDesignation,
      isTeamViewMode,
      adLogo,
      photos,
      openPopup,
      callToAction,
      headline,
      caption,
      primaryText,
      disableCarouselSwipe,
      isSingleImage,
    };

    const getIsActive = (type) => {
      if (isPopup) return true;
      if (!isMobile) return activeSocial === type;
      return true;
    };

    const googlePrevewMainProps = {
      googleHeadline,
      googleLongHeadline,
      googleDescription,
      photos,
      adLogo,
      adTitle,
      agencyLogo,
      agencyName: brand?.name,
      isMobile,
      isTeamViewMode,
      adCallValue: callToAction,
      openPopup,
    };

    return (
      <>
        {isFacebookFlow || !adMediaType ? (
          <>
            <FacebookPreview
              {...props}
              isActive={getIsActive(SocialEnum.FACEBOOK)}
            />
            <InstagramPreview
              {...props}
              isActive={getIsActive(SocialEnum.INSTAGRAM)}
            />
            <InstagramStorePreview
              {...props}
              isActive={getIsActive(SocialEnum.STORIES)}
            />
          </>
        ) : (
          <>
            <GmailPreview
              {...googlePrevewMainProps}
              isActive={getIsActive(googleAdPreviewTypeEnum.GMAIL)}
            />
            <DisplayPreview
              {...googlePrevewMainProps}
              isActive={getIsActive(googleAdPreviewTypeEnum.DISPLAY)}
            />
            <DiscoverPreview
              {...googlePrevewMainProps}
              isActive={getIsActive(googleAdPreviewTypeEnum.DISCOVER)}
            />
          </>
        )}
      </>
    );
  };

  return loading ? (
    <div className="loader" data-html2canvas-ignore />
  ) : (
    <>
      {renderAdView()}
      <AdGeneratePreview
        generatePreviewRef={mainImageRef}
        isTeamViewMode={isTeamViewMode}
        adDesignation={adDesignation}
        adTitle={adTitle}
        adLogo={adLogo}
        imageUrl={photos?.[0]?.data}
      />
    </>
  );
};

AdView.propTypes = {
  openPopup: PropTypes.func,
  adId: PropTypes.string,
  mainImageRef: PropTypes.object,
};

export default AdView;
