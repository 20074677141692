import { goalsEnum } from "src/constants";
import {
  adGoogleMaxCount,
  adLeadPhotoMaxCount,
  adMediaTypesEnum,
  adPhotosMaxCount,
  displayMediaTypesEnum,
} from "src/constants/promoteAd";

export const getSelectMediaType = (mediaType) => {
  const isSelectedGoogle = mediaType === adMediaTypesEnum.Google;
  const isSelectedFacebook = mediaType === adMediaTypesEnum.Facebook;

  return {
    isSelectedGoogle,
    isSelectedFacebook,
  };
};

export const getDisplayMediaType = (mediaType) => {
  const displayMediaType =
    mediaType === adMediaTypesEnum.Google
      ? displayMediaTypesEnum.Google
      : displayMediaTypesEnum.Facebook;

  return displayMediaType;
};

export const getReviewCopyClassName = (
  generalValidation,
  houseWordValidation
) => {
  if (generalValidation) {
    return houseWordValidation ? "error" : "";
  }
  return "error";
};

export const getReviewCopyInputLabel = (
  primaryTextValidationMessage,
  errorPrimaryClassName,
  field
) => {
  if (primaryTextValidationMessage) {
    return `"${primaryTextValidationMessage}" 
          word(s) cannot be used in this sentence.`;
  }

  return `${errorPrimaryClassName ? "Input " : ""}${field}`;
};

export const getMaxPhotoCount = (goal, isSelectedFacebook = true) => {
  if (isSelectedFacebook) {
    if (goal === goalsEnum.LEAD_GENERATION) {
      return adLeadPhotoMaxCount;
    }
    return adPhotosMaxCount;
  }
  return adGoogleMaxCount;
};

export const getFormattedBudgets = (budgets = []) =>
  budgets?.reduce((ac, data) => {
    const goal = goalsEnum[data.goal];
    ac[goal] = { ...data.budgetInfo, radius: data.radius };
    return ac;
  }, {});

export const getFormattedGoogleBudgets = (budgets = []) => {
  const googleBudgets = budgets?.reduce((ac, data) => {
    const goal = goalsEnum.LINK_CLICKS;
    ac[goal] = { ...data.budgetInfo, radius: data.radius };
    return ac;
  }, {});
  return googleBudgets;
};
