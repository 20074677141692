import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Portal } from "react-portal";
import { selectSentStatus } from "../../store/selectors/supportSelector";
import { selectFaqContent } from "../../store/selectors/contentSelector";
import { emptySupportData } from "../../store/actions/support/supportActions";
import SupportImg from "../../assets/images/support-received.svg";
import SupportRequestForm from "./supportRequestForm";
// eslint-disable-next-line import/named
import { popupStatusesEnum } from "../../constants/enums";
import FaqContainer from "./faqContainer";

const SupportPopup = () => {
  const [popupStatus, setPopupStatus] = useState(popupStatusesEnum.NONE);
  const [isOpen, setIsOpen] = useState(false);
  const sentStatus = useSelector(selectSentStatus);
  const { loading } = useSelector(selectFaqContent);

  const dispatch = useDispatch();

  const handleOpenRequestForm = (e) => {
    e.preventDefault();
    setIsOpen(true);
    document.body.classList.add("overflow-hidden");
    setPopupStatus(popupStatusesEnum.FAQ);
  };

  const handleCloseRequestForm = () => {
    setIsOpen(false);
    document.body.classList.remove("overflow-hidden");
    setTimeout(() => {
      setPopupStatus(popupStatusesEnum.NONE);
      dispatch(emptySupportData());
    }, 700);
  };

  const isPopupClosed = popupStatus === popupStatusesEnum.NONE;

  return (
    <>
      <a
        onClick={handleOpenRequestForm}
        className="icon-support copyright-info"
        href="https://form.asana.com?k=iUKjts5Gt2dA4C3rHqE-IQ&d=14384507346757"
        target="_blank"
        data-html2canvas-ignore
      />
      <Portal>
        <div
          className={`request-popup-container ${isOpen ? "open" : ""} ${loading ? "cf-skeleton" : ""
            }`}
        >
          <div className="request-popup-content">
            {sentStatus ? (
              <div className="request-popup-received-container">
                <div className="request-popup-received-head">
                  <button
                    onClick={handleCloseRequestForm}
                    className="request-popup-close"
                  >
                    <i className="icon-close" />
                  </button>
                </div>
                <div className="request-popup-received-section">
                  <div className="request-popup-received-texts">
                    <img
                      src={SupportImg}
                      alt=""
                      className="request-popup-received-img"
                    />
                    <p className="request-popup-received-description">
                      Your submission has been received - thank you!
                    </p>
                    <p className="request-popup-received-info">
                      Please expect a reply within 24 hours during our normal
                      business hours
                    </p>
                    <p className="request-popup-received-info">
                      (9:00 AM - 6:00 PM PT, Monday-Friday).
                    </p>
                  </div>
                </div>
                <div className="request-popup-received-bottom">
                  <button
                    onClick={handleCloseRequestForm}
                    className="request-popup-received-button"
                  >
                    close
                  </button>
                </div>
              </div>
            ) : null}
            <div
              className={`request-popup-forms-container ${popupStatus === popupStatusesEnum.FAQ ? "faq-swipe" : ""
                }`}
            >
              <div className="request-popup-head">
                <div className="request-popup-head-column">
                  <div className="request-popup-title-holder">
                    <h3 className="request-popup-title cf-skeleton-h with-icon">
                      <i
                        className="icon-arrow-left request-popup-head-arrow"
                        onClick={() => setPopupStatus(popupStatusesEnum.FAQ)}
                      />
                      Support Request
                    </h3>
                    <h3 className="request-popup-title cf-skeleton-h">
                      FAQ & Help
                    </h3>
                  </div>
                </div>
                <button
                  onClick={handleCloseRequestForm}
                  className="request-popup-close"
                >
                  <i className="icon-close cf-skeleton-dot" />
                </button>
              </div>
              <div className="request-popup-swipe-container">
                <FaqContainer
                  setPopupStatus={setPopupStatus}
                  isPopupClosed={isPopupClosed}
                />
                <SupportRequestForm popupStatus={popupStatus} />
              </div>
            </div>
          </div>
        </div>
      </Portal>
    </>
  );
};

export default SupportPopup;
