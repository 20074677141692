export const selectAgentListings = state => state?.listing?.agentListings;
export const selectAgentListingsFilter = state => state?.listing?.agentListingsFilter;
export const selectAgentListingsPage = state => state?.listing?.agentListingsPage;
export const selectIsListingAllLoaded = state => state?.listing?.isListingAllLoaded;
export const selectListingAddress = state => state?.listing?.listingAddress;
export const selectListingImages = state => state?.listing?.listingImages;
export const selectListingAddressLoading = state => state?.listing?.listingAddressLoading;
export const selectListingInfo = state => state?.listing?.listingInfo;
export const selectListingFbInfo = state => state?.listing?.listingFbInfo;
export const selectGoogleAdConfig = state => state?.listing?.googleAdConfig;
export const selectListingHeadline = state => state?.listing?.listingInfo?.headline;
export const selectListingPrimaryText = state => state?.listing?.listingInfo?.primaryText;
export const selectListingPhotos = state => state?.listing?.listingInfo?.photos;
export const selectAgentDesignation = state => state?.listing?.listingInfo?.agentDesignation;
export const selectAgentTeamInfo = state => state?.listing?.listingInfo?.teamInfo;
export const selectAgentListingsLoading = state => state?.listing?.agentListingsLoading;
export const selectAnalyticsLoading = state => state?.listing?.analyticsLoading;
export const selectListingsUnderMaintenance = state => state?.listing?.listingsUnderMaintenance;
export const selectAgentListingsError = state => state?.listing?.agentListingsError;
export const selectPromoteListingStep = state => state?.listing?.promoteListingStep;
export const selectPaymentListingStep = state => state?.listing?.paymentListingStep;
export const selectStepLoading = state => state?.listing?.stepLoading;
export const selectDashboardSelectedTab = state => state?.listing?.dashboardSelectedTab;
export const selectAgentListOfListings = state => state?.listing?.agentListOfListings.data;
export const selectAgentListOfListingsFiltered =
    state => state?.listing?.agentListOfListings.filteredData;
export const selectAgentListOfListingsInfo = state => state?.listing?.agentListOfListings.info;
export const selectAgentListOfListingsFilter = state => state?.listing?.agentListOfListings.filter;
