import { combineReducers } from "redux";

import brandReducer from "./brandReducer";
import listingReducer from "./listingReducer";
import adReducer from "./adReducer";
import paymentReducer from "./paymentReducer";
import insightReducer from "./insightReducer";
import permissionReducer from "./permissionReducer";
import emailReducer from "./emailReducer";
import supportReducer from "./supportReducer";
import contentReducer from "./contentReducer";
import managerReducer from "./managerReducer";
import delegationReducer from "./delegationReducer";
import userReducer from "./userReducer";
import managerAd from "./managerAdReducer";
import managerAdAnalyticsReducer from "./managerAdAnalyticsReducer";

const rootReducer = combineReducers({
  brand: brandReducer,
  listing: listingReducer,
  ad: adReducer,
  payment: paymentReducer,
  insight: insightReducer,
  permission: permissionReducer,
  email: emailReducer,
  support: supportReducer,
  content: contentReducer,
  manager: managerReducer,
  delegation: delegationReducer,
  user: userReducer,
  managerAdAnalytics: managerAdAnalyticsReducer,
  managerAd,
});

export default rootReducer;
