import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { acceptDelegationRequest } from "../../api/delegation";
import { notificationsServices } from "../../helpers/notifications";
import MessageForDelegate from "../../UI/MessageForDelegate";
import Spinner from "../shared/Spinner";

const fromUserIdKey = "fromUserId";

const DelegatePage = () => {
  const history = useHistory();

  const goToMainPage = () => {
    history.push("/");
  };

  const acceptDelegation = async (fromUserId) => {
    try {
      const response = await acceptDelegationRequest(fromUserId);
      const fromUserEmail = response?.data?.email;
      if (fromUserEmail) {
        const successMessage = `You have successfully accepted ${fromUserEmail}'s invitation. Click on your profile photo and then Shared With You to switch to ${fromUserEmail}'s AdPro`;
        notificationsServices.success(successMessage);
      }
      goToMainPage();
    } catch {
      notificationsServices.errorWithComponent(MessageForDelegate);
      goToMainPage();
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const fromUserId = query.get(fromUserIdKey);
    if (fromUserId) {
      acceptDelegation(fromUserId);
    } else {
      notificationsServices.errorWithComponent(MessageForDelegate);
      goToMainPage();
    }
  }, []);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default DelegatePage;
