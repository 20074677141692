import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import HeaderAvatar from "../shared/HeaderAvatar";
import UserProfileOptionItem from "../../UI/UserProfileOptionItem";
import UserTypeDropdown from "../tools/UserTypeDropdown";
import ManagerAdSection from "../../UI/ManagerAdSection";
import { openAdStepper } from "../../store/actions/managerAd/managerAdAction";
import { routesPath } from "../../constants/constants";

const DelegateUserFirstScreen = ({
  rootUser,
  isManager,
  toggleDelegate,
  toggleShared,
  isOpen,
  onCloseSidebar,
  isMultipleRoleAgent,
  roleTitle,
  isMarketingManager,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onPlaceAd = () => {
    onCloseSidebar();
    dispatch(openAdStepper());
  };
  const onViewAd = () => {
    onCloseSidebar();
    history.push(`/${rootUser.company}/${routesPath.MANAGER_AD_ANALYTICS}`);
  };

  return (
    <>
      <div className={`users-first-page ${isOpen ? "open" : ""}`}>
        <div className="user-profile-header">
          <HeaderAvatar userData={rootUser} />
          <h3 className="personal-info">
            {`${rootUser?.firstName || ""} ${rootUser?.lastName || ""}`}
          </h3>
          <p className="persona-role">{roleTitle}</p>
        </div>
        {isMarketingManager && isManager && (
          <ManagerAdSection onPlaceAd={onPlaceAd} onViewAd={onViewAd} />
        )}
        {isMultipleRoleAgent && !isMarketingManager && <UserTypeDropdown />}
        {!isManager && (
          <>
            <UserProfileOptionItem
              title="Delegate Access"
              onClick={toggleDelegate}
            />
            <UserProfileOptionItem
              title="Shared With You"
              onClick={toggleShared}
            />
          </>
        )}
      </div>
      <span className="modal-close" onClick={onCloseSidebar}>
        <i className="icon-close"></i>
      </span>
    </>
  );
};

export default DelegateUserFirstScreen;
