import React from "react";
import PropTypes from "prop-types";

const Notification = ({ title, warning }) => (
    <div className={`client-finder-notification ${warning ? "warning" : ""}`}>
        <i className={`icon-info${warning ? "-full" : ""}`} data-html2canvas-ignore/>
        {title}
    </div>
);

Notification.propTypes = {
    title: PropTypes.string,
    warning: PropTypes.bool
};

export default Notification;
