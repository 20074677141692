const resizeFontSize = ({
  containerRef,
  textRef,
  gap,
  minFontSize,
  maxFontSize,
  step,
}) => {
  if (!containerRef.current && !textRef.current) return;
  function getTextFit({ textRef, containerWidth, gap }) {
    const textLength = textRef.current.offsetWidth;
    const isBigThanContainer = textLength + gap > containerWidth;
    const isSmallThanContainer = textLength < containerWidth - gap;
    return {
      isBigThanContainer,
      isSmallThanContainer,
      isFit: !isBigThanContainer && !isSmallThanContainer,
    };
  }
  if (!containerRef.current || !textRef.current) return;
  const container = containerRef.current;

  const containerWidth = container.offsetWidth;
  // eslint-disable-next-line radix
  let fontSize = parseInt(window.getComputedStyle(container).fontSize);
  let isReady = false;
  const { isFit, isBigThanContainer } = getTextFit({
    textRef,
    containerWidth,
    gap,
  });
  if (isFit) {
    isReady = true;
    return;
  }

  // make font size smaller
  while (!isReady && isBigThanContainer) {
    const { isFit } = getTextFit({
      textRef,
      containerWidth,
      gap,
    });

    if (isFit || fontSize < minFontSize) {
      isReady = true;
      break;
    }
    fontSize -= step;
    container.style.fontSize = `${fontSize}px`;

    const { isSmallThanContainer: isSmallAfterChange } = getTextFit({
      textRef,
      containerWidth,
      gap,
    });

    // if text is bigger than container after change we regard the font size and break the loop
    if (isSmallAfterChange) {
      isReady = true;
      break;
    }
  }

  //  make font size bigger
  while (!isReady) {
    const { isFit } = getTextFit({
      textRef,
      containerWidth,
      gap,
    });

    if (isFit || fontSize >= maxFontSize) {
      isReady = true;
      break;
    }
    fontSize += step;
    container.style.fontSize = `${fontSize}px`;

    const { isBigThanContainer: isBigAfterChange } = getTextFit({
      textRef,
      containerWidth,
      gap,
    });

    // if text is smaller than container after change we regard the font size and break the loop
    if (isBigAfterChange) {
      fontSize -= step;
      container.style.fontSize = `${fontSize}px`;
      break;
    }
  }
};

export default resizeFontSize;
