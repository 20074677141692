import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectIsManager } from "../../store/selectors/permissionSelector";
import { setIsManager } from "../../store/actions/permission/permissionActions";
import { setManagerReset } from "../../store/actions/manager/managerActions";
import { setAgentListingLoading } from "../../store/actions/listing/listingActions";

import { ROLES } from "../../constants/constants";
import useOutsideClick from "../../hooks/useOutsideClick";
import auth from "../../services/auth";

const userViewTypes = {
  [ROLES.AGENT]: { value: ROLES.AGENT, title: "Agent" },
  [ROLES.MANAGER]: { value: ROLES.MANAGER, title: "Manager" }
};

const UserTypeDropdown = () => {
  const contentRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const isManager = useSelector(selectIsManager);
  const [openStatus, setOpenStatus] = useState(false);

  const selectedInfo = userViewTypes[isManager ? ROLES.MANAGER : ROLES.AGENT];

  const onSelectItem = (newRole) => {
    if (newRole !== selectedInfo.value) {
      auth
        .setAuthRole(newRole)
        .then(() => {
          dispatch(setIsManager(newRole === ROLES.MANAGER));
          dispatch(setAgentListingLoading(true));
          dispatch(setManagerReset(true));
          history.push("/");
        })
        .catch(() => { });
    }

    setOpenStatus(false);
  };

  useOutsideClick(contentRef, () => setOpenStatus(false));

  return (
    <div className="user-type-dropdown">
      <p className="user-type-dropdown-title">View as</p>
      <div ref={contentRef} className="user-type-select" onClick={() => setOpenStatus(!openStatus)}>
        <div className="user-type-select-value">
          <span>
            {selectedInfo.title}
          </span>
          <i className="dropdown-icon icon-down" />
        </div>
        {openStatus ? (
          <ul className="user-type-select-content">
            {Object.values(userViewTypes).map(({ value, title }) => (
              <li
                key={value}
                className={`user-type-select-option ${value === selectedInfo.value ? "active" : ""}`}
                onClick={() => onSelectItem(value)}
              >
                {title}
                <i className="icon-check-bold user-type-select-option-icon" />
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default UserTypeDropdown;
